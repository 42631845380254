import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import pandaIcon from "../assets/panda-icon.svg";
import { PandaConnectButton } from "../../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { LayerData, RecGCBase } from "../../contracts/rec-gc-base";
import { RecGCFull } from "../../contracts/rec-gc-full";
import artifact from '../../../artifacts/rec-gc-full.json';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from './../../components/styled/PrimaryButton';
import Header from "../../components/styled/Header";
import Footer from "../../components/styled/Footer";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Modal from "../../components/mint/WaitingForFaces";
import { ShruggrLib } from "../../contracts/shruggr-lib";
import { env } from "process";
import GalleryGrid from "../../components/GalleryGrid";

RecGCFull.loadArtifact(artifact);

export const BasedFrogs = () => {
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [initialSupply, setInitialSupply] = useState(0)
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [coverUrl, setCoverUrl] = useState<string>('')
  const [currentOrigin, setCurrentOrigin] = useState<string>('b94dd05b015519dbb362a4d11399f1f59d8bcfd432996516157f510823892dfc_0');
  const [currentLocation, setCurrentLocation] = useState<string>('b94dd05b015519dbb362a4d11399f1f59d8bcfd432996516157f510823892dfc_0');
  //URGENT: TODO: change back to 550000
  const [priceInSatoshis, setPriceInSatoshis] = useState<number>(550000)
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(10000)
//   const [priceInSatoshis, setPriceInSatoshis] = useState<number>(100)
//   const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(100)
  const [totalSupply, setTotalSupply] = useState<number>(0)
  const [collectionName, setCollectionName] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [finalTxId, setFinalTxId] = useState<string | null>(''); // State to store the mint response
  const [message, setMessage] = useState<string | null>(null)
  const location = useLocation();
  const [signedMessage, setSignedMessage] = useState<
    SignedMessage | undefined
  >();

  //URGENT: Change Cover Photo
  const coverPhotoUrl = "https://ton.x.com/i/ton/data/dm/1798420888808833271/1798420829258018816/hLEakPQm.jpg:medium";
  //URGENT: TODO: Change to their address 
  const paymentAddress = "16zzyLRH64qv1z7uywHdUdZBuw9tuDEhL3" //mine: '1KKAYAvc82Bouj4MVfxEoo1qpvxRL8hc5r'//;
  
  useEffect(() => {
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(location.search);
    // Get the value of the 'origin' parameter
    const originQueryParam = queryParams.get('origin');
    if (originQueryParam) {
      // Set the state to the query string value
      console.log("Found Origin:", originQueryParam)
      setCurrentOrigin(originQueryParam);
    }
  }, [location]);

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo);

      if(contractInstance.payOut !== toByteString("")){
        const price = 
          bsv.Transaction.Output.fromBufferReader(
            new bsv.encoding.BufferReader(Buffer.from(contractInstance.payOut, 'hex'))
        )
        console.log(price.satoshis);
      }
      console.log("setting remaining supply", Number(contractInstance.supply))
      localStorage.setItem("remainingSupply", contractInstance.supply.toString());
      setRemainingSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin, currentLocation, finalTxId])


  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("setting total supply", contractInstance.supply.toString())
      localStorage.setItem("maxSupply", contractInstance.supply.toString());
      setTotalSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin])

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/txos/${currentOrigin}/`;
    axios.get(url).then((response) => {
      setCollectionName(response.data.origin.data.map.name);
    }).catch((error) => console.log({ error }));
  }, [])

//   useEffect(() => {
    
//     if (!wallet.connect) {
//       return;
//     }
  
//     const connectWallet = async () => {
//       try {
//         const key = await wallet.connect();
//         setPubKey(key);
  
//         const signer = new PandaSigner(new OrdiProvider()); // <---- use `PandaSigner`
//         setSigner(signer);
  
//         const { isAuthenticated, error } = await signer.requestAuth();
//         if (!isAuthenticated) {
//           throw new Error(`Unauthenticated: ${error}`);
//         }
  
//         setConnectedPayAddress(await signer.getDefaultAddress());  // <----
//         setConnectedOrdiAddress(await signer.getOrdAddress());     // <----
//         await handleGetAddresses();
//       } catch (error) {
//         alert("Something went wrong signing in. " + error);
//       }
//     };
  
//     connectWallet();
  
// }, [wallet]);
  
  const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
  const layers: LayerData[] = [
    new LayerData(
      toByteString("Background", true),
      [
        {
          "outpoint": toByteString("cea795456541c3e83ce335874ec47258fff305d92c7cdd5e41fcc7bba1cd92d0_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Actual Ignorance"
          }), true)
        },
        {
            "outpoint": toByteString("215b790c0b3f22b1e8419ed68f3e1a50636ed564d87b16d11ac93f3ce7499bff_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Bitcoin Dragon Lair"
            }), true)
          },
          {
            "outpoint": toByteString("16dd8d1d2aea0e66be0fee99c47f3fde4bdacde2374a257cc8347dfdeee5a15f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Hokuto No Ken"
            }), true)
          },
          {
            "outpoint": toByteString("c1438785d1935ae65475eda7e7c1ec57d0c48d5fe34005e228f242ac1e17b3a3_0", true),
            "data": toByteString(JSON.stringify({
              "name": "As Above So Below"
            }), true)
          },
          {
            "outpoint": toByteString("6d6d220297c3014d3193b276d7e170cfa65bf654f11a5fa0684532cf4c47af95_0", true),
            "data": toByteString(JSON.stringify({
              "name": "LOL"
            }), true)
          },
          {
            "outpoint": toByteString("924cfa186955d48a5e71981b9c76200cf86f2a5437372fdbc236d2388ea80c0a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Matrix Alley"
            }), true)
          },
          {
            "outpoint": toByteString("1aade829f30b2d74026d4bb58528a82918f070e74ab750d2a26a98f99c1c11cb_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Best Is Yet To Come"
            }), true)
          },
          {
            "outpoint": toByteString("b66ff68d2f70a856377a78be996a1d724151138a98bf74d70d480364d53789b7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Eternal Game"
            }), true)
          },
          {
            "outpoint": toByteString("c023e1aa1236af60ea753eca09ca2b40421ad9b783f98d47d6f8d38ae47b9532_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Land Of The Free"
            }), true)
          },
          {
            "outpoint": toByteString("53e08b8ca89c6416494852342489f1185b62c93c4c8f9b0dbec32f4013ca47fe_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Swamp"
            }), true)
          },
          {
            "outpoint": toByteString("3cdf1f240d187beda296388f4d02d56686cdc763083531132db2727f5184c358_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Red Grid"
            }), true)
          },
          {
            "outpoint": toByteString("0b77507060f1a2dcb29ad7522554438f4af54121657f849a799ce7557a70d898_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Loading Room"
            }), true)
          },
          {
            "outpoint": toByteString("39803dd65f0775b725e9876bc52770015293a45b65dfca1b9a5aad8187339415_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Retro"
            }), true)
          },
          {
            "outpoint": toByteString("d48a1b20dca1a509936c79e41282ec183f1780f63063ef7c25ead3cc689e89fb_0", true),
            "data": toByteString(JSON.stringify({
              "name": "White Rabbit Hole"
            }), true)
          },
          {
            "outpoint": toByteString("51a73807889528b5105dcc70409e853c80abdf1ba3ea087779332a5dc1174f14_0", true),
            "data": toByteString(JSON.stringify({
              "name": "40 000 ft view"
            }), true)
          },
          {
            "outpoint": toByteString("2cf9bf39b717de53ec26823e9558399db72cacd225f649d66ba98a9e28ba7811_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Antarctica"
            }), true)
          },
          {
            "outpoint": toByteString("56fb53ce34119d88a00b3ba9edf5a1ddab7ced0a0926eca004ad845e48e2ffa3_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Fake Moon Landing"
            }), true)
          },
          {
            "outpoint": toByteString("66e541575cc9af8335f379ade5eb62debc6b085d3e54fffcfc4c72dfe667e004_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Winter Forest"
            }), true)
          },
          {
            "outpoint": toByteString("c7811c0b1e420cc59bb40e2fbe5b409c12ced81c498d85c29ea25ce7e0a80684_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Dream"
            }), true)
          },
          {
            "outpoint": toByteString("3f9f7224d8fc9b673fd6e81593040fa4738bd5e4555530d9adea6129d01ac98a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Grey Camo"
            }), true)
          },
          {
            "outpoint": toByteString("b055bc18846ec3617104730bfa54f639f3d17d3d487ae311f79b9b1c6db6fba0_0", true),
            "data": toByteString(JSON.stringify({
              "name": "D.U.M.B."
            }), true)
          },
          {
            "outpoint": toByteString("451411168d868a84473f03e7aa03bb920990d36e5852c1696959caad8c9ca13a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "WTC7"
            }), true)
          },
          {
            "outpoint": toByteString("f91b933556b9ae79cd6bb45eb5eb671d31db1847080cf2bea77031f452d4dfcf_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Halls Of Justice"
            }), true)
          },
          {
            "outpoint": toByteString("85d7b2bc101a797176278bc9ce654f9009c35f1eb74bb9efadcf0ff94210fa70_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Star Gate"
            }), true)
          },
          {
            "outpoint": toByteString("9d12da257f00e0c4049f604e1e0c77410ae309cda55523dd3d10a9bfb7f9accb_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Red October"
            }), true)
          },
          {
            "outpoint": toByteString("fe43e8917b3e14b27eb9be1d084826926cc9b28679bcd15b99f0244cf00d050b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Doom"
            }), true)
          },
          {
            "outpoint": toByteString("0a86ad7767f41f3359856c451607384a18c38b2e7b6a47e1c562b95e62004130_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Epstein Island"
            }), true)
          },
          {
            "outpoint": toByteString("f8f124bbe30b2df46126968ae46f35254fe205e83c9443dc9d0d8c6f57f217d4_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Tartaria"
            }), true)
          },
          {
            "outpoint": toByteString("da67bd3e058114862f722f68a775b281ed8b1a47629cdd1d758c3e49fd17fea2_0", true),
            "data": toByteString(JSON.stringify({
              "name": "MEMES"
            }), true)
          },
          {
            "outpoint": toByteString("1af2ecadd4899c8e5825348b60c084f19abb5bf67cdc2309cc7cc0d1846adb5a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Reset"
            }), true)
          },
          {
            "outpoint": toByteString("bffa7ad37d03b1945df2ed64e96fcfd9040d69b62739b964c385cb00094fd96f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Vatican Servers"
            }), true)
          },
          {
            "outpoint": toByteString("717ebca2db7bd89e784544b91313ec5d12322fb785f823ed4cb7a8022eda1ec9_0", true),
            "data": toByteString(JSON.stringify({
              "name": "99 Percent"
            }), true)
          },
          {
            "outpoint": toByteString("b92fa109f49a44c57f0e67606ca1547ea0a0affbe8ac4b1b48481e9b95aee486_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Control Room"
            }), true)
          },
          {
            "outpoint": toByteString("ee2259701bbff6b4c595706156806d023eb34a308ba3c38358093751097aa6df_0", true),
            "data": toByteString(JSON.stringify({
              "name": "They Live"
            }), true)
          },
          {
            "outpoint": toByteString("286a534940ae5508d2db57571688b942474631508c70a8fc9d62ebda2a43a700_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Firmament"
            }), true)
          },
          {
            "outpoint": toByteString("deedeb607d8c0a8240cc3eb29abc2020ca8c73415baf41a7407e265323008956_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Hemp"
            }), true)
          },
          {
            "outpoint": toByteString("65bf1dceef0e0847fe6961562fcc2d77d2a54729b0b6fe8047d3c60d6d7adbac_0", true),
            "data": toByteString(JSON.stringify({
              "name": "We Have It All"
            }), true)
          },
          {
            "outpoint": toByteString("6448fe5b01cbb5c15c57dcd8427db3b45a301c45ef14dfd52dcc59aa1683333b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "TR3B"
            }), true)
          },
          {
            "outpoint": toByteString("3bc8a336b3ad7fb36e13d8e9ad3b123511238b34ba9e907170dd72086c2436b9_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Space Force"
            }), true)
          },
          {
            "outpoint": toByteString("012d4e20ae91e4ccb34357d78299ae0484cba55e901ce56b838b7d07f07fc699_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chicago Worlds Fair"
            }), true)
          },
          {
            "outpoint": toByteString("2566aaa20637bf4ea62c7b4ea3adb7cb973bc4ac0e997d878548366a9b55f395_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Science Fiction"
            }), true)
          },
          {
            "outpoint": toByteString("fa87b5377e59d7e6511e38d2254a1fd898a5e600c01739ccdfd924a6cd08f9e9_0", true),
            "data": toByteString(JSON.stringify({
              "name": "The Storm"
            }), true)
          },
          {
            "outpoint": toByteString("8fabd94421858b95e58d61320974cb4a60e9017f158298b95da7e6933874c177_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Movie Theater"
            }), true)
          },
          {
            "outpoint": toByteString("2463cae89200ab2bcd5ce0022f06dbc229c2dc8beb2f0e5b67c502b7ba43744c_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Night Watch"
            }), true)
          },
          {
            "outpoint": toByteString("a08437909390c04c5181d70c6658d3f104c122f190165d556c72ff7521cbe7cb_0", true),
            "data": toByteString(JSON.stringify({
              "name": "DeLorean"
            }), true)
          },
          {
            "outpoint": toByteString("bead51f257a5a06a1f8b952b940c099886ab8df3628d5933aa1b822d533e0e11_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Green Camo"
            }), true)
          },
          {
            "outpoint": toByteString("efe2cbbd35a36c261d772ed93ee6a4d6615453c93cd8efd12342941c07b326f7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Tell A Lie Vision"
            }), true)
          },
          {
            "outpoint": toByteString("d03b91649f69d990bdb3568bd558ea824a52dfd79bec6d51bd65881c83706ada_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Ice Wall"
            }), true)
          },
          {
            "outpoint": toByteString("ca8b01455d97a9645d5499c3c02f57626d0cd23ce586a67533b6af7db24c5afd_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Red Pyramid"
            }), true)
          }
      ]
    ),
    new LayerData(
        toByteString("Back", true),
        [
          {
            "outpoint": toByteString("30e9ec4fe5b60c86484bbf7663870a55b9f5a471834ad3b8ea45cb646f1fb881_0", true),
            "data": toByteString(JSON.stringify({
              "name": "FRENS"
            }), true),
          },
          {
            "outpoint": toByteString("2d0714f74710acf03f984c29fde0994d08968d55bc473fca67b3c204180fa882_0", true),
            "data": toByteString(JSON.stringify({
              "name": "AR"
            }), true),
          },
          {
            "outpoint": toByteString("78c4d87c2d027889c1ce12e86f2418547450d2bc8bc2a8f04cffa25a899461c4_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Minigun Turret"
            }), true),
          },
          {
            "outpoint": toByteString("fb1021e3f97f520468437843d54f1e78978cedf8239d2536be793ad09f0e1214_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Green Halo"
            }), true),
          },
          {
            "outpoint": toByteString("a0b336bfafba1120d9ca8353877b176b7d9b09dd177261db386855e9ce942a06_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Noticing"
            }), true),
          },
          {
            "outpoint": toByteString("ec94cf8dd70b0355ca785598b64c4c6630a1c0be5a86d7023efaccc9b7ac371f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "No"
            }), true),
          },
          {
            "outpoint": toByteString("1574ae243fe5c0c33df13d23421d42a118de764e8625e54f660d1d8aeb2aa784_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Red Halo"
            }), true),
          },
          {
            "outpoint": toByteString("04b1cfd1b1a1eefbed88651a33ee2c663b15d443ccea05c683a5e2162703f6a7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Meme War"
            }), true),
          },
          {
            "outpoint": toByteString("80d1f92222a35083893041292e8ca33e11fc14939c9666bfa451832576451e99_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Katana"
            }), true),
          },
          {
            "outpoint": toByteString("29a58522ce8d5dc9719d374f3842988efdadf6ccd8ffbb72abb3e7718837e804_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Wings"
            }), true),
          },
          {
            "outpoint": toByteString("d14085ab9e243190a8b61b032489ead8ac83df58927c8d05489ee26dc4d53917_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Halo"
            }), true),
          },
          {
            "outpoint": toByteString("88b400e9eba08632378cbdc0e1c258c70be544b773467f74df94dfde85758e9a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Strong Vibe"
            }), true),
          },
          {
            "outpoint": toByteString("5bdb0523cfd495c588e2984225a13ea97e1941d2a3bd8c79b9fa242fcfbc167e_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Two Katanas"
            }), true),
          },
          {
            "outpoint": toByteString("6af85eec1cb98a63faaf6511115d0153dfb624af9df08a8c3b4c0e14a833131c_0", true),
            "data": toByteString(JSON.stringify({
              "name": "GM"
            }), true),
          },
          {
            "outpoint": toByteString("8972bddd93d182b89238676489bd6166a96450317432983fe5c6b7271562e478_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Battle Brothers"
            }), true),
          },
          {
            "outpoint": toByteString("316302b907eb9c94ec6890cc1bc9b4c0b8f23cb80da7c8652abed0a8d9e664d7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "White Halo"
            }), true),
          },
          {
            "outpoint": toByteString("ea9af83f895acc3d15fd670293904a95ba47e11abcd0c90bcba0f3347892864f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "None"
            }), true),
          },
          {
            "outpoint": toByteString("cb0b42668e2d2a9b537d1bd5d1fb03325bcdce754da1d1ae8d7a534bfff2ff10_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Vibe"
            }), true),
          },
          {
            "outpoint": toByteString("e5e0110259f1f07b8e86c1922e1a2e1d3f7c38da5322e720801b2d4645d5af45_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Missle Launchers"
            }), true),
          }
        ]
    ),
    new LayerData(
        toByteString("Boiler Suit", true),
        [
            {
                "outpoint": toByteString("79c790e910a4ffe793cdc0e0ac9eb5b11597b4ca5ba8ebe102c966e56941a000_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Ghostbusters",
                }), true),
            },
            {
                "outpoint": toByteString("a313aebc9289287573bd1a42de2ac7f678a5939ace62ab8d3860453550484626_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Question The Narrative",
                }), true),
            },
            {
                "outpoint": toByteString("dabea85deca72b6d6267922020c20b12eacab6682a2c17eec6216974d0853169_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Retro",
                }), true),
            },
            {
                "outpoint": toByteString("35982713d5c0847ddf33fcc421f019094a41de5f0cfbcc6f729e5b73f65bc413_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dream"
                }), true),
            },
            {
                "outpoint": toByteString("6b469139f17c78094d3d78677271de3ac2cba7b11f99e2675f040b87c85e18de_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red Camo Vet"
                }), true),
            },
            {
                "outpoint": toByteString("b5751ac96115508e9b5d385ef764ccd41e6e3acd4b3ff9d7828e8509d2e52fad_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Grey Camo"
                }), true),
            },
            {
                "outpoint": toByteString("377a1cb2929aed8a4926354732fe77617d242ac5b755b4121e65dd0f97b27e1d_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Close Combat Specialist"
                }), true),
            },
            {
                "outpoint": toByteString("9582501c512534fbb989404974cf57ce35348169f16bc3a57fc3e3824e22549e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Desert Camo"
                }), true),
            },
            {
                "outpoint": toByteString("6d8a6920fad1b79ba8516b188c120e7edc7e67cc014f4a18a43f2957a9306781_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Golden"
                }), true),
            },
            {
                "outpoint": toByteString("e93c5375b5844515c9ac0fd067b131276de65b470d5923e63a110a27a9e2ad44_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "White Camo"
                }), true),
            },
            {
                "outpoint": toByteString("4592bd998792406616e202f32e70f6c253e7302acd9b0028ff1250aa49cb8bf1_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Aluminium Foil"
                }), true),
            },
            {
                "outpoint": toByteString("338e21756f924767e42bb4dd48c68d83b3fb170532c20ed9afb907c10834ba31_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Spec Ops"
                }), true),
            },
            {
                "outpoint": toByteString("4a31a4b88adecbec1352d138d0c3d5082d10d6a1c474fd2baec6e5a45c0858ad_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Patriot"
                }), true),
            },
            {
                "outpoint": toByteString("a48443fd8c8bcc01907d0b19592510fa0daf6ca1472706e73d821dd8fe265bc9_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Medic"
                }), true),
            },
            {
                "outpoint": toByteString("a5454bff65edd3c2f6c0a52174ca03736e9104f28f6051b2c3d19f1bf91d821f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Digital Camo Vet"
                }), true),
            },
            {
                "outpoint": toByteString("d347f66bca20ae7679637eef7afbd84570cc2931ef8e6a580998e7ace3af5b86_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red Camo"
                }), true),
            },
            {
                "outpoint": toByteString("50e4b1f65325956697eec4fbd99c748ef7632ffc2a408157429af79fbdfa025e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Pain"
                }), true),
            },
            {
                "outpoint": toByteString("059e6fd8965f7c2711495d6e6569036559f9941d3df70a66f320a9a01c8e90ee_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Green Camo"
                }), true),
            },
            {
                "outpoint": toByteString("0b7b3be5f901f845a4fa1958a2128eca103e57c8bb8b670b2a622bfe09cc4a71_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Digital Camo"
                }), true),
            },
            {
                "outpoint": toByteString("ed8c2a5b5e9d9dbbfc955550fddf5d473ab0e33bbbcb558292e756d467668993_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Desert Camo Vet"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Mouth", true),
        [
            {
                "outpoint": toByteString("12a93641b0063aa8cd7025554e638b2c89efe4db66e54935ba3cf373d43424ca_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tongue"
                }), true),
            },
            {
                "outpoint": toByteString("304a0c9dbf8877d863cf597cb06db09ae42d4476e0c5aa75e850b97a1f38a03d_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Upper Teeth & Tongue"
                }), true),
            },
            {
                "outpoint": toByteString("9f1300d6a867c8eaee319b21b98e806f1a18a23df3923344d0b10518a5758c02_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "White Teeth"
                }), true),
            },
            {
                "outpoint": toByteString("f84241f33ebb6fe24dac1cf1fe4e561b2b18a2123e8e8f7676fdbe46ea4b91ff_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "No Teeth"
                }), true),
            },
            {
                "outpoint": toByteString("58f6851d4edaf7b62634311563e71db9fad7d4b8e3169fa29c0787af5b990649_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Bad Teeth"
                }), true),
            },
            {
                "outpoint": toByteString("1054955bb076510e3f943643e33bed3fdaa2e281bfd8fdffc0647b88d4f15189_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Upper Teeth"
                }), true),
            },
            {
                "outpoint": toByteString("1974beb29a26af681fa7aa9163c1b5086a3294d5cfe6ce3dd39f6ffc8bc3f4c5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red Pill"
                }), true),
            },
            {
                "outpoint": toByteString("d292c2226b69faaa020e19ea7bd1a6d7f78567ae927e2d1699f973de085ca122_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black Pill"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Eyes", true),
        [
            {
                "outpoint": toByteString("4c5ca73e1fd3640b2651c9e14c6b3232f471a43200933c14790c67ce22e54101_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black Pilled"
                }), true),
            },
            {
                "outpoint": toByteString("62ab4f89cbebb2c92ede069bdaa112be93ddf0dea6343eecd102e3584cd0dcd2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dialated"
                }), true),
            },
            {
                "outpoint": toByteString("38cba7f205140419446df11292ab10c3127b85c2a278932158f58aa201f0346c_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "White Eyes"
                }), true),
            },
            {
                "outpoint": toByteString("1c2846c0886749002c62af681fb659280f07494816a65c8c0d3e9609aeb3b6b0_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Ready"
                }), true),
            },
            {
                "outpoint": toByteString("970351f5aad51c917ab9b782127bc51230446c24ceddca439c5f33bf1eaf68da_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Yellow Eyes"
                }), true),
            },
            {
                "outpoint": toByteString("c636a8740a057fd4dd5fdcce412318edaa3ef9acfc7972f657552bc7ffb77bab_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Fresh"
                }), true),
            },
            {
                "outpoint": toByteString("99e12eb6edb0b0c385018cc82c990d22684a7326b00235c90242ecc524ae6045_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Rinnegan"
                }), true),
            },
            {
                "outpoint": toByteString("7cf3b55fa3a9b9b724b35a27a835a078a4f611a039a2879b193fa975ecb3ce2b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Glaucoma"
                }), true),
            },
            {
                "outpoint": toByteString("bfc90e835d3fa169a0d9deab23cb70f88ae452c30b5ecd11730067fd5cca89ab_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Sharp"
                }), true),
            },
            {
                "outpoint": toByteString("647fa6a95fd6daa57dc54107e7b5d4017e250b51a72f052970f403461668868d_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Freemen"
                }), true),
            },
            {
                "outpoint": toByteString("5b650266fa391d0b9ae450c8d98fe1fdc298528cd18b39b5713290e64860f34b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Greedy"
                }), true),
            },
            {
                "outpoint": toByteString("1dd3f2482dfa788c087cc0d069dd73120b67333e1cc69b0f1492205d4e9c6b84_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Sharingan"
                }), true),
            },
            {
                "outpoint": toByteString("33fe95bb59c696940a21891a3677314687a5a3300fd93b77d08c6a3620549e32_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Target Locked"
                }), true),
            },
            {
                "outpoint": toByteString("95043be1b8c6477a961d10653111be4e943807f6c12bb4a9fc718ad9a6495d7a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Awake"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Skin", true),
        [
            {
                "outpoint": toByteString("08ce87188e06b95b73377fcb509ac61132571288d357d6c1f57039ebef710e0c_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Yellow"
                }), true),
            },
            {
                "outpoint": toByteString("79c36a3c5829b974d3546d4a9009d9783f383a5c09df63c0772541c202c0e8a1_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "War Paint"
                }), true),
            },
            {
                "outpoint": toByteString("4545fecae5fcee98a09432896452f8815a710c9b3da1b0b8dd4dcb1f08877636_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Veteran Toad"
                }), true),
            },
            {
                "outpoint": toByteString("201abad111352eb64e3b90257ac6c7bd02e7a48199bd188c045bfbe6a0b61cfa_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Blue"
                }), true),
            },
            {
                "outpoint": toByteString("bcf903fbf72cc6376f5eaa2863f0c3b4a1138c37de212fd22eaae90d4ee807fd_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Golden"
                }), true),
            },
            {
                "outpoint": toByteString("0a695987a5bb85dd11ea5625316afa44819c3b4ba080e3d4675192e6a849635e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Green Tattoos"
                }), true),
            },
            {
                "outpoint": toByteString("d2b0d9db5132d9f999cce34efdf8a652cecea61c46dcb1ee8f45edc6eb544d8a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "White"
                }), true),
            },
            {
                "outpoint": toByteString("0c2762a70c6e3323ce60c970a69f36bad666a747da33e961d266d56333d51cb5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Poison"
                }), true),
            },
            {
                "outpoint": toByteString("bab1dab50e697f0a538896611ffd949b142a0ac6f526947ae4be8fa03d8533f5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Green"
                }), true),
            },
            {
                "outpoint": toByteString("dda0de0edd683842106398dacc6219132280beca355ab16afbb0d2e57bf7dfcf_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Green Pumped"
                }), true),
            },
            {
                "outpoint": toByteString("c7f3c2ae3ae777242ff3352897081e4d1ab31518310903cc913d824d19ad23cf_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Purple"
                }), true),
            },
            {
                "outpoint": toByteString("5acb6749d4b9e2629bc43030e6fa82a558f7dbc2057146039cb7ce9efe341c14_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Bright Gang"
                }), true),
            },
            {
                "outpoint": toByteString("8507b8f542ec2a4134af65d06be4a26a6940a8e748db998099e02b3ce501ecb4_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Orange"
                }), true),
            },
            {
                "outpoint": toByteString("b3c63b79bc8a04d1eb603a9f559138e12fc8752bc1945efbb92555c065ce8489_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red"
                }), true),
            },
            {
                "outpoint": toByteString("6080e7f23316f6f4ae61783e5c9df4582ff603e70733a32911662404ede2d520_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Body", true),
        [
            {
                "outpoint": toByteString("4f1dafb36f678ae1e57c053e6a03becbacbe644983cbd3a7cd748053653d1a8e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Yellow Vest"
                }), true),
            },
            {
                "outpoint": toByteString("b210ee37ab57b048e6b68e282dc29b3030f5e37ea50469fa7837fc1f89b76747_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Vest NCSWIC"
                }), true),
            },
            {
                "outpoint": toByteString("d4ab96858c8b454088953fa6ef13ea4c4a334bd56c7bcc3d184c5f2d2e8f1eb0_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Padded Vest"
                }), true),
            },
            {
                "outpoint": toByteString("c74d49665ee24d8c8a50e7b6a44a94f30e1c6ae0dfdca447cb9324dd59615649_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Vest + Pads"
                }), true),
            },
            {
                "outpoint": toByteString("2c0aefbf91d1c26716ff9286e2575a5dbc71574c85bc61efb08d85be8df8a237_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Nothing"
                }), true),
            },
            {
                "outpoint": toByteString("484bb2a28c0b104692d5ca1d68d8a762494c932e80ff67cc0e18c89abf610cdc_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Grey Vest"
                }), true),
            },
            {
                "outpoint": toByteString("3aa0dbcc1912d1e0bba7f690611b92d9727d736f9c754bc5a2eca71f39a325d2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Power Armor"
                }), true),
            },
            {
                "outpoint": toByteString("88e7733d201878e6931f41fbcabb22a11d80be220abc0315b2b39994b2e2e645_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Packed Vest"
                }), true),
            },
            {
                "outpoint": toByteString("81206e87567710f24a0ad5d0321fb927b230deb8be2187bca93177fb27308df1_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Ammo Belt"
                }), true),
            },
            {
                "outpoint": toByteString("cc68d7cfd13a1a01cc05c693a2ba2ef7ff2e76432021fabd7c74168b6a32771a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Vest Grey"
                }), true),
            },
            {
                "outpoint": toByteString("a528f8370b4e587eeb4ed5f1eea77d77f55109d6f3ae416e12d00a66c0bcfe33_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Breastplate Of Righteousness"
                }), true),
            },
            {
                "outpoint": toByteString("72b6d5a162ff202ca3d0736a794fc79097a1600dcce0e7607c7d34e94397aae5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "None"
                }), true),
            },
            {
                "outpoint": toByteString("4064b9357602b29868660f694c034ea0577b511f6303bed13930faf9b43cd319_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Camo Power Armor"
                }), true),
            },
            {
                "outpoint": toByteString("9e780f8e8e3eee31b71f03a99d8f9b1cfd92d0eec7e7bdb1aa965ffac79f52e4_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Anon"
                }), true),
            },
            {
                "outpoint": toByteString("62a4abf9878e03cb14ec051a10c2ff8dd1e499701508ce99eea08666a9e898f9_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Vest Black Pills"
                }), true),
            },
            {
                "outpoint": toByteString("3ca5ba829e75363d8e427c89e79890b58735e884524ee8aad7081e7e7dad37e2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Vest Knife"
                }), true),
            },
            {
                "outpoint": toByteString("800703477ce865dd5c343f8b97a31ba3b3fa70d9c6f9f26519dc270c6e89a822_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "No Armor"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Head Item", true),
        [
            {
                "outpoint": toByteString("33d12e48133e34f0def30b95c12bde19ca537f3ca15fd7f9bdd571d1daadb1ba_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Deal With It + Based Hat + Cuban"
                }), true),
            },
            {
                "outpoint": toByteString("c808b20878ec6cd214582983bf25e92b5faea07bcbb8442c96f6769b881fcce2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black Glasses"
                }), true),
            },
            {
                "outpoint": toByteString("196d59e9c2c1b8e6462fcf7fdd40429214a2180f73a7f17958ae2a115c60ea9b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Spartan Helmet"
                }), true),
            },
            {
                "outpoint": toByteString("d900c167ef04d27b5f9df7e11db0f975bca0769e59ad764ea0648d3b125dc867_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Deal With It + 420"
                }), true),
            },
            {
                "outpoint": toByteString("92ab5a55d87f95c5a55d1f15e6d096eea6bce4a50f6185ea41724840cf968a1e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Gray Hat"
                }), true),
            },
            {
                "outpoint": toByteString("324c2880fd2008e230e25a70a41dbe588a122e72d03dc552f69a41669336dde8_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Helmet + Cuban + Deal With It"
                }), true),
            },
            {
                "outpoint": toByteString("70d7211ea76790474c5b348a73ab534c52c3e0f20cab1e50f85b299bfbb19744_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Twetch Hat"
                }), true),
            },
            {
                "outpoint": toByteString("f071ee302252aa22d65eacdac13fd545db58b7cbec7be2158348222905b8664e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Desert Camo Hat"
                }), true),
            },
            {
                "outpoint": toByteString("89153c62e8f859286905413f66e02dd498c7d18cc6b122220406e306b06c5c75_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "White Power Helmet"
                }), true),
            },
            {
                "outpoint": toByteString("03b6a0b04c814050dfa6726f12bbd32668376699b987230b14d9fb76879c967f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black Hat"
                }), true),
            },
            {
                "outpoint": toByteString("adc0559295740c783c139beb331693e187b335701dda2799faeef1b6525c7b35_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Punk"
                }), true),
            },
            {
                "outpoint": toByteString("e9b25acdfa97f41d8f2a78aa43f008d5f98f4ed8cf47dd8611f00549795130ec_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Frog Vision + Helmet + Ski Mask"
                }), true),
            },
            {
                "outpoint": toByteString("63a6e795e529659ef015317baf1499b77582dedb307b7919893d1db4f142b359_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Bionic Eye"
                }), true),
            },
            {
                "outpoint": toByteString("480b9e62a5665e2aed9520e0b10bb9975c2702402b615fdfbe6fbfd483396a92_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Golden Skull Mask"
                }), true),
            },
            {
                "outpoint": toByteString("4c97b0bd82b23118781f7b9b0eae7960b78fc2b838d643ea1a6b01b600d9f628_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "MEGA Hat + Red Ski Mask"
                }), true),
            },
            {
                "outpoint": toByteString("18d74c952f175ef9ef13f0753bc4ba12662aa09d2a1f895105d8ef2dc0f594d1_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Green Beret"
                }), true),
            },
            {
                "outpoint": toByteString("86cb6093ac21c4a36659e8ad0c697925448a19c640c291a2cb14bee33260f47b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "White Hat"
                }), true),
            },
            {
                "outpoint": toByteString("94e69972ad4736885e15d7b08cf1158cba9639e6c52c08c89b886763bd49b24b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black Hat + Cuban"
                }), true),
            },
            {
                "outpoint": toByteString("00fcb7d3666265cc617bf3ea26f0d68d8eec4475be195c6ad21e139f3e84f0f4_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Helmet + Cuban"
                }), true),
            },
            {
                "outpoint": toByteString("a938d266acfc30e64026a163161532d7eb983553d0021af79d7c85b90597237a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Camo Hat"
                }), true),
            },
            {
                "outpoint": toByteString("fff219cc4a500dff1014b94f402f6bac6853980af58701b9b97d5e6813dbb73a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black sunglasses money"
                }), true),
            },
            {
                "outpoint": toByteString("5fae40ff8752aaaad3722a41352ebdfa9122cc1f02d18760a60d169585e5ea92_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black sunglasses NPC"
                }), true),
            },
            {
                "outpoint": toByteString("bb0b66249a72a007f4737077c7fa516388337b22a62e83131b6fdd4f716a9319_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Ski Mask Red + Cuban"
                }), true),
            },
            {
                "outpoint": toByteString("d8b9ecab998ad5d994810894bc9b4fdf97abd9b127a194c742d9e0a9b3eda855_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "White Skull Mask"
                }), true),
            },
            {
                "outpoint": toByteString("a168d0d547fe43c0e2fcf5510d22c5d257785759839cfa4c568893da750d8355_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Ski Mask Camo"
                }), true),
            },
            {
                "outpoint": toByteString("ac94fa2080ae66da9db873ac8934615e2af172ab3d7a36d69cc2b6f996746143_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Old Helmet"
                }), true),
            },
            {
                "outpoint": toByteString("2239470e4c81ea0577c32c250f28479ec590a9e8c1cf832bc5009734837b93e0_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Green Helmet + Ski Mask"
                }), true),
            },
            {
                "outpoint": toByteString("a880bc7f833ee9bd4d9f8dc849ca5f363767fbd250dfa4e529ec71e0efeb0279_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Cuban"
                }), true),
            },
            {
                "outpoint": toByteString("cdfb3c9fcad804c5d7936c27d3fb1c0992f233b2c484c021f64be51b384f6afd_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Mega Hat"
                }), true),
            },
            {
                "outpoint": toByteString("534ec5a02134df9c56d0213b2e99cecc9ffe23deec7428d2cb154081b9ad32b7_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Based Hat"
                }), true),
            },
            {
                "outpoint": toByteString("5923f8a2582d4ce7ffe31123a12bf4aca8dbc3f74dcc454c48bd8c42d391b2f2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Born To Meme"
                }), true),
            },
            {
                "outpoint": toByteString("a8d9f773ddcdb4f919edf47dd4317f8033c7cd29a28cbe0f5edd68e4176c1763_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Grey Helmet + Cuban"
                }), true),
            },
            {
                "outpoint": toByteString("7769f2777aac584179aa763f20bf573c2cd75aa67ecfbf8d2bc2a6511b99a988_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Green Helmet"
                }), true),
            },
            {
                "outpoint": toByteString("913971a7fe334621d5a0235c0ebd56281fb3dd7086fc98123d8bd7b2a9fd6690_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red Mask + Box"
                }), true),
            },
            {
                "outpoint": toByteString("0c06d6422b5d02f44dda6cfa42aaa26eece6d365482e91e33996fa06b9607f8b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Black Skull Mask"
                }), true),
            },
            {
                "outpoint": toByteString("28374341f7b31666bbbc2d527b1da728dcad8d9c7e47caee466bb07d7791b8d9_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "No Item"
                }), true),
            },
            {
                "outpoint": toByteString("66813c93aaff00a022e1e1c1b66cc5d4c998c58fece176a470e35047a79d5ffd_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Artist"
                }), true),
            },
            {
                "outpoint": toByteString("d37f46d7de59d98f80470d5e830c1c66da6efa6156e17f034f68e5d75ded5331_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Skull Mask"
                }), true),
            },
            {
                "outpoint": toByteString("ea7759361c4fccf852c5f0adaf522d874e8aaffcf53f5c4e7fd349b9a122e97c_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Hermet Headband"
                }), true),
            },
            {
                "outpoint": toByteString("12594bacb28562e4d4454cb0180e9493767b14cc68c309b40dabfcb8e0106fcc_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "None"
                }), true),
            },
            {
                "outpoint": toByteString("6744bec5b9f4f6871c55a854cf9867424895c9930f24ca719895dc1df8ecb73a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Frog Vision"
                }), true),
            },
            {
                "outpoint": toByteString("418c9ebad112239e86368d43f687b05945657895ae6615f16d657fad260691d1_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "White Ski Mask"
                }), true),
            },
            {
                "outpoint": toByteString("20f7c91372cd37e39d8801eb45f0d5aae2e568ab8f31ca6ab701144f89bb75ce_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Grey Helmet + Bionic Eye + Cuban"
                }), true),
            },
            {
                "outpoint": toByteString("28606051b9eb483c3e32c559eba36831b0b71255bfe6140ae95b0834ad6971cd_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Helmet Of Salvation"
                }), true),
            },
            {
                "outpoint": toByteString("570b16069d33ac4a8e94006a422464df3f8b7dc9a7005468ef45a2518f02e092_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red Beret"
                }), true),
            },
            {
                "outpoint": toByteString("43b67823f817073815efacd6c7b1e2bdddc63f311f0419157e4f65a71c1388af_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Box"
                }), true),
            },
            {
                "outpoint": toByteString("eb7ab30d23c1fab44315b2b49c69c0902ddb3d4575380a23aa91a7d817fda6d5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red Headband"
                }), true),
            },
            {
                "outpoint": toByteString("1585903dc5beb8cda4a2d20541af4a4090cea9677165e41558444106e897ad55_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Helmet + Skull Mask"
                }), true),
            },
            {
                "outpoint": toByteString("e93d4d694853062c74d1c693f30c1f403eb78e2d3cfa1238f237146745d6e692_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Storm"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Weapon", true),
        [
            {
                "outpoint": toByteString("098482aa11a52186e13d8d5e9e14b562ab51c81213cc5a3676671e4f4582ceb7_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Grenade Launcher"
                }), true),
            },
            {
                "outpoint": toByteString("9a21ccfacd4636927f788f696908a53163a8e23f74496df91fedde2e9c5d4f73_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Golden Minigun"
                }), true),
            },
            {
                "outpoint": toByteString("8e5ddbe3d3865aa482c59c6f48a9fefce1bbe669c3183a32066a3bcef6c03510_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 5"
                }), true),
            },
            {
                "outpoint": toByteString("10758f0f62075dbff07638b6493ae608364ea1026bf5c803fcb1c8ad40fba6d6_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "No Weapons"
                }), true),
            },
            {
                "outpoint": toByteString("07249f42e1c8314d03fc1134de96c462256da5871282836d05e994700b10a014_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 4"
                }), true),
            },
            {
                "outpoint": toByteString("15eb77f186a408486250ad9bd5c1bfbb92cbac1bd574eeecab6bc6a00185d987_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 6"
                }), true),
            },
            {
                "outpoint": toByteString("46060347990d5f4900818514d179023f108579b7a14aa0ef34524b5b8fba34df_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red AR"
                }), true),
            },
            {
                "outpoint": toByteString("5ecac8117a935abc4b5020bf86c03eb2a07ca97eeada59c44b80f410fab633a4_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Custom AR 7"
                }), true),
            },
            {
                "outpoint": toByteString("c2a610bb1276bfacd78946b04c3be453e07ef1cbf52e63d51d1e89a6f7a6293b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Minigun"
                }), true),
            },
            {
                "outpoint": toByteString("5ac15ab125bbb65e0af3e5e67ae0992944963356906587ecb84ad7797c9f7e65_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 3"
                }), true),
            },
            {
                "outpoint": toByteString("815ded963f6e5d41f9ab189613d4fad5acd4d51c2983a848700fcd73a0e4330b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 2"
                }), true),
            },
            {
                "outpoint": toByteString("5536636bc16608bb0a750ef577a2f2cad8a671e73ec6a1ea1d444d2f94ca3999_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Red Minigun"
                }), true),
            },
            {
                "outpoint": toByteString("020103265ea9868a37221c91066fe04993f8d0f930eeef228623d4e896f6ecbb_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 1"
                }), true),
            },
            {
                "outpoint": toByteString("90b4ca9cd5d2ad5a9ebf276aa03df030b10003492701c2776681b8f589c586d2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 13"
                }), true),
            },
            {
                "outpoint": toByteString("969059be6d725d669d760d37633d59fcac36d12e6546a15b42b5dff26cc2a54d_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 12"
                }), true),
            },
            {
                "outpoint": toByteString("bcc1e8b224b7b81c97a31334e02c5b550a6c5726e31b8ed61d50a4550c0683ff_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Stock"
                }), true),
            },
            {
                "outpoint": toByteString("a5c7bb2290a5a8cc25033e201289f0b147d691f829f1c50ca653dfce2311b1a0_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 10"
                }), true),
            },
            {
                "outpoint": toByteString("c7ff862b15113e9c6bff452a1aa54b047620a9b3d8fed634c9b65c1335f0d55d_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 11"
                }), true),
            },
            {
                "outpoint": toByteString("e0ba9c0877561bf353fb553ec9d64f8f2fc2d81ac4815d3201a5a62f3370ac25_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 15"
                }), true),
            },
            {
                "outpoint": toByteString("ee8da596e27c74231b468d8e199efeaf611c527d711cc219c1ecbfccb075e1d6_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Nothing"
                }), true),
            },
            {
                "outpoint": toByteString("99ba42f61d100207dc07932bc5ef51c3f2d061c94574122a7ceac328be7aa608_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 14"
                }), true),
            },
            {
                "outpoint": toByteString("a5e1fb731c7722193013a686d905a5b4e9d66d9fe97060878a73a27925dfc9a1_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 16"
                }), true),
            },
            {
                "outpoint": toByteString("5a43ee83e686c2e430281d7e72f1698864bcc09d389d5e6e03c4b55645198102_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom Black"
                }), true),
            },
            {
                "outpoint": toByteString("1a06319b8922753fc7f4d8969d3eca5e4f19ce09a051550befa54f987fed5974_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 17"
                }), true),
            },
            {
                "outpoint": toByteString("4f5d64fc68959708042e92f3636be39a70a83046c5ad8a1d0de3e2210292b387_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Pistol"
                }), true),
            },
            {
                "outpoint": toByteString("bd3276a593d302311e934b1fcd87fdd35c7f63105258b590d94f6f1dfdb3c50d_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Green Camo"
                }), true),
            },
            {
                "outpoint": toByteString("ddc49986d08df26bcd3a12eb32087f84f4ace3afd5ac7c53d0e6d4facc1c090b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Golden"
                }), true),
            },
            {
                "outpoint": toByteString("cc64f2ad568ab71301b3de60e3cbee60c5708d66cc9abfb715244eb568a5d88a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Missile Launcher White Camo"
                }), true),
            },
            {
                "outpoint": toByteString("cd99f89f2f4caaa9429887ced17a6ef50fcebbedd34d458ef5e7510f7b5a7b1f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Golden Pistol"
                }), true),
            },
            {
                "outpoint": toByteString("223132f05debfee998b285156a316d77267fbd5875f562863c79af1d092f34c2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "None"
                }), true),
            },
            {
                "outpoint": toByteString("5081b62a76f682547a0a3d7b1fe1dac1aa8c3fe19ce0c79146b682b31d4bd41a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Unarmed"
                }), true),
            },
            {
                "outpoint": toByteString("0812147d60086427f43137ee69a714c453349f0a92f618a8e772e83582e8c698_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Missile Launcher"
                }), true),
            },
            {
                "outpoint": toByteString("adbc162c754df9643a0655dae87cce735e825dd858577b3cc1254d8b41d9d93e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR with Scope"
                }), true),
            },
            {
                "outpoint": toByteString("ff3a66aea243e20ff6ab795ad18dc9e06f0b9b37493bcfa0c557d44692323f83_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 9"
                }), true),
            },
            {
                "outpoint": toByteString("97bed8abcf75ae107f0ba21ae4751b38d425db40a4d68fddb7ca696ba9988395_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "AR Custom 8"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Close Combat", true),
        [
            {
                "outpoint": toByteString("824af3cfc6336244d1fbcfd4b23f5683d0e8722bbcd5dfc3b938c3008c0e22f5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "No Weapon"
                }), true),
            },
            {
                "outpoint": toByteString("80fe85d29cb7c883ff34677483de6f22baf23acbf40fc8f4615662cc63072775_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Lazer Katana"
                }), true),
            },
            {
                "outpoint": toByteString("e35d2e377bd27e1b4285e2d3f3e776cc1d6ffc2d41e7b66d7008a672cddc09a5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Katana"
                }), true),
            },
            {
                "outpoint": toByteString("e948bad5a30889460c09a1503efd92d3bf5dd98dcbd31ffdb2d046f4ba06f2a7_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Sword of the Spirit"
                }), true),
            },
            {
                "outpoint": toByteString("4de78cf05bcd4e06102f9f473142dfb3cb8839ceb5f127b7ebed96681602edf2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Nothing"
                }), true),
            },
            {
                "outpoint": toByteString("15b446b57f019e8884fd48a29c0c21bf7ae7201fa8e7e03dd23a38a1721dc730_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Knife"
                }), true),
            },
            {
                "outpoint": toByteString("ac7df23af16f2cfe8ef34baebcd7ff87a07c01c36cff309321801c4273be7e86_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Golden HODL Knuckleduster"
                }), true),
            },
            {
                "outpoint": toByteString("49a820c2525315c5aacd8f0431166b62d0e699cabc0f9f56e4425cd245b56973_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Knuckleduster"
                }), true),
            }
        ]
    ),
  ]

  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try{
      const key = await wallet.connect();
      setPubKey(key);
      const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth()
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`)
      }
      setConnectedPayAddress(await signer.getDefaultAddress())  // <----
      setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
      await handleGetAddresses();
    } catch(error){
      alert("Something went wrong sigining in. " + error)
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false)
    setFinalTxId(null)
  }

  //TODO: Add a hook to get the contract details from the origin 1 time if theyre not populated 
  //      and save them in initalSupply, etc... 

  const handleDeploy = async () => {
    //URGENT: Set up Yours wallet to receive payment
    const payoutScript = bsv.Script.buildPublicKeyHashOut(paymentAddress)
    console.log("Payout Script:", payoutScript);
    const payout =
      new bsv.Transaction.Output({ script: payoutScript, satoshis: priceInSatoshis })
    console.log("Payout:", payout);
    const payoutHex = payout.toBufferWriter()
      .toBuffer()
      .toString('hex');
    console.log("PayoutHex:", payoutHex);

    //TODO: Derive this from the layers object but make it match the CollectionItem Spec
    //this _subTypeData is arbitrary but searchable. 
    //Include any data that the collection would want to be queried by
    // I should build this autmatically from the layers information. 
    //URGENT: Change the description
    const _subTypeData = {
      description: "Based Frogs",
      attributes: [
        // //Layers
        // {
        //   name: "Accessory",
        //   assets: [
        //     //Layer Items
        //     {
        //       "outpoint": "d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0",
        //       "name": "Venom",
        //       "description": "venom accessory",
        //       "more arbitrary info": "venom accessory"
        //     },
        //     {
        //       "outpoint": "6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0",
        //       "name": "Purple",
        //       "description": "purple accessory",
        //       "more arbitrary info": "purple accessory"
        //     },
        //     {
        //       "outpoint": "0538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0",
        //       "name": "Pink",
        //       "description": "Pink accessory",
        //       "more arbitrary info": "Pink accessory"
        //     }
        //   ]
        // },
        // {
        //   name: "Clothes",
        //   assets: [
        //     {
        //       "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
        //       "name": "Yellow Clothes",
        //       "description": "Sun clothes",
        //       "more arbitrary info": "Yellow Clothes"
        //     },
        //     // ...
        //   ],
        // },
        // {
        //   name: "Eyes",
        //   assets: [
        //     {
        //       "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
        //       "name": "Yellow Eyes",
        //       "description": "Sun colored Eyes",
        //       "more arbitrary info": "This is a cool Eyes."
        //     },
        //     // ...
        //   ],
        // }
      ]
    }

    //URGENT: Set up Yours wallet to receive royalties
    //set up to tardians-live-wallet
    const _royalties = [
      { type: "address", destination: paymentAddress, percentage: "0.03" }
    ]

    //URGENT: Change Collection Name
    const metadata = {
      app: 'Bubblemint',
      type: 'ord',
      contractId: artifact.md5,
      name: "Based Frogs",
      subType: 'collection',
      subTypeData: JSON.stringify(_subTypeData),
      royalties: JSON.stringify(_royalties),
    };

    console.log(JSON.stringify(metadata));
    const map = new bsv.Script('');
    map.add(Buffer.from("SET"));
    for (const [key, value] of Object.entries(metadata)) {
      map.add(Buffer.from(key))
      map.add(Buffer.from(value))
    }

    // Fetch image data with Axios
    const fetchImageData = async (url:any) => {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.data;
      };

    // Isolate fetch operation for testing
    const imageUrl = "https://mint-sites.s3.amazonaws.com/hLEakPQm.jpg";
    const imageData = await fetchImageData(imageUrl);
    console.log("Fetched image data length:", imageData.byteLength);


    // console.log("Content:", )
    const inscriptionScript = ShruggrLib.buildInscriptionScript({
      content: Buffer.from(imageData),
      contentType: 'image/png'
    },
      {
        "1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5": map.toBuffer()
      }
    )

    //URGENT: Change total supply & other factors   
    const deployable = new RecGCFull(
                          toByteString(metadata.name, true), //the ordinals name 
                          RecGCBase.buildLayerNames(layers), //constructed names for the layers
                          RecGCBase.buildLayers(layers),  // the layers object
                          55000n, // number of NFTs to mint into the collection
                          0n, // This is a difficuty number in bits. Set to 0 for no POW. 4 of these difficuly = 1 POW20
                          toByteString(payoutHex), //the address & amount to pay when minting
                          0n, //the current height of the chain to enforce the starting point on locks 
                          0n, // number of blocks to remain locked 
                          0n, // number of satoshis that must be locked
                          BigInt(inscriptionScript.length)) // number of bytes in the inscription

    console.log("Deployable:", deployable);
    await deployable.connect(signer!)

    let deployResponse = await deployable.inscribeWithNoOp(inscriptionScript)
    const rawTx = deployResponse.toBuffer();
    console.log(deployResponse.id, rawTx);
    setTimeout(async () => {
      axios.get(`https://ordinals.gorillapool.io/api/tx/${deployResponse.id}/submit`)
    }, 5000)
    setCurrentOrigin(deployResponse.id + "_0")
  };

  const handleMint = async () => {
    setMessage(null);
    setIsModalOpen(true);
    try {
      setFinalTxId('');
      await wallet.getPaymentUtxos()
      await wallet.getBalance();
      const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
      const response = await axios.get(url);
      console.log("This is the the response to Latest", response.data);
      if (response.data.spend && response.data.spend !== "") {
        //This is the signal that the mint is complete. 
        console.log("spend is not null");
        setMessage("Try refreshing the balance in yours wallet by clicking balance display")
        return;
      }
      console.log("setting current location", response.data.outpoint);
      setCurrentLocation(response.data.outpoint)
      // const inscPrefix = Buffer.from('0063036f726451', 'hex')

      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("Contract Instance initialized...", contractInstance.layers);
      setRemainingSupply(Number(contractInstance.supply));
      localStorage.setItem("remainingSupply", Number(contractInstance.supply).toString());

      let serviceFeeOutput = new bsv.Transaction.Output({
        script: bsv.Script.fromAddress(new bsv.Address("1QD3avnNJbdsdoYEkvSgzuRYL2toqAVUEq")),
        satoshis: 10000
      })

      await contractInstance.connect(signer!)
      const nonce = toByteString('');
      const selectionData = contractInstance.buildSelectionData(layers, nonce);
      console.log({ selectionData });
      const buyerScript = bsv.Script.buildPublicKeyHashOut(connectedOrdiAddress!)
      setMessage("Signing");
      const mintResponse = await contractInstance.methods.mint(
        nonce, // user generated random bytestring. If the difficulty is higher than 0 this needs to be something that when hashed with the previous txid (Little Endian) it creates the output with the specified proof of work. 
        toByteString(buyerScript.toHex()),
        toByteString(''), // public key hash that these satoshis will be locked to
        selectionData,
        toByteString(serviceFeeOutput.toBufferWriter().toBuffer().toString('hex')),
        {
          changeAddress: connectedPayAddress,
          pubKeyOrAddrToSign: connectedPayAddress,
          partiallySign: true
        })

      const rawTx = mintResponse.tx.toBuffer();
      console.log(mintResponse.tx.id, rawTx);
      await setTimeout(async () => {
        setMessage("Broadcasting");
        let injectResponse = await axios.get(`https://ordinals.gorillapool.io/api/tx/${mintResponse.tx.id}/submit`)
        console.log({injectResponse})
        await sleep(3000)
        setFinalTxId(mintResponse.tx.id);
      }, 5000)


    } catch (error) {
      let er = error as Error;
      console.error('Error Minting inscription:', er);
      if(er.toString()==="User dismissed the request!"){
        setMessage(null);
        setIsModalOpen(false);
      } else if(er.toString().includes('mempool')) {
        setMessage("w0mp w0mp. someone beat you too it")
      }else{
        setMessage(er.toString())
      }
      //alert("Something went wrong. Refresh the page and try again. Error message: " + error);
      // Handle the error as needed
    }
  };

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  const handleSignMessage = async () => {
    if (!messageToSign) {
      alert("There was no message to sign!");
      return;
    }
    const signRes = await wallet.signMessage({ message: messageToSign });
    if (signRes) setSignedMessage(signRes);
  };

  return (
    <>

      <div className="App bg-dark relative isolate overflow-hidden text-white">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> <a href="/">← Go Back, Pussy </a></div>
          </div>
          <div className="">
            {!addresses &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {addresses &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <div className="App-header h-full">
        
          <div className="">
            <Header 
              initialRemainingSupply={remainingSupply}
              initialMaxSupply={totalSupply}
              inputPrice={priceInSatoshis}></Header>
          </div>
            
          <div className="max-w-7xl">
            <div className=" rounded-lg">
              
              <div className="flex-1 rounded-lg px-8 bg-dark ">
                <div className="flex-1 md:grid md:grid-cols-5 my-2  rounded-lg p-8">
                  <div className="flex items-center md:col-span-2">
                    <div className="bg-gray-100 rounded-xl ring-yellow-500 ring-2 overflow-hidden">
                      <img src={coverUrl}
                          alt="Collection Cover" 
                          className="mx-auto h-full w-full" 
                           />
                    </div>
                  </div>
                  <div className=" flex-1 flex flex-col md:col-span-1"></div>
                  <div className=" flex-1 flex flex-col md:col-span-2 justify-between flex-grow py-12 px-2 lg:px-4 xl:px-8 ">
                    <section
                      aria-labelledby="summary-heading"
                      className="mt-16 w-full rounded-lg bg-gray-900 px-4 py-6 sm:p-4 lg:col-span-5 lg:mt-0 lg:p-8 ring-gray-500 ring-2"
                    >
                      <h2 id="summary-heading" className="text-lg font-medium">
                      {collectionName}
                      </h2>

                      <dl className="mt-6 space-y-4">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm text-gray-200">Price</dt>
                          <dd className="text-sm font-medium text-gray-200">₿ {priceInSatoshis / 100000000}</dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className=" md:text-xs flex items-center text-sm text-gray-200">
                            <span>Mint Fee</span>
                            {/* <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">What is this?</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a> */}
                          </dt>
                          <dd className="text-sm font-medium text-gray-200">{serviceFeeInSatoshis} <span className="text-xs">sats</span></dd>
                        </div>
                        {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                          <dt className="flex text-sm text-gray-600">
                            <span>Tax estimate</span>
                            <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Learn more about how tax is calculated</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                          </dt>
                          <dd className="text-sm font-medium text-gray-900">$8.32</dd>
                        </div> */}
                        {/* <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className="text-base font-medium text-gray-200">Order total</dt>
                          <dd className="text-base font-medium text-gray-200">₿ {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}</dd>
                        </div> */}
                      </dl>

                      <div className="mt-6">
                      {!addresses &&
                        <PandaConnectButton onClick={handleConnect} />
                      }
                      {addresses &&
                        <>
                          {/* <Button onClick={handleDeploy} variant="secondarySquare" className="w-full font-medium" style={{ color: "black" }}>
                              Deploy 
                          </Button> */}
                          <Button onClick={handleMint} variant="gradientSquare" className="w-full font-medium" style={{ color: "black" }}>
                              Mint For {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}
                          </Button>
                        </>
                      }
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GalleryGrid 
            parentID={currentOrigin} 
            address={connectedOrdiAddress? connectedOrdiAddress.toString() : "" } 
            collectionName={collectionName}/>
      </div>
      
      <Footer></Footer>
      <Modal
        isOpen={isModalOpen}
        onClose={() => handleModalClose()}
        lastTxid={finalTxId!}
        inputMessage={message? message : 'Minting'}
      />
    </>
  );
};
