import React, { useState, useEffect } from 'react';
import { Button } from '../styled/PrimaryButton';
import PendingTransactionsCheck from './PendingTransactionsCheck'

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  lastTxid: string;
  inputMessage?: string;
}

interface Attribute {
  name: string;
  attributeName: string;
  attributeDescription: string;
}

interface DataSet {
  name: string;
  attributes: Attribute[];
}


const imageSources = [
  'https://mint-sites.s3.amazonaws.com/foxes/1.png',
  'https://mint-sites.s3.amazonaws.com/foxes/2.png',
  'https://mint-sites.s3.amazonaws.com/foxes/3.png',
  'https://mint-sites.s3.amazonaws.com/foxes/4.png'
  // Add more images as needed
];

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, lastTxid, inputMessage }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [txid, setTxid] = useState(lastTxid);
  const [returnedData, setReturnedData] = useState<DataSet | null>(null);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [message, setMessage] = useState<string>(inputMessage || "Minting")
  useEffect(() => {
    console.log("Setting Modal Input Message:", inputMessage!)
    setMessage(inputMessage!)

  }, [inputMessage])
  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(0); // Reset the index when the modal opens

      const timer = setInterval(() => {
        slideNext();
      }, 200);

      return () => clearInterval(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    setTxid(lastTxid);
    const fetchOrdinalData = async (txid: string) => {
      const response = await fetch(`https://ordinals.gorillapool.io/api/txos/${txid}?script=false`);
      const data = await response.json();
      console.log(data.data);
      const name = data.data.map.name;
      const attributes = data.data.map.subTypeData.attributes.map((attr: { name: any; details: { name: any; description: any; }; }) => ({
        name: attr.name,
        attributeName: attr.details.name,
        attributeDescription: attr.details.description,
      }));
    
      return {
        name,
        attributes,
      };
    };
    if(lastTxid !== ''){
      fetchOrdinalData(lastTxid + "_2")
      .then(result => {
        console.log(result);
        setReturnedData(result)
      })
      .catch(error => {
        console.error('Error fetching ordinal data:', error);
      });
    }
  }, [lastTxid]);

  const slideNext = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      if (nextIndex > 0) {
        setIsFirst(false);
      }
      return nextIndex % imageSources.length;
    });
  };

  const handleViewClick = () => {
    window.open('https://alpha.1satordinals.com/outpoint/' + lastTxid + "_2/inscription", '_blank', 'noopener,noreferrer');
  };

  const handleViewLarge = () => {
    window.open('https://ordfs.network/content/' + lastTxid + "_2", '_blank', 'noopener,noreferrer');
  };

  const handleClose = () => {
    setReturnedData(null)
    onClose();
  }

  return isOpen ? (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-900 p-5 z-50 w-4/5 max-w-2xl rounded-lg shadow-lg flex flex-col">
      <div className="flex flex-1 lg:flex">
        <div className="relative overflow-hidden w-1/2 h-72 flex items-center justify-center">
          {(txid === "" || txid === null) && imageSources.map((src, index) => (
            <img
              key={index}
              src={src}
              className={`absolute w-full h-full ${isFirst === true ? '' : 'rounded-xl '}  ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
              alt={`Slide ${index + 1}`}
            />
          ))}
          {txid !== "" && txid !== null &&
            <div className="mt-2 w-full" style={{ height:"300px", width:"300px"}}><img src={"https://mint-sites.s3.amazonaws.com/foxes/yellow-fox.png"} style={{ width: '300px', height:"300px"}} className={'opacity-100 rounded-xl'} /></div>
          }
        </div>
        <div className="text-white w-1/2 pl-4">
            {returnedData !== null && 
              <div className="p-4 shadow-lg rounded-lg">
              <h2 className="text-2xl font-bold mb-4">{returnedData.name!}</h2>
              <div className="p-4 shadow-lg rounded-lg max-h-48 overflow-y-scroll bg-gray-800">
                {returnedData.attributes.map((attr, index) => (
                  <div key={index} className="mb-2">
                    <div className="flex-1 lg:flex"> 
                      <div className="font-semibold lg:w-full">{attr.name}:</div>
                      <div className="w-full lg:text-right">{attr.attributeName}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            }
            {txid !== '' && 
                <div className="flex-1 justify-between pt-4">
                    {/* <div><Button className='m-2 w-full text-black' variant="oneSatSquare" onClick={handleViewClick}>View On 1Sat</Button></div>
                    <div><Button className='m-2 w-full text-black rounded-lg text-white' variant="secondary" onClick={handleViewLarge}>View Large</Button></div> */}
                    <div><Button className='m-2 w-full text-black' variant="gradientSquare" onClick={handleClose}>Mint Again</Button></div>
                </div>
            }
            {txid === '' && (message === 'Minting' || message === 'Signing' || message === 'Broadcasting') &&
                
                <div className='w-full h-full flex flex-col items-center justify-center'>
                  <div className="border-t-transparent border-solid animate-spin rounded-full border-blue-500 border-4 h-8 w-8"></div>
                  <div className="mt-2">{message}...</div>
                </div>
            }
            {txid === '' && message !== "" && message !== 'Minting' && message !== 'Signing' && message !== 'Broadcasting' && 
                <div className='w-full h-full flex flex-col items-center justify-center'>
                 <div> {message}</div>
                <div><Button className='m-2 w-full text-red-500' variant="destructive" onClick={handleClose}>Close</Button></div>
              </div>
            }
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
