import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from './styled/PrimaryButton';
import { Link } from 'react-router-dom';
import IframeContent from './HeaderCircleIframe';

type GalleryGridProps = {
  parentID: string;
  address: string;
  collectionName: string;
  totalItems?: number; // Add totalItems to props
};

type ApiResponseItem = {
  origin: {
    outpoint: string;
    data: {
      data:{},
      map: {
        subTypeData: {
          rank: number;
        };
        name: string;
      };
    };
  };
  data: {
    map: {
      subTypeData: {
        rank: number;
      };
    };
  };
};

type ApiResponse = ApiResponseItem[];

const GalleryGrid: React.FC<GalleryGridProps> = ({ parentID, address, collectionName, totalItems }) => {
  const [items, setItems] = useState<ApiResponseItem[]>([]);
  const [holdersCount, setHoldersCount] = useState<number>(0);
  const [myItemsCount, setMyItemsCount] = useState<number>(0);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        let response = await axios.post<ApiResponse>(
          `https://ordinals.gorillapool.io/api/txos/address/${address}/unspent`,
          {
            insc: {
              parent: parentID,
            },
          }
        );
        console.log("Fetching Collection", response.data);

        if (response.data.length < 1) {
          console.log("fetching 2");
          response = await axios.post<ApiResponse>(
            `https://ordinals.gorillapool.io/api/txos/address/${address}/unspent`,
            {
              map: {
                subTypeData: {
                  collectionId: `${parentID}`,
                },
              },
            }
          );
        }
        console.log("Length after fetch ", response.data);
        console.log(response.data[1].origin? response.data[1].origin.data.map.subTypeData.rank : "No origin")
        setItems(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error('Error fetching items:', error.message);
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    fetchItems();
  }, [parentID, address]);

  useEffect(() => {
    const fetchHolders = async (address: string) => {
      let response = await axios.get(`https://ordinals.gorillapool.io/api/collections/${parentID}/holders`);
      setHoldersCount(response.data.length);
      let returnValue = response.data.find((item: { address: string }) => item.address === address);
      console.log({ returnValue });
      setMyItemsCount(returnValue?.amt ? returnValue.amt : 0);
    };
    fetchHolders(address);
  }, [address]);

  const getRarity = (rank: number, totalItems: number): string => {
    const percentile = (rank / totalItems) * 100;
    if (percentile <= 1) return 'Exotic';
    if (percentile <= 4) return 'Legendary';
    if (percentile <= 10) return 'Epic';
    if (percentile <= 25) return 'Rare';
    if (percentile <= 50) return 'Uncommon';
    return 'Common';
  };

  const getRarityStyle = (rarity: string): string => {
    switch (rarity) {
      case 'Exotic':
        return 'bg-gradient-to-r from-purple-400 via-blue-300 to-blue-500 text-white';
      case 'Legendary':
        return 'bg-gradient-to-r from-yellow-400 to-yellow-600 text-white';
      case 'Epic':
        return 'bg-gradient-to-r from-red-400 to-red-600 text-white';
      case 'Rare':
        return 'bg-gradient-to-r from-green-400 to-green-600 text-white';
      case 'Uncommon':
        return 'bg-gradient-to-r from-blue-400 to-blue-600 text-white';
      case 'Common':
        return 'bg-gradient-to-r from-gray-400 to-gray-600 text-white';
      default:
        return 'bg-gray-400 text-white';
    }
  };

  const getRarityRing = (rarity: string): string => {
    switch (rarity) {
      case 'Exotic':
        return 'ring-1 ring-purple-500';
      case 'Legendary':
        return 'ring-1 ring-yellow-500 text-white';
      case 'Epic':
        return 'ring-1 ring-red-500 text-white';
      case 'Rare':
        return 'ring-1 ring-green-500 text-white';
      case 'Uncommon':
        return 'ring-1 ring-blue-500 text-white';
      case 'Common':
        return 'ring-1 ring-gray-500 text-white';
      default:
        return 'bg-gray-400 text-white';
    }
  };

  return (
    <>
      <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-12">
        <div className="flex items-start space-x-5 px-6">
          <div className="flex-shrink-0">
            <div className="relative">
              <IframeContent id={parentID} />
              <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
            </div>
          </div>
          <div className="pt-1.5">
            <h1 className="text-2xl font-bold text-left">My {collectionName}: {myItemsCount}</h1>
            <div className="w-full pt-2 wordwrap-none text-left"></div>
            <p className="text-sm font-medium text-gray-300 text-left">
              Total Holders{' '}
              <a href="#" className="text-gray-900">
              </a>{holdersCount}
            </p>
          </div>
        </div>
        <div className="xs:flex-1 md:flex lg:pr-6 mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <Link to={`/listings/${parentID}`}>
            <Button
              variant="secondarySquare"
              className="xs:m-4 inline-flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50 md:m-0"
            >
              Market
            </Button>
          </Link>
        </div>
      </div>
      {items.length > 0 &&
        <>
          <div className="grid grid-cols-3 xl:grid-cols-4 gap-4 mx-4 px-4">
            {items.map((item, index) => (
              <a href={`https://ordfs.network/content/${item.origin.outpoint}`} target="_blank" rel="noopener noreferrer" className="pt-4 mt-4">
              <div key={index} className={`rounded-xl overflow-hidden bg-gray-900 ring-1 m-2 ${getRarityRing(getRarity(item?.origin?.data?.map?.subTypeData?.rank, totalItems? totalItems : 1))}`}>
                <div key={index} className="w-full rounded-xl relative" style={{ paddingTop: '100%', height:'100%' }}>
                  <iframe
                    src={`https://ordfs.network/content/${item.origin.outpoint}`}
                    title={`Content ${index}`}
                    className="absolute top-0 left-0 w-full h-full"
                    frameBorder="0"
                  />
                </div>
                <div className="p-2 w-full">
                  {item?.origin?.data?.map?.subTypeData?.rank && (
                    <>
                    <div className="p-2 w-full text-gray-100"> {item?.origin?.data?.map?.name} </div>
                    <div className='md:flex w-full pt-2'>
                      <div className="text-white text-center py-1 w-full">Rank: {item?.origin?.data?.map?.subTypeData?.rank}</div>
                      <div className="text-white text-center w-full">
                        <p className={`xs:text-xs sm: text-sm lg:text-md text-center mb-2 py-1 px-6 rounded-full ${getRarityStyle(getRarity(item?.origin?.data?.map?.subTypeData?.rank, totalItems? totalItems : 1))}`}>
                          {getRarity(item?.origin?.data?.map?.subTypeData?.rank, totalItems ? totalItems : 1)}
                        </p>
                      </div>
                    </div>
                    </>
                  )}
                </div>
              </div>
              </a>
            ))}
          </div>
        </>
      }
    </>
  );
};

export default GalleryGrid;
