import { Button } from "../styled/PrimaryButton";
import { useEffect, useState } from "react";
type Status = 'Open' | 'Completed' | 'Soon';
type ActivityItem = {
  name: string;
  description: string;
  milestone: string;
  status: Status;
  percentComplete: String;
};

const activityItems: ActivityItem[] = [
  { 
    name: 'sCrypt Mint Contracts',
    description: "sCrypt Smart Contract for minting generative recursive parent child inscriptions",
    milestone: "1",
    status: 'Completed',
    percentComplete: "100%"
  },
  { 
    name: 'Lock To Mint Collections Launch',
    description: "At this milestone we will launch a Lock To Mint Collection from an sCrypt Smart Contract. The bitcoin returns to you after a lockup period",
    milestone: "10000",
    status: 'Open',
    percentComplete: "0%"
  },
  { 
    name: 'Add POW Protection to Contracts',
    description: "At this milestone we will enable sCrypt Smart Contract with added proof-of-work to protect the mint",
    milestone: "25000",
    status: 'Soon',
    percentComplete: "0%"
  },
  { 
    name: 'User Generated Collections',
    description: "At this milestone we will release a page to add all your layer details and metadata, press publish & you've got an unstoppable contract to mint your own collection.",
    milestone: "50000",
    status: 'Soon',
    percentComplete: "0%"
  },
  { 
    name: 'Mint Your Own LTM & POW Collections',
    description: "At this milestone we will enable the user generated mints to select which type of mint they would like to launch: Pay To Mint, LTM, Pay To Mint + POW & LTM + POW",
    milestone: "75000",
    status: 'Soon',
    percentComplete: "0%"
  },
  { 
    name: 'Open Source This F*cker',
    description: "First, we party. Then we open-source the whole f*cking thing: The suite of contracts & the minting pages.",
    milestone: "100000",
    status: 'Soon',
    percentComplete: "0%"
  },
  
]

const statuses: Record<Status, string> = {
  Completed: 'text-purple-400 bg-purple-400/10',
  Open: 'text-green-400 bg-green-400/10',
  Soon: 'text-yellow-400 bg-yellow-400/10'
};


function classNames(...classes: (string | undefined | null | false)[]) {
  return classes.filter(Boolean).join(' ');
}

interface CountResponse {
  count:number; 
  max: number;
}
export default function FeaturesFunds() {
  const [collectionCount, setCollectionCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCount = async () => {
        try {
            const response = await fetch('https://ordinals.gorillapool.io/api/collections/cc274a2cc28d88f24a7442443b5542fefe95e486f81f4261e0649401eec30c5d_0/stats');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data: CountResponse = await response.json();
            setCollectionCount(data.count);
            setIsLoading(false);
        } catch (e) {
            setError(e instanceof Error ? e.message : 'An unknown error occurred');
            setIsLoading(false);
        }
    };
  
    fetchCount();
    }, []); // Empty dependency array means this effect will only run once after the initial render
  
  return (
    <div className="bg-gray-900 py-10 overflow-x-scroll">
      <div className="flex"> 
      <div className="flex w-full "> 
      <h3 className="w-full text-lg md:text-xl px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">Feature Release</h3>
        </div>
        <div className="flex pr-12"> 
          <a href="/mint/0face">
            <Button variant="gradientSquare"> Minting Now</Button>
          </a>
        </div>
      </div>
      
      <p className="px-4 pt-4 text-base leading-7 text-white sm:px-6 lg:px-8">
        Checkout the features list below. Everytime the total number of 0Faces minted hits a milestone, a new feature will be released. Want a feature soon? Smash that mint button.
      </p>

      <table className="mt-6 w-full text-left">
        <colgroup>
          <col className="sm:w-4/12" />
          <col className="lg:w-4/12" />
          <col className="lg:w-2/12" />
          <col className="lg:w-1/12" />
          <col className="lg:w-1/12" />
        </colgroup>
        <thead className="border-b border-white/10 text-sm leading-6 text-white">
          <tr>
            <th scope="col" className="py-2 pl-4 font-semibold sm:pl-6 lg:pl-8">
              Name
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell whitespace-nowrap">
              What is it?
            </th>
            <th scope="col" className="py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8">
              Status
            </th>
            <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
              Milestone
            </th>
            <th scope="col" className="py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20 whitespace-nowrap">
              % Complete
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-white/5">
          {activityItems.map((item) => (
            <tr key={item.name}>
              <td className="py-4 pl-4 sm:table-cell sm:pl-6 lg:pl-8">
                <div className="flex items-center gap-x-4">
                    {/* <img src={item.user.imageUrl} alt="" className="h-8 w-8 rounded-full bg-gray-800" /> */}
                  <div className="truncate text-sm font-medium leading-6 text-white">{item.name}</div>
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">{item.description}</div>
                  {/* <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                    {item.recieved}
                  </div> */}
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-2 lg:pr-20">
                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                  {/* <time className="text-gray-400 sm:hidden" dateTime={item.dateTime}>
                    {item.date}
                  </time> */}
                  <div className={classNames(statuses[item.status], 'flex-none rounded-full p-1')}>
                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                  </div>
                  <div className="text-white sm:block">{item.status}</div>
                </div>
              </td>
              <td className=" py-4 pl-0 pr-8 text-sm text-gray-400 sm:table-cell lg:pr-20">
                {item.milestone}
              </td>
              <td className="py-4 pl-0 pr-4 text-right text-sm text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                {collectionCount > 0 && collectionCount <= Number(item.milestone) &&
                  (collectionCount / (Number(item.milestone)) * 100).toPrecision(2)
                }
                {collectionCount > 0 && collectionCount >= Number(item.milestone) &&
                  "100"
                } %
              </td>
              {/* <td className="py-4 pl-0 pr-4 text-right text-sm  text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                {item.milestone}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
