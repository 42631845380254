import {
    assert,
    bsv,
    ByteString,
    ContractTransaction,
    FixedArray,
    hash256,
    int2ByteString,
    len,
    method,
    MethodCallOptions,
    prop,
    toByteString,
} from 'scrypt-ts'
import { RecGCBase } from './rec-gc-base'
import { ShruggrLib } from './shruggr-lib'

export class RecGCFull extends RecGCBase {
    @prop()
    public difficultyBits: bigint

    @prop()
    public payOut: ByteString

    @prop()
    public lockDuration: bigint

    @prop()
    public lockSatoshis: bigint

    @prop(true)
    public lastHeight: bigint

    constructor(
        collectionName: ByteString,
        layerNames: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        layers: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        supply: bigint,
        difficultyBits: bigint,
        payOut: ByteString,
        lockCurrentHeight: bigint,
        lockDuration: bigint,
        lockSatoshis: bigint,
        inscBytes: bigint
    ) {
        super(collectionName, layerNames, layers, supply, inscBytes)
        this.init(...arguments)
        this.difficultyBits = difficultyBits
        this.payOut = payOut
        this.lockDuration = lockDuration
        this.lockSatoshis = lockSatoshis
        this.lastHeight = lockCurrentHeight
    }

    @method()
    public mint(
        nonce: ByteString,
        buyerScript: ByteString,
        lockPkh: ByteString,
        selectionData: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        trailingOuts: ByteString
    ) {
        let contractOutputs = toByteString('')

        if (len(this.payOut) > 0) {
            contractOutputs += this.payOut
        }

        if (this.lockDuration > 0) {
            assert(
                this.ctx.locktime >= this.lastHeight,
                `nLocktime cannot be in the past ${this.lastHeight} ${this.ctx.locktime}}`
            )
            assert(
                this.ctx.locktime + this.lockDuration < 9437183,
                `lock until height must be less than 9437183, ${this.ctx.locktime} ${this.lockDuration}}`
            )
            assert(
                this.ctx.sequence < 0xffffffff,
                `must use sequence < 0xffffffff`
            )

            contractOutputs += ShruggrLib.buildLockupOutput(
                lockPkh,
                this.lockSatoshis,
                this.ctx.locktime + this.lockDuration
            )
        }

        const seed = ShruggrLib.buildPoW(this.ctx.utxo.outpoint.txid, nonce)

        if (this.difficultyBits > 0) {
            assert(
                ShruggrLib.validatePoW(seed, this.difficultyBits),
                'invalid PoW'
            )
        }

        const outputs =
            this.buildOutputs(
                seed,
                buyerScript,
                contractOutputs,
                selectionData,
                trailingOuts
            ) + this.buildChangeOutput()

        assert(
            hash256(outputs) === this.ctx.hashOutputs,
            'invalid outputs hash'
        )
    }

    static async buildTxForMint(
        current: RecGCFull,
        options: MethodCallOptions<RecGCFull>,
        nonce: ByteString,
        buyerScript: ByteString,
        lockPkh: ByteString,
        selectionData: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        trailingOuts: ByteString
    ): Promise<ContractTransaction> {
        const next = current.next()
        next.inscBytes = 0n
        next.supply--
        const txidLE = toByteString(
            Buffer.from(current.utxo.txId, 'hex').reverse().toString('hex')
        )
        if (current.origin === toByteString('')) {
            next.origin =
                toByteString(txidLE) +
                int2ByteString(BigInt(current.utxo.outputIndex))
            next.collectionId = toByteString(
                `${current.utxo.txId}_${current.utxo.outputIndex}`,
                true
            )
        }

        const unsignedTx = new bsv.Transaction()
            // add contract input
            .addInput(current.buildContractInput())

        // build next instance output
        if (next.supply > 0n) {
            unsignedTx.addOutput(
                new bsv.Transaction.Output({
                    script: next.lockingScript,
                    satoshis: Number(1),
                })
            )
        }

        if (len(current.payOut) > 0n) {
            const br = new bsv.encoding.BufferReader(
                Buffer.from(current.payOut, 'hex')
            )
            unsignedTx.addOutput(bsv.Transaction.Output.fromBufferReader(br))
        }

        if (current.lockDuration > 0n) {
            unsignedTx.nLockTime = options.lockTime!
            unsignedTx.inputs[0].sequenceNumber = 0
            const br = new bsv.encoding.BufferReader(
                Buffer.from(
                    ShruggrLib.buildLockupOutput(
                        lockPkh,
                        current.lockSatoshis,
                        BigInt(options.lockTime!) + current.lockDuration
                    ),
                    'hex'
                )
            )
            unsignedTx.addOutput(bsv.Transaction.Output.fromBufferReader(br))
        }

        // build inscription output
        const seed = ShruggrLib.buildPoW(txidLE, nonce)
        const selections = RecGCBase.randomize(seed, current.layers)
        const br = new bsv.encoding.BufferReader(
            Buffer.from(
                next.buildInscriptionOutput(
                    next.buildName(),
                    selections,
                    next.origin,
                    next.collectionId,
                    buyerScript,
                    current.layerNames,
                    current.layers,
                    selectionData
                ),
                'hex'
            )
        )
        unsignedTx.addOutput(bsv.Transaction.Output.fromBufferReader(br))

        if (len(trailingOuts) > 0) {
            const br = new bsv.encoding.BufferReader(
                Buffer.from(trailingOuts, 'hex')
            )
            while (br.remaining() > 0) {
                const output = bsv.Transaction.Output.fromBufferReader(br)
                unsignedTx.addOutput(output)
            }
        }

        if (options.changeAddress) {
            // build change output
            unsignedTx.change(options.changeAddress)
        }

        console.log('unsignedTx:', unsignedTx.toString())

        return {
            tx: unsignedTx,
            atInputIndex: 0,
            nexts: [],
        }
    }
}
