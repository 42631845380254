import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { PandaConnectButton } from "../../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { RecGCFull } from "../../contracts/rec-gc-full";
import artifact from '../../../artifacts/rec-gc-full.json';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from './../../components/styled/PrimaryButton';
import Header from "../../components/styled/Header";
import Footer from "../../components/styled/Footer";
import Modal from "../../components/mint/WaitingModal";
import GalleryGrid from "../../components/FirebaseGalleryGrid";
import PendingTransactionsCheck from './../../components/mint/PendingTransactionsCheck';
import ProgressBar from "../../components/styled/ProgressBar";

RecGCFull.loadArtifact(artifact);

interface TransactionData {
  amount: number;
  description: string;
  txid: string;
  processed:boolean;
  ordAddress: string;
  sendToOrdibot: boolean;
  // Add more fields as necessary.
}

interface TotalMintedResponse {
  totalMinted: number;
}

// Define the async function
async function checkPaidBalance(): Promise<number | null> {
  console.log('Checking Balance');
  try {
    const balanceResponse = await axios.get("https://api.whatsonchain.com/v1/bsv/main/address/13mehhstmS1HR4Z5kktqs3SUgvr2nFg8nD/balance");
    console.log(balanceResponse); // This will log the axios response
    // Assuming you want to return some actual value from the response:
    return balanceResponse.data.confirmed + balanceResponse.data.unconfirmed; // Modify according to actual response structure
  } catch (error) {
    console.error("Error fetching balance:", error);
    return null; // Return null in case of an error
  }
}

export const PixelFoxes = () => {
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [initialSupply, setInitialSupply] = useState(10000000)
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [currentOrigin, setCurrentOrigin] = useState<string>('1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0');
  const [currentLocation, setCurrentLocation] = useState<string>('');
  const [priceInSatoshis, setPriceInSatoshis] = useState<string>("Free")
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(10000)
  const [totalSatsToSpend, setTotalSatsToSpend] = useState<number>(10000);
  const [totalSupply, setTotalSupply] = useState<number>(0)
  const [collectionName, setCollectionName] = useState<string>('Pixel Foxes')
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [finalTxId, setFinalTxId] = useState<string | null>(''); // State to store the mint response
  const [message, setMessage] = useState<string | null>(null)
  const [mintCount, setMintCount] = useState<number>(1)
  const [totalSpentToPayAddress, setTotalSpentToPayAddress] = useState<number>(0)
  const [totalMinted, setTotalMinted] = useState<number | null>(0)
  const [isMintComplete, setIsMintComplete] = useState<boolean>(false);
  const [sendToOrdibot, setSentToOrdibot] = useState<boolean>(false)
  const [useCustomAddress, setUseCustomAddress] = useState<boolean>(false);
  const [customAddress, setCustomAddress] = useState<string>("");
  const [signedMessage, setSignedMessage] = useState<
    SignedMessage | undefined
  >();



  const paymentRecieverAddress = "13mehhstmS1HR4Z5kktqs3SUgvr2nFg8nD"; 
  const coverUrl = "https://mint-sites.s3.amazonaws.com/foxes/yellow-fox.png";

  useEffect(() => {
    
    if (!wallet.connect) {
      return;
    }
  
    const connectWallet = async () => {
      try {
        const key = await wallet.connect();
        setPubKey(key);
  
        const signer = new PandaSigner(new OrdiProvider()); // <---- use `PandaSigner`
        setSigner(signer);
  
        const { isAuthenticated, error } = await signer.requestAuth();
        if (!isAuthenticated) {
          throw new Error(`Unauthenticated: ${error}`);
        }
  
        setConnectedPayAddress(await signer.getDefaultAddress());  // <----
        setConnectedOrdiAddress(await signer.getOrdAddress());     // <----
        await handleGetAddresses();
      } catch (error) {
        alert("Something went wrong signing in. " + error);
      }
    };
  
    connectWallet();
  
}, [wallet]);
  
useEffect(() => {
  const fetchTotalMinted = async () => {
      const minted = await getTotalMintedOrdinals();
      if(minted !== null){
        setTotalMinted(minted);
        setRemainingSupply(10000000 - minted)
      }
  };

  fetchTotalMinted();
}, []);
useEffect(() => {
  async function fetchBalance() {
    let balanceResponse = await checkPaidBalance();
    console.log(balanceResponse); // This will log the balance or null
    setTotalSpentToPayAddress(balanceResponse!)
    if((balanceResponse! /100000000) > 998){
      setIsMintComplete(true)
    }
  }
  
  fetchBalance(); // Call the async function within useEffect
}, [finalTxId]);

  const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));

  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try{
      const key = await wallet.connect();
      setPubKey(key);
      const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth()
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`)
      }
      setConnectedPayAddress(await signer.getDefaultAddress())  // <----
      setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
      await handleGetAddresses();
    } catch(error){
      alert("Something went wrong sigining in. " + error)
    }
  };

  
  // Example using fetch to call your Firebase function
// Function to send data to the Firebase function
const sendDataToFunction = async (data: TransactionData): Promise<void> => {
  try {
    const response = await fetch('https://addtransaction-kq55e66zjq-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log('Response from Firebase Function:', result);
  } catch (error) {
    console.error('Error calling Firebase Function:', error);
  }
};


  const handleModalClose = () => {
    setIsModalOpen(false)
    setFinalTxId(null)
  }
  const handleMintCountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let count = Number(event.target.value);
    if(count > 10000){count = 10000}
    if(count < 1){count = 1}
    setMintCount(count);
    setTotalSatsToSpend(count * serviceFeeInSatoshis)
  };

  const handleMint = async () => {
      // If using a custom address, validate it
    if (useCustomAddress) {
      if (!customAddress || !isValidCustomAddress(customAddress)) {
        alert("Invalid Bitcoin address.");
        setIsModalOpen(false);
        return;
      }
    }
    setMessage(null);
    setIsModalOpen(true);
    const paymentParams = [{
        satoshis: totalSatsToSpend,
        address: paymentRecieverAddress,
    }];
  
    try {
      const transactionId = await wallet.sendBsv(paymentParams);
      setFinalTxId(transactionId?.txid ? transactionId.txid : "");
  
      // Determine which address to save
      const addressToSave = useCustomAddress && customAddress
        ? customAddress
        : connectedOrdiAddress!.toString();
  
      sendDataToFunction({
        amount: mintCount,
        description: 'Sample transaction',
        txid: transactionId!.txid,
        processed: false,
        ordAddress: addressToSave,
        sendToOrdibot: sendToOrdibot,
      });
  
      await wallet.getPaymentUtxos();
      await wallet.getBalance();
  
    } catch (error) {
      let er = error as Error;
      console.error('Error Paying:', er);
      setMessage(er.toString());     
    }
  };
  

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  const handleSignMessage = async () => {
    if (!messageToSign) {
      alert("There was no message to sign!");
      return;
    }
    const signRes = await wallet.signMessage({ message: messageToSign });
    if (signRes) setSignedMessage(signRes);
  };

  const handleSendToOrdiBot = async (shouldSend: boolean) => {
    console.log("Send To Ordibot")
    console.log(shouldSend)
    setSentToOrdibot(shouldSend)
  }

  async function getTotalMintedOrdinals(): Promise<number | null> {
    try {
        const response = await fetch('https://gettotalmintedordinals-kq55e66zjq-uc.a.run.app');
        if (!response.ok) { // Check if the response status code is not OK (200-299)
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: TotalMintedResponse = await response.json();
        console.log('Total minted ordinals:', data.totalMinted);
        return data.totalMinted;
    } catch (error) {
        console.error('Error fetching total minted ordinals:', error);
        return null; // You may want to handle the error differently depending on your application needs
    }
  }
  async function checkPaidBalance(): Promise<number | null>{
    console.log('Checking Balance')
    const balanceResponse = await axios.get("https://api.whatsonchain.com/v1/bsv/main/address/13mehhstmS1HR4Z5kktqs3SUgvr2nFg8nD/balance");
    console.log(balanceResponse)
    return balanceResponse.data.confirmed + balanceResponse.data.unconfirmed;
  }
  const isValidCustomAddress = (address: string): boolean => {
    const addressLength = address.length;
    const startsWith1 = address.startsWith('1');
    return startsWith1 && addressLength >= 30 && addressLength <= 40;
  };
  return (
    <>

      <div className="App bg-dark relative isolate overflow-hidden text-white">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> <a href="/">← Go Back, Pussy </a></div>
          </div>
          <div className="">
            {!addresses &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {addresses &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <div className="App-header h-full">
        
          <div className="">
            <Header 
              initialRemainingSupply={remainingSupply}
              initialMaxSupply={initialSupply}
              inputPrice={-1}></Header>
          </div>
          
          <div className="max-w-7xl">
            
            <div className=" rounded-lg">
              
              <div className="flex-1 rounded-lg px-8 bg-dark ">
              
                <div className="flex-1 md:grid md:grid-cols-5 my-2  rounded-lg p-8">
                  <div className="flex items-center md:col-span-2">
                    <div className="bg-gray-100 rounded-xl ring-yellow-500 ring-2 overflow-hidden">
                      <img src={coverUrl}
                          alt="Collection Cover" 
                          className="mx-auto h-full w-full" 
                           />
                    </div>
                  </div>
                  <div className=" flex-1 flex flex-col md:col-span-1"></div>
                  <div className=" flex-1 flex flex-col md:col-span-2 justify-between flex-grow py-12 px-2 lg:px-4 xl:px-8 ">
                    <section
                      aria-labelledby="summary-heading"
                      className="mt-16 w-full rounded-lg bg-gray-900 px-4 py-6 sm:p-4 lg:col-span-5 lg:mt-0 lg:p-8 ring-gray-500 ring-2"
                    >
                      <h2 id="summary-heading" className="text-lg font-medium">
                      {collectionName}
                      </h2>

                      <dl className="mt-6 space-y-4">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm text-gray-200">Price</dt>
                          <dd className="text-sm font-medium text-gray-200">₿ Free</dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className=" md:text-xs flex items-center text-sm text-gray-200">
                            <span>Mint Fee</span>
                            {/* <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">What is this?</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a> */}
                          </dt>
                          <dd className="text-sm font-medium text-gray-200">{serviceFeeInSatoshis} <span className="text-xs">sats</span></dd>
                        </div>
                        {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                          <dt className="flex text-sm text-gray-600">
                            <span>Tax estimate</span>
                            <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Learn more about how tax is calculated</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                          </dt>
                          <dd className="text-sm font-medium text-gray-900">$8.32</dd>
                        </div> */}
                        {/* {<div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <div className="w-full"><ProgressBar progress={(1 - (remainingSupply / initialSupply)) * 100}/></div>
                        </div>} */}
                      </dl>

                      <div className="mt-6">
                      {!addresses &&
                        <PandaConnectButton onClick={handleConnect} />
                      }
                      {addresses &&
                        <>
                          {/* <Button onClick={handleDeploy} variant="secondarySquare" className="w-full font-medium" style={{ color: "black" }}>
                              Deploy 
                          </Button> */}
                          <div className="flex space-x-4"> 
                            <div className="w-full text-left"> 
                              Mint Count:
                            </div>
                            <div className=""> 
                              <input type="number" 
                                      onChange={handleMintCountChange} 
                                      className="w-full rounded-lg ring-2 ring-gray-800 bg-gray-900 text-gray-100 text-right mr-2"
                                      value={mintCount}/>
                            </div>
                          </div>
                          <div className="pt-2">
                            <div> 
                              {!isMintComplete && 
                                <div> 
                                  <div className="flex items-center">
                                    <div className="w-full text-left py-2">
                                      <span className="text-sm text-left">
                                        Mint to 1Sat Discord OrdiBot
                                      </span>
                                    </div>
                                    <div className="py-1">
                                      <label className="switch">
                                        <input 
                                          type="checkbox" 
                                          checked={sendToOrdibot} 
                                          onChange={() => handleSendToOrdiBot(!sendToOrdibot)} 
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="flex items-center">
                                    <div className="w-full text-left py-2">
                                      <span className="text-sm text-left">
                                        Mint To Address
                                      </span>
                                    </div>
                                    <div className="py-1">
                                      <label className="switch">
                                        <input 
                                          type="checkbox" 
                                          checked={useCustomAddress} 
                                          onChange={() => setUseCustomAddress(!useCustomAddress)} 
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  </div>

                                  {useCustomAddress && (
                                    <div className="flex items-center">
                                      <div className="w-full text-left py-2">
                                        <input
                                          type="text"
                                          className="w-full rounded-lg ring-2 ring-gray-800 bg-gray-900 text-gray-100 mr-2 p-2"
                                          placeholder="1scT......"
                                          value={customAddress}
                                          onChange={(e) => setCustomAddress(e.target.value.trim())}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  
                                  <Button onClick={handleMint} variant="gradientSquare" className="w-full font-medium" style={{ color: "black" }}>
                                    Mint For {totalSatsToSpend / 100000000} Bitcoin
                                  </Button>
                                </div>
                              }
                              {isMintComplete && 
                                <a href={`/listings/1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0`}>
                                  <Button onClick={(() => {})} variant="secondarySquare" className="w-full font-medium" style={{ color: "black" }}>
                                    View Market
                                  </Button>
                                </a>
                              }
                            </div>
                            <div> 
                              <PendingTransactionsCheck ordAddress={connectedOrdiAddress!.toString()} />
                            </div>
                          </div>
                        </>
                      }
                          <div className="w-full pt-6">
                              <div className="w-full"><ProgressBar progress={(1 - (remainingSupply / initialSupply)) * 100}/></div> 
                          </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {connectedOrdiAddress && 
        <GalleryGrid 
            parentID={currentOrigin} 
            address={connectedOrdiAddress? connectedOrdiAddress.toString() : "" } 
            collectionName={collectionName}/>
        }
      </div>
      
      <Footer></Footer>
      <Modal
        isOpen={isModalOpen}
        onClose={() => handleModalClose()}
        lastTxid={finalTxId!}
        inputMessage={message? message : 'Minting'}
      />
    </>
  );
};
