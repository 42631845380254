import pandaIcon from "../assets/panda-icon.svg";
import {Button} from "./styled/PrimaryButton"
export type PandaConnectButtonProps = {
  onClick: () => void;
};

// NOTE: Using inline styling demo but prefer styled-components or CSS classes in real app
export const PandaConnectButton = (props: PandaConnectButtonProps) => {
  const { onClick } = props;
  return (
    <Button
      onClick={onClick}
      disabled={false}
      variant="secondary"
      style={{color:"black"}}
    >
      Connect
      <img
        src="https://yours.org/logo.svg"
        alt="icon"
        className="m-2 md:m-4 "
        style={{ width: "3rem", height: "3rem" }}
      />
      Wallet
    </Button>
  );
};
