// Define the interface for your collection items if they follow a specific structure
export interface CollectionItem {
    id: number;
    name: string;
    description: string;
    imageUrl: string;
    origin: string;
    mintLink: string;
    mintPrice: string;
    supply: number;
  }
  //note
  // Define a constant array of items
  export const collectionsData: CollectionItem[] = [
    {
      id: 1,
      name: "Order of the Delta",
      description: "Delta is the symbol for change.",
      imageUrl: "https://ordinals.gorillapool.io/content/217c6fa01e2d59e04138e117198635f750685aca771d1d3e5c808e4bf694df78_0",
      origin: "217c6fa01e2d59e04138e117198635f750685aca771d1d3e5c808e4bf694df78_0",
      mintLink: '/mint-deltas',
      mintPrice: "0.0001000",
      supply: 199
    },
    {
      id: 2,
      name: "Grand Tardinians",
      description: "From the lost island of Tardinia, these Tardinians are the last of their people.",
      imageUrl: "https://ordinals.gorillapool.io/content/f099182e168c0a30a9f97556d156c8b6284cfc3f76eca8352807b1ceff29da04_0",
      origin: "f099182e168c0a30a9f97556d156c8b6284cfc3f76eca8352807b1ceff29da04_0",
      mintLink: '/mint/tardinians',
      mintPrice: "0.05",
      supply: 999
    },
    {
      id: 3,
      name: "0Face",
      description: "A collection of 0Faces",
      imageUrl: "https://ordinals.gorillapool.io/content/cc274a2cc28d88f24a7442443b5542fefe95e486f81f4261e0649401eec30c5d_0",
      origin: "cc274a2cc28d88f24a7442443b5542fefe95e486f81f4261e0649401eec30c5d_0",
      mintLink:"/mint/0face",
      mintPrice: "0.01",
      supply: 100000
    },
    {
      id: 4,
      name: "Free Pepes",
      description: "Free the PEPE. They must break free. ",
      imageUrl: "https://ordinals.gorillapool.io/content/2f3f22a5631a8634317757bd8a48982d476681307bf3cd71320d1240b3eeb9f5_0",
      origin: "2f3f22a5631a8634317757bd8a48982d476681307bf3cd71320d1240b3eeb9f5_0",
      mintLink:"",
      mintPrice: "0.00001",
      supply: 10000
    },
    {
      id: 4,
      name: "GM",
      description: "By @1soysauce",
      imageUrl: "https://ordinals.gorillapool.io/content/2db5261b58f51261594ce3dd7847a02f9ad652ed6d1af9458c1f43687b65ae87_0",
      origin: "2db5261b58f51261594ce3dd7847a02f9ad652ed6d1af9458c1f43687b65ae87_0",
      mintLink:"",
      mintPrice: "0.05",
      supply: 300
    },
    {
      id: 5,
      name: "Pixel Foxes",
      description: "10,000,000 Pixel Foxes on-chain forever",
      imageUrl: "https://mint-sites.s3.amazonaws.com/foxes/yellow-fox.png",
      origin: "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0",
      mintLink:"/mint/pixel-foxes",
      mintPrice:"0.00000001",
      supply: 10000000
    },
    {
      id: 6,
      name: "Based Frogs",
      description: "Based Frogs Ready For Combat",
      imageUrl: "https://mint-sites.s3.amazonaws.com/hLEakPQm.jpg",
      origin: "b94dd05b015519dbb362a4d11399f1f59d8bcfd432996516157f510823892dfc_0",
      mintLink:"/mint/based-frogs",
      mintPrice:"0.0055",
      supply: 55000
    },
    {
      id: 7,
      name: "NVMBEARS",
      description: "Little NFT creatures created from shadow and dust that take the form of various beings.",
      imageUrl: "https://ordinals.gorillapool.io/content/c8d3c69979a221e8ccfad42b174712d9f4443e0ab3676d035e415d166b23543f",
      origin: "c8d3c69979a221e8ccfad42b174712d9f4443e0ab3676d035e415d166b23543f_0",
      mintLink:"/twonks/nvmbears",
      mintPrice:"0.777",
      supply: 999
    },
    {
      id: 8,
      name: "Fox Syndicate",
      description: "The 4444 most feral and ferocious NFT foxes on the blockchain",
      imageUrl: "https://mint-sites.s3.amazonaws.com/c3888b0b37a9c25785b2b03f9fbfcb9e1102ff84dfc859f7ff738aa4cf51f783.jpeg",
      origin: "56d10eed9c2586fc72d615e372b41b797aabaa5c6c1a99d1b1fe2a548972ea9d_0",
      mintLink:"/twonks/fox-syndicate",
      mintPrice:"0.182",
      supply: 4444
    },
    {
      id: 9,
      name: "Astro Apes",
      description: "The 4444 most feral and ferocious NFT foxes on the blockchain",
      imageUrl: "https://ordfs.network/content/fb960be0604d81ae9cf005dd92cc05e8e65298af9ed836ed427a74a7cba22cb6_0",
      origin: "fb960be0604d81ae9cf005dd92cc05e8e65298af9ed836ed427a74a7cba22cb6_0",
      mintLink:"/twonks/astro-apes",
      mintPrice:"0.218",
      supply: 2000
    },
    {
      id: 10,
      name: "Astro Apes: Special Forces",
      description: "A collection of 2000 unique randomly generated Special Forces Apes stored on the blockchain.",
      imageUrl: "https://ordfs.network/content/f66ca8d610dbd3402a2cca6f303cd54554191b06b3de78281dd5472dd7ae8881_0",
      origin: "f66ca8d610dbd3402a2cca6f303cd54554191b06b3de78281dd5472dd7ae8881_0",
      mintLink:"/twonks/special-forces",
      mintPrice:"0.00000001",
      supply: 2000
    },
    {
      id: 11,
      name: "Astro Apes: Lab Monkes",
      description: "A collection of 2000 unique randomly generated Lab Monke Apes stored on the blockchain.",
      imageUrl: "https://ordfs.network/content/84305127e69880b7e89d8828af38f387ac7d94229f2a7ec955cccdbd30ed4db8_0",
      origin: "84305127e69880b7e89d8828af38f387ac7d94229f2a7ec955cccdbd30ed4db8_0",
      mintLink:"/twonks/lab-monkes",
      mintPrice:"0.00000001",
      supply: 2000
    },
    {
      id: 12,
      name: "Astro Apes: Slurp Juice",
      description: "A collection of 4000 SLURP JUICES from the darkest corner of space and time. Consume at your own risk.",
      imageUrl: "https://ordfs.network/content/58f35df588395a1a632db1f62f4f432bae77ed33ae618abec2bd88efbe065827_0",
      origin: "58f35df588395a1a632db1f62f4f432bae77ed33ae618abec2bd88efbe065827_0",
      mintLink:"/twonks/slurp-juice",
      mintPrice:"0.00000001",
      supply: 4000
    },
    {
      id: 13,
      name: "Bored Waifus",
      description: "Bored Waifus is a collection of 2888 unique Bored Waifu NFTs— generative digital collectibles living on the blockchain. ",
      imageUrl: "https://ordfs.network/content/5d6e1c5362b0642673be43e95eb6556bdcc1eb86b558624eaecc59ba25b50ea5_0",
      origin: "5d6e1c5362b0642673be43e95eb6556bdcc1eb86b558624eaecc59ba25b50ea5_0",
      mintLink:"/twonks/bored-waifus",
      mintPrice:"0.2888",
      supply: 2888
    },
    {
      id: 13,
      name: "Rare Sirloins",
      description: "Rare Sirloins",
      imageUrl: "https://ordfs.network/content/d4724cfbcf49c8addf779b7267db992397a32d25628899ba9e93587c31826596_0",
      origin: "d4724cfbcf49c8addf779b7267db992397a32d25628899ba9e93587c31826596_0",
      mintLink:"/twonks/rare-sirloins",
      mintPrice:"0.218",
      supply: 2180
    },
    {
      id: 14,
      name: "Turbo Foxes",
      description: "Turbo Foxes",
      imageUrl: "https://ordfs.network/content/ed3e3cb7e2730ed4b0e5a4ac50d2b06f2e5a8af3a2b8ad7e67828c178d27eb22_0",
      origin: "ed3e3cb7e2730ed4b0e5a4ac50d2b06f2e5a8af3a2b8ad7e67828c178d27eb22_0",
      mintLink:"/twonks/turbo-foxes",
      mintPrice:"0.00000001",
      supply: 69
    },
    {
      id: 15,
      name: "Planetary Pixels",
      description: "Planetary Pixels",
      imageUrl: "https://ordfs.network/content/4bdd09355b5cad44ad7769d6781bc1570559a1e5e369ef9cfc7658fb4208e294_0",
      origin: "4bdd09355b5cad44ad7769d6781bc1570559a1e5e369ef9cfc7658fb4208e294_0",
      mintLink:"/twonks/planetary-pixels",
      mintPrice:"0.02000000",
      supply: 99
    },
    {
      id: 16,
      name: "Planetary Balls",
      description: "Planetary Balls",
      imageUrl: "https://ordfs.network/content/7e9004c9ea93120202f96b7d307c988a52f10d32416a7e07d34c788d045b9107_0",
      origin: "7e9004c9ea93120202f96b7d307c988a52f10d32416a7e07d34c788d045b9107_0",
      mintLink:"/twonks/planetary-balls",
      mintPrice:"0.42000000",
      supply: 420
    },
    {
      id: 17,
      name: "Planetary Squares",
      description: "Planetary Squares",
      imageUrl: "https://ordfs.network/content/61757f7b73135fc5d0c02913f3cb1d33c10ea021a4d5726caeccaad1ab5a3317_0",
      origin: "61757f7b73135fc5d0c02913f3cb1d33c10ea021a4d5726caeccaad1ab5a3317_0",
      mintLink:"/twonks/planetary-squares",
      mintPrice:"0.00100000",
      supply: 222
    },
    {
      id: 18,
      name: "Planetary Buttons",
      description: "Planetary Buttons",
      imageUrl: "https://ordfs.network/content/fb3b5e47ebce0ea81d88218ccd90529908987093ce0a34dee620b8552aa1498f_0",
      origin: "fb3b5e47ebce0ea81d88218ccd90529908987093ce0a34dee620b8552aa1498f_0",
      mintLink:"/twonks/planetary-buttons",
      mintPrice:"2.18000000",
      supply: 69
    },
    {
      id: 19,
      name: "Planetary Skies",
      description: "Planetary Skies",
      imageUrl: "https://ordfs.network/content/7ddaf114a2efa146689439a4587a7242864798cc26c0c2b3f02c8adaa6e95e2e_0",
      origin: "7ddaf114a2efa146689439a4587a7242864798cc26c0c2b3f02c8adaa6e95e2e_0",
      mintLink:"/twonks/planetary-skies",
      mintPrice:"0.20000000",
      supply: 99
    },
    {
      id: 20,
      name: "TwelveCoom",
      description: "TwelveCoom",
      imageUrl: "https://ordfs.network/content/f275fcdff498d6f47d9934cb9321a28e5314f8e4672886475e0dfd19dec56eea_0",
      origin: "f275fcdff498d6f47d9934cb9321a28e5314f8e4672886475e0dfd19dec56eea_0",
      mintLink:"/twonks/twelvecoom",
      mintPrice:"2.18000000",
      supply: 218
    },
  ];


  

  
  
  // Optionally, if you have other related constants, you can define them here
  export const someOtherConstants = {
    key1: "value1",
    key2: "value2"
  };
  