import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import pandaIcon from "../assets/panda-icon.svg";
import { PandaConnectButton } from "../../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { LayerData, RecGCBase } from "../../contracts/rec-gc-base";
import { RecGCFull } from "../../contracts/rec-gc-full";
import artifact from '../../../artifacts/rec-gc-full.json';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from './../../components/styled/PrimaryButton';
import Header from "../../components/styled/Header";
import Footer from "../../components/styled/Footer";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Modal from "../../components/mint/WaitingModal";
import { ShruggrLib } from "../../contracts/shruggr-lib";
import { env } from "process";
import GalleryGrid from "../../components/GalleryGrid";

RecGCFull.loadArtifact(artifact);

export const Tardinians = () => {
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [initialSupply, setInitialSupply] = useState(0)
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [coverUrl, setCoverUrl] = useState<string>('')
  const [currentOrigin, setCurrentOrigin] = useState<string>('f099182e168c0a30a9f97556d156c8b6284cfc3f76eca8352807b1ceff29da04_0');
  const [currentLocation, setCurrentLocation] = useState<string>('f099182e168c0a30a9f97556d156c8b6284cfc3f76eca8352807b1ceff29da04_0');
  const [priceInSatoshis, setPriceInSatoshis] = useState<number>(5000000)
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(10000)
  const [totalSupply, setTotalSupply] = useState<number>(0)
  const [collectionName, setCollectionName] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [finalTxId, setFinalTxId] = useState<string | null>(''); // State to store the mint response
  const [message, setMessage] = useState<string | null>(null)
  const location = useLocation();
  const [signedMessage, setSignedMessage] = useState<
    SignedMessage | undefined
  >();

  //URGENT: Change Cover Photo
  const coverPhotoUrl = 'https://slavettes-layers.s3.amazonaws.com/misc/highly-regarded-w-accessories.png';
  
  useEffect(() => {
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(location.search);
    // Get the value of the 'origin' parameter
    const originQueryParam = queryParams.get('origin');
    if (originQueryParam) {
      // Set the state to the query string value
      console.log("Found Origin:", originQueryParam)
      setCurrentOrigin(originQueryParam);
    }
  }, [location]);

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo);

      if(contractInstance.payOut !== toByteString("")){
        const price = 
          bsv.Transaction.Output.fromBufferReader(
            new bsv.encoding.BufferReader(Buffer.from(contractInstance.payOut, 'hex'))
        )
        console.log(price.satoshis);
      }
      console.log("setting remaining supply", Number(contractInstance.supply))
      localStorage.setItem("remainingSupply", contractInstance.supply.toString());
      setRemainingSupply(0);
    }).catch((error) => console.log({ error }));
  }, [currentOrigin, currentLocation, finalTxId])


  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("setting total supply", contractInstance.supply.toString())
      localStorage.setItem("maxSupply", contractInstance.supply.toString());
      setTotalSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin])

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/txos/${currentOrigin}/`;
    axios.get(url).then((response) => {
      setCollectionName(response.data.origin.data.map.name);
    }).catch((error) => console.log({ error }));
  }, [])


    

  useEffect(() => {
    
    if (!wallet.connect) {
      return;
    }
  
    const connectWallet = async () => {
      try {
        const key = await wallet.connect();
        setPubKey(key);
  
        const signer = new PandaSigner(new OrdiProvider()); // <---- use `PandaSigner`
        setSigner(signer);
  
        const { isAuthenticated, error } = await signer.requestAuth();
        if (!isAuthenticated) {
          throw new Error(`Unauthenticated: ${error}`);
        }
  
        setConnectedPayAddress(await signer.getDefaultAddress());  // <----
        setConnectedOrdiAddress(await signer.getOrdAddress());     // <----
        await handleGetAddresses();
      } catch (error) {
        alert("Something went wrong signing in. " + error);
      }
    };
  
    connectWallet();
  
}, [wallet]);
  
  const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
  const layers: LayerData[] = [
    new LayerData(
      toByteString("Background", true),
      [
        {
          "outpoint": toByteString("980709e82ebe77c8793ba1d7108dc27d472ad156b7d57e552cfddd37f5c954db_0", true),
          "data": toByteString(JSON.stringify({
            "name": "White",
            "description": "Tardinian White",
           // "more arbitrary info": "venom accessory"
          }), true)
        },
        {
          "outpoint": toByteString("ccdf1472b0b20e2278377d97091f9d70588e680ea573e59c8f0142f20bb9e0c6_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Charcoal",
            "description": "Tardinian Charcoal",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
            "outpoint": toByteString("8b97276feb21cb25ace97c784fbe1b9b07b2f054130023e4ad4c49ec8e42a7cb_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Peachy",
              "description": "Everything is Peachy",
            }), true),
        },
        {
            "outpoint": toByteString("5695c0c82acbaa442240bd2f7f4f6fbef1b914c8d237f7e3a107ab8fe91ff46f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snow Blue",
              "description": "The light blue of a snowy Tardinian sky",
            }), true),
          },
          {
              "outpoint": toByteString("79e3102d7bdae874eee158b04d17305773742850cdafddf4fa2f0ef2dac324d4_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Ribbit",
                "description": "In honor of Pepe, the Tradinians best friend",
              }), true),
          },
          {
              "outpoint": toByteString("f934fdc0bca077e2e5d709302bb8495e59d10d0261d5842f1637e751ad0d1b68_0", true),
              "data": toByteString(JSON.stringify({
                "name": "GM GM",
                "description": "The stubtle blue hue in the Tardinian morning sky",
              }), true),
          },
          {
              "outpoint": toByteString("2c881dca1c874e334cd1755a567e7f3fb429f4da74409ff1157f05ad7e96b0a7_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Ocean",
                "description": "The rich tone of the Legendary Tardianian beaches",
              }), true),
          },
          {
              "outpoint": toByteString("35101f58abc0fdddc0e2c6d1c7a80dda6e43a96f277190a329a1630a8f7ee9d0_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Sunset",
                "description": "The stubtle red glow in the Tardinian evening sky",
              }), true),
          },
          {
              "outpoint": toByteString("082544eb4678d1134121285e882ed5b6747de2f64a029abcdafecb136be07635_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Lavender",
                "description": "One of the freshiest scents in all of the land",
              }), true),
          }
      ]
    ),
    new LayerData(
        toByteString("Body", true),
        [
          {
            "outpoint": toByteString("a12b2abcfdf73771bc6f3166f63539535a38f2fdfaf7d1233234b84fe75a8c8b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "The Model Tardinian",
              "description": "The exquisite shape of the Tardinians",
             // "more arbitrary info": "venom accessory"
            }), true),
          },
        ]
    ),
    new LayerData(
      toByteString("Clothes", true),
      [
        {
          "outpoint": toByteString("4a3bcee66b8e7de48fc9cc884ba66f9ed5a97b2351b4a59984d4b087df6b7494_0", true),
          "data": toByteString(JSON.stringify({
            "name": "LAWR T",
            "description": "The LAWR T-Shirt",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("9d7700cd30850e6715e3ea620dd6a768110bca0ad7f34e77926d01c655331ece_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Highly Regarded",
            "description": "Black Tie Formal Wear for the most highly regarded Tardinians with a Rose",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("d3f31f69dfeae05ea2b52093feaedc0554b56a8e20537f2b564cb92d85ba3f25_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Bigfoot Coat",
            "description": "A classic Tardinian Yeti Coat",
          }), true),
        },
        {
            "outpoint": toByteString("42e9eb925fa8b374228d8fe871465b84d5809f4f134cbcb6fc0d096053f60b8b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Pepe T",
              "description": "The T-shirt featuing the Tardinians best friend, Pepe",
            }), true),
          },
          {
            "outpoint": toByteString("dffd320b06385ace817e75b39356657a92e393fa7ee31b45e678f3977cc96d84_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Cookin",
              "description": "The master bakers coat for cookin in the kitchen.",
            }), true),
          },
          {
            "outpoint": toByteString("aeab237863cac866084082f4749c37328588deb3ff289ac58de711be207c3db6_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Polo Club",
              "description": "A classic southern Targentinian Polo T",
            }), true),
          },
          {
            "outpoint": toByteString("62d8c2c81df18271db75cdf00567ca332fab1e0307632a46922afa4c0a9b600e_0", true),
            "data": toByteString(JSON.stringify({
              "name": "I Got KREGD",
              "description": "The KREGD T-Shirt",
            }), true),
          },
          {
            "outpoint": toByteString("6dcdc48dc6ef528f64f08279e74231dd1722fb3ddc468b9f4451056bda7cd48a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "HFSP T",
              "description": "I HFSP T-Shirt",
            }), true),
          },
          {
            "outpoint": toByteString("8d995333b1b0e6b7044e1a9e536f0dc7c10835c3f9c2fb63400082acacfb27c6_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Plain White T",
              "description": "The Plain White T-Shirt",
            }), true),
          },
          {
            "outpoint": toByteString("365233915859f143e375848c4a8f19072deb0bb3ecacf44e9ba20fb6875ae346_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Burger Flipper",
              "description": "Classic Apron MickeyDees, the most coveted job in Tardinia ",
            }), true),
          },
          {
            "outpoint": toByteString("3070402ef29eab49b512f02fad56358ae9233c9e0b53d1eb9ed139bbd1d16e2a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Gen Z Hoodie",
              "description": "That soft colored hoodie",
            }), true),
          }
      ]
    ),
    new LayerData(
      toByteString("Eyes", true),
      [
        {
          "outpoint": toByteString("0c3991c6a25b60a47f99a7d8a190b51707192e3d70c05a2aaa02bfa888c62273_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Watermark",
            "description": "I see watermarks",
          }), true),
        },
        {
          "outpoint": toByteString("32ee3978b8bef4f1e0ee18267ca63f73d171efd7a1ee90823605e7cd78e85f2b_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Stoned",
            "description": "Because I got high...",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("ae2367f5790b3d77dd2b9c83c75259a6b2f29e4d127b1a1c37cd04dee68e2a0c_0", true),
          "data": toByteString(JSON.stringify({
            "name": "AVP",
            "description": "Lost in the matrix",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("c059c4124146216bdff2bbf3fdc0cc1dbdb5ef1d098fedc49b00ca40b02361ba_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Macrodosing",
            "description": "I took like 20 microdoses, am I doing it right?",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("a0765a6ced555e446ec3c156cf865adffa0d2ee742b287fe2a964bb1b405c3c6_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Aviators",
            "description": "Standard issue for all Tardinian seamen",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("4e734133843e373cf379c0e9a9f61c8c171465e5e49ef1e778a9c730ac59abb8_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Reptilian",
            "description": "They blink sideways. Watch in slowmo.",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
            "outpoint": toByteString("59fcedaabd5cd8004de878d7ef29bee836a2c29de473f67d12d473f91cc039db_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Bored",
              "description": "The Bored Tardinian Look",
             // "more arbitrary info": "venom accessory"
            }), true),
          },
          {
            "outpoint": toByteString("1baf5505d5212bcde964d583f0476684acf82a1851bd964bf8d5557a6dc48dee_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Dopey",
              "description": "Which eye should you look at?",
             // "more arbitrary info": "venom accessory"
            }), true),
          },
          {
            "outpoint": toByteString("f10b40d9c77abbadeeb4cfdbfd5d33de430c515f279b1f98137dc335d90b33ba_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Monacle",
              "description": "Formal Eyewear for the most Highly Regarded Tardinians",
             // "more arbitrary info": "venom accessory"
            }), true),
          }
      ]
    ),
    new LayerData(
        toByteString("Head", true),
        [
          {
            "outpoint": toByteString("612ab071496ae5188e294723e98428e4e33ff5da5df8029134448407e245f460_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Wizards Cap",
              "description": "The special cap for the Tardinian wizard dunces",
            }), true),
          },
          {
            "outpoint": toByteString("97452811147fb24d41a1ee967d9ca0a5bbcf402f8ac6dc1aae102c62b843b835_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Ribbit Ribbit",
              "description": "A Tardinian frog daemon, inseperable from his friends ",
            }), true),
          },
          {
            "outpoint": toByteString("5a428d30a8ed31c7b8fbfea4f7e7cc73550b1aaf377e2abc39d6be4efc263734_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Skully",
              "description": "Just a skully cap",
            }), true),
          },
          {
            "outpoint": toByteString("a722493ce9c0f7a720406672360045203bbbf35e30458ae65c3193454ba258d7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Master Baker",
              "description": "Traditional headwear for the Tardinian Master Bakers",
            }), true),
          },
          {
            "outpoint": toByteString("5964c0af5b529b93fbf8a4e1019a96acdb1192d891abfc09ffcc44bae61a34c8_0", true),
            "data": toByteString(JSON.stringify({
              "name": "iScream",
              "description": "Are you even real if theres no iScream on your head?",
            }), true),
          },
          {
            "outpoint": toByteString("218ab0f28f8e00d065a5c0e81656d0b14f2b375150b2436314ac4884ad78812d_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Burger Flipper",
                "description": "Classic MickeyDees visor",
            }), true),
          },
          {
            "outpoint": toByteString("729487bc18a91fee4825253af8f2543368da15a21bb717faef320b3fd9e3137d_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Highly Regarded",
                "description": "Highly regarded headwear for the formal Tardinian",
            }), true),
          },
          {
            "outpoint": toByteString("454b84fae86cd11bee934090c9293cfde042f348fb8da925f64b86b278a4e610_0", true),
            "data": toByteString(JSON.stringify({
                "name": "None",
                "description": "Just Tardinian Hair",
            }), true),
          },
          {
            "outpoint": toByteString("e17d1c488bc89d20d3363c7128c41309e6f327465c66c6508ff3f66f9c982f29_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Nothing To See Here",
                "description": "The classic Tardinian blank hat",
            }), true),
          },
          {
            "outpoint": toByteString("34c0c57f9736f9ae867c64574c0fec88a14e202ac154991e18de9412794363c2_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Hellmut",
                "description": "The watermellon helmet his mom makes him wear",
            }), true),
          },
          {
            "outpoint": toByteString("e3f0f34d80d2d228cdde735fd15e87612a76dc303e885a01e5f992553674ce8c_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Beach Hat",
                "description": "The dumb bucket hat all Tardinians wear on vacation when they become Boomers.",
            }), true),
          },
        ]
    ),
    new LayerData(
        toByteString("Accessories", true),
        [
          {
            "outpoint": toByteString("075d1bcdf09cbd26a4f20a2e18538314cbd29c79ef4e2cad715502e2bc892ea2_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Popcorn",
              "description": "The classic drama watching snack",
            }), true),
          },
          {
            "outpoint": toByteString("b360bb1b30eba452f0ceef6e11b7292171e7b6ffe37fed8a2036bf9d2e1c69f2_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Dumpster Fire",
              "description": "Its all going up in flames",
            }), true),
          },
          {
            "outpoint": toByteString("829439831437054db74a48c8bfc7648ac1d741d663f973a9a238ab02b7daa411_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Smokin",
              "description": "Got a butt?",
            }), true),
          },
          {
            "outpoint": toByteString("8aa5d4f79c799b10e5ab98663032397e770c152a799e3e400bca63631e836102_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Mustache",
              "description": "Highly Regarded facial hair.",
            }), true),
          },
          {
            "outpoint": toByteString("3e9f62cb3697ef822c0794ebbb1216188f3713016c0602ae7384c8e499aeeb4b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "None",
              "description": "No accessories",
            }), true),
          },
          {
            "outpoint": toByteString("8e23017c71b2e142381069cb19d85813de2d5f1d186b3a381ab44c4155342e82_0", true),
            "data": toByteString(JSON.stringify({
              "name": "The Daily",
              "description": "The daily news from all across Tardinia",
            }), true),
          },
          {
            "outpoint": toByteString("6afd37e5d17bd38fba3c721ef1dc2267a1e769229ddfcc5d44ba56d370b46628_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Stackin Sats",
              "description": "A big stack of sats",
            }), true),
          },
          {
            "outpoint": toByteString("2d1e59dd5b198188d906f3057ab1ed1fdd9e6ea81bca8b5f061c44e1c48a7172_0", true),
            "data": toByteString(JSON.stringify({
              "name": "The Runestone",
              "description": "The legendary Runestones of Tardinia",
            }), true),
          },
          {
            "outpoint": toByteString("f76cf60d37e0751658f7a70b89f7a48be70a83809e8957e5fef9ff5138824563_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Bubblemint",
              "description": "Bubblemint Bubblemint gum",
            }), true),
          },
          {
            "outpoint": toByteString("fd4fab326d11ae7d36e0b1bd05a9a93716fe02715422d765d3def786bd65c87f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Rolling Icebergs",
              "description": "A portrait of broken dreams",
            }), true),
          },
        ]
    )
  ]
  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try{
      const key = await wallet.connect();
      setPubKey(key);
      const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth()
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`)
      }
      setConnectedPayAddress(await signer.getDefaultAddress())  // <----
      setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
      await handleGetAddresses();
    } catch(error){
      alert("Something went wrong sigining in. " + error)
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false)
    setFinalTxId(null)
  }

  //TODO: Add a hook to get the contract details from the origin 1 time if theyre not populated 
  //      and save them in initalSupply, etc... 

  const handleDeploy = async () => {
    //URGENT: Set up Yours wallet to receive payment
    const payoutScript = bsv.Script.buildPublicKeyHashOut(connectedPayAddress!)
    console.log("Payout Script:", payoutScript);
    const payout =
      new bsv.Transaction.Output({ script: payoutScript, satoshis: priceInSatoshis })
    console.log("Payout:", payout);
    const payoutHex = payout.toBufferWriter()
      .toBuffer()
      .toString('hex');
    console.log("PayoutHex:", payoutHex);

    //TODO: Derive this from the layers object but make it match the CollectionItem Spec
    //this _subTypeData is arbitrary but searchable. 
    //Include any data that the collection would want to be queried by
    // I should build this autmatically from the layers information. 
    const _subTypeData = {
      description: "Grand Tardinians",
      attributes: [
        // //Layers
        // {
        //   name: "Accessory",
        //   assets: [
        //     //Layer Items
        //     {
        //       "outpoint": "d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0",
        //       "name": "Venom",
        //       "description": "venom accessory",
        //       "more arbitrary info": "venom accessory"
        //     },
        //     {
        //       "outpoint": "6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0",
        //       "name": "Purple",
        //       "description": "purple accessory",
        //       "more arbitrary info": "purple accessory"
        //     },
        //     {
        //       "outpoint": "0538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0",
        //       "name": "Pink",
        //       "description": "Pink accessory",
        //       "more arbitrary info": "Pink accessory"
        //     }
        //   ]
        // },
        // {
        //   name: "Clothes",
        //   assets: [
        //     {
        //       "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
        //       "name": "Yellow Clothes",
        //       "description": "Sun clothes",
        //       "more arbitrary info": "Yellow Clothes"
        //     },
        //     // ...
        //   ],
        // },
        // {
        //   name: "Eyes",
        //   assets: [
        //     {
        //       "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
        //       "name": "Yellow Eyes",
        //       "description": "Sun colored Eyes",
        //       "more arbitrary info": "This is a cool Eyes."
        //     },
        //     // ...
        //   ],
        // }
      ]
    }

    //URGENT: Set up Yours wallet to receive royalties
    //set up to tardians-live-wallet
    const _royalties = [
      { type: "address", destination: "1QD3avnNJbdsdoYEkvSgzuRYL2toqAVUEq", percentage: "0.03" }
    ]
    const metadata = {
      app: 'Bubblemint',
      type: 'ord',
      contractId: artifact.md5,
      name: "Grand Tardinians",
      subType: 'collection',
      subTypeData: JSON.stringify(_subTypeData),
      royalties: JSON.stringify(_royalties),
    };

    console.log(JSON.stringify(metadata));
    const map = new bsv.Script('');
    map.add(Buffer.from("SET"));
    for (const [key, value] of Object.entries(metadata)) {
      map.add(Buffer.from(key))
      map.add(Buffer.from(value))
    }

    const response = await fetch(coverPhotoUrl);
    const data = await response.arrayBuffer();

    // console.log("Content:", )
    const inscriptionScript = ShruggrLib.buildInscriptionScript({
      content: Buffer.from(data),
      contentType: 'image/png'
    },
      {
        "1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5": map.toBuffer()
      }
    )
    const deployable = new RecGCFull(
                          toByteString(metadata.name, true), //the ordinals name 
                          RecGCBase.buildLayerNames(layers), //constructed names for the layers
                          RecGCBase.buildLayers(layers),  // the layers object
                          999n, // number of NFTs to mint into the collection
                          0n, // This is a difficuty number in bits. Set to 0 for no POW. 4 of these difficuly = 1 POW20
                          toByteString(payoutHex), //the address & amount to pay when minting
                          0n, //the current height of the chain to enforce the starting point on locks 
                          0n, // number of blocks to remain locked 
                          0n, // number of satoshis that must be locked
                          BigInt(inscriptionScript.length)) // number of bytes in the inscription

    console.log("Deployable:", deployable);
    await deployable.connect(signer!)

    let deployResponse = await deployable.inscribeWithNoOp(inscriptionScript)
    const rawTx = deployResponse.toBuffer();
    console.log(deployResponse.id, rawTx);
    setTimeout(async () => {
      axios.get(`https://ordinals.gorillapool.io/api/tx/${deployResponse.id}/submit`)
    }, 5000)
    setCurrentOrigin(deployResponse.id + "_0")
  };

  const handleMint = async () => {
    setMessage(null);
    setIsModalOpen(true);
    try {
      setFinalTxId('');
      await wallet.getPaymentUtxos()
      await wallet.getBalance();
      const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
      const response = await axios.get(url);
      console.log("This is the the response to Latest", response.data);
      if (response.data.spend && response.data.spend !== "") {
        //This is the signal that the mint is complete. 
        console.log("spend is not null");
        setMessage("Try refreshing the balance in yours wallet by clicking balance display")
        return;
      }
      console.log("setting current location", response.data.outpoint);
      setCurrentLocation(response.data.outpoint)
      // const inscPrefix = Buffer.from('0063036f726451', 'hex')

      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("Contract Instance initialized...", contractInstance.layers);
      setRemainingSupply(Number(contractInstance.supply));
      localStorage.setItem("remainingSupply", Number(contractInstance.supply).toString());

      let serviceFeeOutput = new bsv.Transaction.Output({
        script: bsv.Script.fromAddress(new bsv.Address("1QD3avnNJbdsdoYEkvSgzuRYL2toqAVUEq")),
        satoshis: 1000
      })

      await contractInstance.connect(signer!)
      const nonce = toByteString('');
      const selectionData = contractInstance.buildSelectionData(layers, nonce);
      console.log({ selectionData });
      const buyerScript = bsv.Script.buildPublicKeyHashOut(connectedOrdiAddress!)
      setMessage("Signing");
      const mintResponse = await contractInstance.methods.mint(
        nonce, // user generated random bytestring. If the difficulty is higher than 0 this needs to be something that when hashed with the previous txid (Little Endian) it creates the output with the specified proof of work. 
        toByteString(buyerScript.toHex()),
        toByteString(''), // public key hash that these satoshis will be locked to
        selectionData,
        toByteString(serviceFeeOutput.toBufferWriter().toBuffer().toString('hex')),
        {
          changeAddress: connectedPayAddress,
          pubKeyOrAddrToSign: connectedPayAddress,
          partiallySign: true
        })

      const rawTx = mintResponse.tx.toBuffer();
      console.log(mintResponse.tx.id, rawTx);
      await setTimeout(async () => {
        setMessage("Broadcasting");
        let injectResponse = await axios.get(`https://ordinals.gorillapool.io/api/tx/${mintResponse.tx.id}/submit`)
        console.log({injectResponse})
        await sleep(3000)
        setFinalTxId(mintResponse.tx.id);
      }, 5000)


    } catch (error) {
      let er = error as Error;
      console.error('Error Minting inscription:', er);
      if(er.toString()==="User dismissed the request!"){
        setMessage(null);
        setIsModalOpen(false);
      } else if(er.toString().includes('mempool')) {
        setMessage("w0mp w0mp. someone beat you too it")
      }else{
        setMessage(er.toString())
      }
      //alert("Something went wrong. Refresh the page and try again. Error message: " + error);
      // Handle the error as needed
    }
  };

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  const handleSignMessage = async () => {
    if (!messageToSign) {
      alert("There was no message to sign!");
      return;
    }
    const signRes = await wallet.signMessage({ message: messageToSign });
    if (signRes) setSignedMessage(signRes);
  };

  return (
    <>

      <div className="App bg-dark relative isolate overflow-hidden text-white">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> <a href="/">← Go Back, Pussy </a></div>
          </div>
          <div className="">
            {!addresses &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {addresses &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <div className="App-header h-full">
        
          <div className="">
            <Header 
              initialRemainingSupply={remainingSupply}
              initialMaxSupply={totalSupply}
              inputPrice={priceInSatoshis}></Header>
          </div>
            
          <div className="max-w-7xl">
            <div className=" rounded-lg">
              
              <div className="flex-1 rounded-lg px-8 bg-dark ">
                <div className="flex-1 md:grid md:grid-cols-5 my-2  rounded-lg p-8">
                  <div className="flex items-center md:col-span-2">
                    <div className="bg-gray-100 rounded-xl ring-yellow-500 ring-2 overflow-hidden">
                      <img src={coverUrl}
                          alt="Collection Cover" 
                          className="mx-auto h-full w-full" 
                           />
                    </div>
                  </div>
                  <div className=" flex-1 flex flex-col md:col-span-1"></div>
                  <div className=" flex-1 flex flex-col md:col-span-2 justify-between flex-grow py-12 px-2 lg:px-4 xl:px-8 ">
                    <section
                      aria-labelledby="summary-heading"
                      className="mt-16 w-full rounded-lg bg-gray-900 px-4 py-6 sm:p-4 lg:col-span-5 lg:mt-0 lg:p-8 ring-gray-500 ring-2"
                    >
                      <h2 id="summary-heading" className="text-lg font-medium">
                      {collectionName}
                      </h2>

                      <dl className="mt-6 space-y-4">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm text-gray-200">Price</dt>
                          <dd className="text-sm font-medium text-gray-200">₿ {priceInSatoshis / 100000000}</dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className=" md:text-xs flex items-center text-sm text-gray-200">
                            <span>Mint Fee</span>
                            {/* <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">What is this?</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a> */}
                          </dt>
                          <dd className="text-sm font-medium text-gray-200">{serviceFeeInSatoshis} <span className="text-xs">sats</span></dd>
                        </div>
                        {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                          <dt className="flex text-sm text-gray-600">
                            <span>Tax estimate</span>
                            <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Learn more about how tax is calculated</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                          </dt>
                          <dd className="text-sm font-medium text-gray-900">$8.32</dd>
                        </div> */}
                        {/* <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className="text-base font-medium text-gray-200">Order total</dt>
                          <dd className="text-base font-medium text-gray-200">₿ {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}</dd>
                        </div> */}
                      </dl>

                      <div className="mt-6">
                      {!addresses &&
                        <PandaConnectButton onClick={handleConnect} />
                      }
                      {addresses &&
                        <>
                          {/* <Button onClick={handleDeploy} variant="secondarySquare" className="w-full font-medium" style={{ color: "black" }}>
                              Deploy 
                          </Button> */}
                          <a href={`/listings/${currentOrigin}`} >
                          <Button onClick={handleMint} variant="gradientSquare" className="w-full font-medium" style={{ color: "black" }}>
                              Trade
                          </Button>
                          </a>
                        </>
                      }
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GalleryGrid 
            parentID={currentOrigin} 
            address={connectedOrdiAddress? connectedOrdiAddress.toString() : "" } 
            collectionName={collectionName}/>
      </div>
      
      <Footer></Footer>
      <Modal
        isOpen={isModalOpen}
        onClose={() => handleModalClose()}
        lastTxid={finalTxId!}
        inputMessage={message? message : 'Minting'}
      />
    </>
  );
};
