import { Ordinal, OrdinalNFT } from 'scrypt-ord'
import {
    assert,
    bsv,
    ByteString,
    fill,
    FixedArray,
    hash160,
    hash256,
    int2ByteString,
    len,
    method,
    OpCode,
    prop,
    slice,
    SmartContract,
    toByteString,
    Utils,
    UTXO,
    VarIntWriter,
} from 'scrypt-ts'
import { ShruggrLib } from './shruggr-lib'

export const NAME_LEN = 32n
export const OUTPOINT_LEN = 66n
export const ENTRY_LEN = 86n

export abstract class RecGCBase extends OrdinalNFT {
    static readonly LAYERS = 15

    @prop()
    public layerNames: FixedArray<ByteString, typeof RecGCBase.LAYERS>

    @prop()
    public layers: FixedArray<ByteString, typeof RecGCBase.LAYERS>

    @prop()
    public initialSupply: bigint

    @prop()
    public collectionName: ByteString

    @prop(true)
    public supply: bigint

    @prop(true)
    public origin: ByteString

    @prop(true)
    public collectionId: ByteString

    @prop(true)
    public inscBytes: bigint

    constructor(
        collectionName: ByteString,
        layerNames: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        layers: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        supply: bigint,
        inscBytes: bigint
    ) {
        super()
        this.collectionName = collectionName
        this.layerNames = layerNames
        this.layers = layers
        this.supply = supply
        this.initialSupply = supply
        this.origin = toByteString('')
        this.collectionId = toByteString('')
        this.inscBytes = inscBytes
    }

    @method()
    buildOutputs(
        seed: ByteString,
        buyerScript: ByteString,
        contractOuts: ByteString,
        selectionData: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        trailingOuts: ByteString
    ): ByteString {
        if (this.origin === toByteString('')) {
            this.origin =
                this.ctx.utxo.outpoint.txid +
                int2ByteString(this.ctx.utxo.outpoint.outputIndex)
            this.collectionId =
                Ordinal.txId2str(this.ctx.utxo.outpoint.txid) +
                toByteString('_', true) +
                Ordinal.int2Str(this.ctx.utxo.outpoint.outputIndex)
        }

        this.supply = this.supply - 1n
        let stateOutput = toByteString('')
        if (this.supply > 0n) {
            if (this.inscBytes > 0n) {
                const inscBytes = this.inscBytes
                this.inscBytes = 0n
                const stateScript = slice(this.getStateScript(), inscBytes)
                stateOutput = Utils.buildOutput(stateScript, 1n)
            } else {
                stateOutput = this.buildStateOutput(1n)
            }
        }

        const selections = RecGCBase.randomize(seed, this.layers)

        const inscriptionOutput = this.buildInscriptionOutput(
            this.buildName(),
            selections,
            this.origin,
            this.collectionId,
            buyerScript,
            this.layerNames,
            this.layers,
            selectionData
        )

        return stateOutput + contractOuts + inscriptionOutput + trailingOuts
    }

    @method()
    buildName(): ByteString {
        return (
            this.collectionName +
            toByteString(' ', true) +
            Ordinal.int2Str(this.initialSupply - this.supply)
        )
    }

    @method()
    buildInscriptionOutput(
        name: ByteString,
        selections: FixedArray<bigint, typeof RecGCBase.LAYERS>,
        origin: ByteString,
        collectionId: ByteString,
        buyerScript: ByteString,
        layerNames: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        layers: FixedArray<ByteString, typeof RecGCBase.LAYERS>,
        selectionData: FixedArray<ByteString, typeof RecGCBase.LAYERS>
    ): ByteString {
        let inscription = toByteString(
            '<svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">',
            true
        )
        // let subType = toByteString('collectionItem', true)
        let subTypeData =
            toByteString('{"collectionId": "', true) +
            collectionId +
            toByteString('", "attributes":[', true)
        // const selections = this.getLayers(seed, layers)
        for (let i = 0; i < RecGCBase.LAYERS; i++) {
            const index = selections[i]
            if (index > -1n) {
                const pos = index * ENTRY_LEN
                inscription +=
                    toByteString('<image href="/content/', true) +
                    slice(layers[i], pos, pos + OUTPOINT_LEN) +
                    toByteString('" width="100%" height="100%" />', true)

                const datahash = slice(
                    layers[i],
                    pos + OUTPOINT_LEN,
                    pos + ENTRY_LEN
                )

                assert(
                    hash160(selectionData[i]) === datahash,
                    'layer ' +
                        i +
                        ' hash mismatch ' +
                        datahash +
                        ' ' +
                        selectionData[i] +
                        ' ' +
                        hash160(selectionData[i])
                )
                subTypeData +=
                    toByteString('{"name":"', true) +
                    layerNames[i] +
                    toByteString('","details":', true) +
                    selectionData[i] +
                    toByteString('},', true)
            }
        }
        inscription += toByteString('</svg>', true)

        // remove last comma
        subTypeData =
            slice(subTypeData, 0n, len(subTypeData) - 1n) +
            toByteString(']}', true)

        const MAP =
            VarIntWriter.writeBytes(toByteString('SET', true)) +
            VarIntWriter.writeBytes(toByteString('name', true)) +
            VarIntWriter.writeBytes(name) +
            VarIntWriter.writeBytes(toByteString('subType', true)) +
            VarIntWriter.writeBytes(toByteString('collectionItem', true)) +
            VarIntWriter.writeBytes(toByteString('subTypeData', true)) +
            VarIntWriter.writeBytes(subTypeData)

        const script =
            OpCode.OP_FALSE +
            OpCode.OP_IF +
            VarIntWriter.writeBytes(toByteString('ord', true)) +
            OpCode.OP_1 +
            VarIntWriter.writeBytes(toByteString('image/svg+xml', true)) +
            OpCode.OP_3 +
            VarIntWriter.writeBytes(origin) +
            VarIntWriter.writeBytes(
                toByteString('1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5', true)
            ) +
            VarIntWriter.writeBytes(MAP) +
            OpCode.OP_FALSE +
            VarIntWriter.writeBytes(inscription) +
            OpCode.OP_ENDIF +
            buyerScript

        return Utils.buildOutput(script, 1n)
    }

    @method()
    static randomize(
        seed: ByteString,
        layers: FixedArray<ByteString, typeof RecGCBase.LAYERS>
    ): FixedArray<bigint, typeof RecGCBase.LAYERS> {
        const selections = fill(0n, RecGCBase.LAYERS)
        for (let i = 0; i < RecGCBase.LAYERS; i++) {
            const length = len(layers[i])
            if (length > 0) {
                seed = hash256(seed)
                const index = Utils.fromLEUnsigned(seed) % (length / ENTRY_LEN)
                selections[i] = index
            } else {
                selections[i] = -1n
            }
        }
        return selections
    }

    inscribeWithNoOp(noOpScript: bsv.Script) {
        this.prependNOPScript(noOpScript)
        return this.deploy(1)
    }

    static fromInscriptionUTXO<T extends SmartContract>(utxo: UTXO) {
        if (utxo.satoshis !== 1) {
            throw new Error('invalid ordinal p2pkh utxo')
        }
        let contractScript = bsv.Script.fromHex(utxo.script)
        if (utxo.script.startsWith('0063036f7264')) {
            const endInscPos = contractScript.chunks.findIndex((c) => {
                return c.opcodenum === bsv.Opcode.OP_ENDIF
            })
            contractScript = bsv.Script.fromChunks(
                contractScript.chunks.slice(0, endInscPos + 1)
            )
            const instance = (
                this as unknown as typeof SmartContract
            ).fromLockingScript(utxo.script, {}, contractScript) as T
            instance.from = utxo
            return instance
        }
        const instance = (this as unknown as typeof SmartContract).fromUTXO(
            utxo
        ) as T
        return instance
    }

    buildSelectionData(
        layersData: LayerData[],
        nonce: ByteString
    ): FixedArray<ByteString, typeof RecGCBase.LAYERS> {
        const seed = ShruggrLib.buildPoW(
            toByteString(
                Buffer.from(this.utxo.txId, 'hex').reverse().toString('hex')
            ),
            nonce
        )

        const selectionData = fill(toByteString(''), RecGCBase.LAYERS)
        const selections = RecGCBase.randomize(
            seed,
            RecGCBase.buildLayers(layersData)
        )
        for (let i = 0; i < RecGCBase.LAYERS; i++) {
            const selection = selections[i]
            if (selection > -1n) {
                selectionData[i] = layersData[i].options[Number(selection)].data
            }
        }
        return selectionData
    }

    static buildLayers(
        layerData: LayerData[]
    ): FixedArray<ByteString, typeof RecGCBase.LAYERS> {
        const layers = fill(toByteString(''), RecGCBase.LAYERS)
        for (let i = 0; i < RecGCBase.LAYERS; i++) {
            if (layerData[i]) {
                layers[i] = layerData[i].buildLayer()
            }
        }
        return layers
    }

    static buildLayerNames(
        layerData: LayerData[]
    ): FixedArray<ByteString, typeof RecGCBase.LAYERS> {
        const layerNames = fill(toByteString(''), RecGCBase.LAYERS)
        for (let i = 0; i < RecGCBase.LAYERS; i++) {
            if (layerData[i]) {
                layerNames[i] = layerData[i].name
            }
        }
        return layerNames
    }
}

export interface LayerOption {
    outpoint: ByteString
    data: ByteString
}

export class LayerData {
    data: any
    constructor(public name: ByteString, public options: LayerOption[]) {
        this.name = name
        this.options = options
    }

    buildLayer(): ByteString {
        return this.options.reduce(
            (layer, o) => (layer += o.outpoint + hash160(o.data)),
            toByteString('')
        )
    }
}
