import React, { useState, useEffect } from 'react';
const url = 'https://checkunprocessedtransactions-kq55e66zjq-uc.a.run.app';
// Adding a TypeScript interface for props to ensure type safety
interface CheckTransactionsProps {
    ordAddress: string;
  }
  
  const CheckTransactions: React.FC<CheckTransactionsProps> = ({ ordAddress }) => {
    const [loading, setLoading] = useState(false);
    const [exists, setExists] = useState<boolean | null>(null);
  
    // Function to fetch transactions
    const fetchTransactions = async () => {
      if (!ordAddress) {
        alert("Please enter an OrdAddress.");
        return;
      }
      setLoading(true);
  
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ordAddress }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setExists(data.exists);
      } catch (error) {
        console.error('Error fetching data:', error);
        setExists(null);
      } finally {
        setLoading(false);
      }
    };
  
    // Effect to run on ordAddress change
    useEffect(() => {
      fetchTransactions(); // Perform check when ordAddress changes
    }, [ordAddress]); // Dependency on ordAddress
  
    return (
      <div className='flex pt-2'>
        <button onClick={fetchTransactions} disabled={loading}>

        </button>
        {loading ? (
          <p className='text-xs'>Loading...</p>
        ) : exists !== null && exists ? (
            <><div className="spinner"></div><p className='text-xs pt-2'>{exists ? 'you have mints in queue, but you can still mint more' : ''}</p></>
        ) : null}
      </div>
    );
  };
  
  export default CheckTransactions;
