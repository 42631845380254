import "./App.css";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Deploy from './page/Deploy'
import { SamplePage } from "./page/SamplePage";
import { SampleLTM } from "./page/SampleLTM";
// import { LockToMintSample } from "./page/LockToMintSample";
import { SampleSVG } from "./page/SampleSVG";
import { Home } from "./page/Home";
import { POWPay } from "./page/POWPay";
import { MintRequest } from "./page/MintRequest";
import { MintDeltas } from "./page/MintDeltas";
import { Tardinians } from "./page/mint/Tardinians";
import { ZeroFace } from "./page/mint/0faces";
import { PixelFoxes } from "./page/mint/pixel-foxes";
import { BasedFrogs } from "./page/mint/based-frogs";
import { AstroApes } from "./page/mint/twonks/astro-apes";
import { FoxSyndicate} from "./page/mint/twonks/fox-syndicate";
import { TwonksMint } from "./page/mint/twonks/twonksMint";
import Listings from './page/listings/[id]';
export const App = () => {
  return (
  <Router>
      <Switch>
        {/* <Route path="/lock-to-mint-sample" component={LockToMintSample} /> */}
        <Route path="/sample-svg" component={SampleSVG} />
        <Route path="/listings/:id" component={Listings} />
        <Route path="/pow-pay" component={POWPay} />
        <Route path="/deploy" component={Deploy} />
        <Route path="/mint-ltm" component={SampleLTM} />
        <Route path="/mint-request" component={MintRequest} />
        <Route path="/mint-deltas" component={MintDeltas} />
        <Route path="/mint-sample" component={SamplePage} />
        <Route path="/mint/tardinians" component={Tardinians} />
        <Route path="/mint/0face" component={ZeroFace} />
        <Route path="/mint/pixel-foxes" component={PixelFoxes} />
        <Route path="/mint/based-frogs" component={BasedFrogs} />
        <Route path="/twonks/astro-apes" component={AstroApes} />
        <Route path="/twonks/fox-syndicate" component={FoxSyndicate} />
        <Route path="/twonks/:name" component={TwonksMint} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  )
};
