import { useEffect, useState } from "react";
import pandaIcon from "../assets/panda-icon.svg";
import { PandaConnectButton } from "../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { LayerData, RecGCBase } from "../contracts/rec-gc-base";
//import { RecGCPay1 } from "../contracts/rec-gc-pay";
//import artifact from '../../artifacts/rec-gc-pay.json';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from './../components/styled/PrimaryButton';
import Header from "../components/styled/Header";

//RecGCPay1.loadArtifact(artifact);

export const SampleSVG = () => {
  const wallet = usePandaWallet();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [layer1ID, setLayer1ID] = useState<string>('063dfe105b7ac81208b1b56ea121e605fc442f692bb93903a30b2ac273fd930c_0')
  const [layer2ID, setLayer2ID] = useState<string>('8effb6541fbc7ea71f1d02a0d1576c47a3d39cf0f53e1d68762a7761ec85883a_0')
  const [layer3ID, setLayer3ID] = useState<string>('b65f274981ba3cc60f898ab208144aa503ad14b4877275987d149466ac9ac2ac_0')
  const [layer4ID, setLayer4ID] = useState<string>('f11c0efe2e01dadfca4a92f442888dafe995415ab52a3fa646d3827a9e20b0a7_0')
  const [layer5ID, setLayer5ID] = useState<string>('82e943e775ed2b83f571c349ec3c0561ef01e2642bce35ed1218055073e1760a_0')
  

  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    const key = await wallet.connect();
    const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
    const { isAuthenticated, error } = await signer.requestAuth()
    if (!isAuthenticated) {
      throw new Error(`Unauthenticated: ${error}`)
    }
    setConnectedPayAddress(await signer.getDefaultAddress())  // <----
    setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
    await handleGetAddresses();
  };

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  return (
    <>

      <div className="App">
        <div className="menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> Logo </div>
          </div>
          <div className="">
            {!addresses &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {addresses &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <header className="">
            <div className="mx-auto max-h-96 max-w-96"> 
                <div className="mx-auto" style={{position: "relative", width: "fit-content;"}}>
                    <img src={"https://ordinals.gorillapool.io/content/" + layer1ID} style={{position: "absolute", top: 0, left: 0}}/>
                    <img src={"https://ordinals.gorillapool.io/content/" + layer2ID} style={{position: "absolute", top: 0, left: 0}}/>
                    <img src={"https://ordinals.gorillapool.io/content/" + layer3ID} style={{position: "absolute", top: 0, left: 0}}/>
                    <img src={"https://ordinals.gorillapool.io/content/" + layer4ID} style={{position: "absolute", top: 0, left: 0}}/>
                    <img src={"https://ordinals.gorillapool.io/content/" + layer5ID} style={{position: "absolute", top: 0, left: 0}}/>
                </div>
            </div>
       </header>
      </div>
    </>
  );
};
