'use client'
import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardDescription, CardTitle } from "./../../components/styled/card"

interface HeaderProps {
  supply?: number;
  mintPrice?: number;
  floorPrice?: number;
}
interface ExchangeRateData {
    currency: string;
    rate: string;
    time: number;
}
export default function MarketHeader({ supply, mintPrice, floorPrice }: HeaderProps) {
  const [maxSupply, setMaxSupply] = useState<number>(supply || 0);
  const [startingPrice, setStartingPrice] = useState<number>(mintPrice || 0)
  const [currentPrice, setCurrentPrice] = useState<number>(floorPrice || 0)
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [exchangeRate, setExchangeRate] = useState<ExchangeRateData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  console.log("Rendering MarketHeader with floor price:", floorPrice);
  useEffect(() => {
    setMaxSupply(Number(supply))
  }, [supply]);

  useEffect(() => {
    setStartingPrice(mintPrice!)
  }, [mintPrice]);

  useEffect(() => {
    setCurrentPrice(floorPrice!)
  }, [floorPrice]);

  useEffect(() => {
    const startAsBitcoin = startingPrice * 100000000;
    if (startAsBitcoin && currentPrice) {
      const change = ((currentPrice - startAsBitcoin) / startAsBitcoin) * 100;
      setPercentageChange(change);
    } else {
      setPercentageChange(0);
    }
  }, [startingPrice, currentPrice]);

  useEffect(() => {
    const fetchExchangeRate = async () => {
        try {
            const response = await fetch('https://api.whatsonchain.com/v1/bsv/main/exchangerate');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data: ExchangeRateData = await response.json();
            setExchangeRate(data);
            setIsLoading(false);
        } catch (e) {
            setError(e instanceof Error ? e.message : 'An unknown error occurred');
            setIsLoading(false);
        }
    };

    fetchExchangeRate();
    }, []); // Empty dependency array means this effect will only run once after the initial render

	return (
		<div className="w-full flex flex-col gap-4">
			<div className="flex grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-4 mb-4">
				<Card>
					<CardHeader>
						<CardDescription>Supply</CardDescription>
						<CardTitle className="flex items-center justify-between">
							<div className="w-full flex items-center gap-2 bg-opacity-20 px-2 h-[24px] flex items-center rounded-lg text-sm whitespace-nowrap text-[#6CE9A6] bg-[#6CE9A6]">
								<div className='mx-auto'> { supply?.toString() }</div>
							</div>
						</CardTitle>
					</CardHeader>
				</Card>
				<Card>
					<CardHeader>
						<CardDescription>Mint Price</CardDescription>
						<CardTitle>{startingPrice && startingPrice !== 0.00000001 &&
								<>{startingPrice}</>
							}
							{startingPrice === 0.00000001 &&
								<>FREE</>
							}</CardTitle>
					</CardHeader>
				</Card>
				<Card>
					<CardHeader>
						<CardDescription>Floor Price</CardDescription>
						<CardTitle>{currentPrice? (currentPrice / 100000000).toString() : 0}</CardTitle>
					</CardHeader>
				</Card>
                <Card>
					<CardHeader>
						<CardDescription>Market Cap (BSV)</CardDescription>
						<CardTitle>
                            {((currentPrice * maxSupply) / 100000000).toLocaleString()} BSV
						</CardTitle>
					</CardHeader>
				</Card>
                <Card>
					<CardHeader>
						<CardDescription>Market Cap (USD)</CardDescription>
						<CardTitle>
                        {exchangeRate && 
                            <span className=''>
                                $ {Math.round(Number(exchangeRate.rate) * ((currentPrice * maxSupply) / 100000000)).toLocaleString()}
                            </span>
                        }

						</CardTitle>
					</CardHeader>
				</Card>
                <Card>
					<CardHeader>
						<CardDescription>Change</CardDescription>
						<CardTitle>
                            {percentageChange < 0 && 
                                <span className='text-red-400 text-md'> {percentageChange.toFixed(2) + '%'}</span>
                            }
                            {percentageChange === 0 && 
                                <span className='text-gray-400 text-md'> {percentageChange.toFixed(2) + '%'}</span>
                            }
                            {percentageChange > 0 && percentageChange < 1000 &&
                                <span className='text-green-400 text-md'> {percentageChange.toFixed(2) + '%'}</span>
                            }
                            {percentageChange > 0 && percentageChange > 1000 &&
                                <span className='text-green-400 text-md'> {percentageChange.toFixed(0) + '%'}</span>
                            }
						</CardTitle>
					</CardHeader>
				</Card>
			</div>
		</div>
	)
}
