'use client'
import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardDescription, CardTitle } from "./../../components/styled/card"

interface HeaderProps {
  initialRemainingSupply?: number;
  initialMaxSupply?: number;
  inputPrice?: number;
}

export default function Header({ initialRemainingSupply, initialMaxSupply, inputPrice }: HeaderProps) {
  const [remainingSupply, setRemainingSupply] = useState<number>(initialRemainingSupply || 0);
  const [maxSupply, setMaxSupply] = useState<number>(initialMaxSupply || 1000);
  const [price, setPrice] = useState<number>(inputPrice || -1)

  useEffect(() => {
    if (initialRemainingSupply === undefined) {
      const _supply = localStorage.getItem("remainingSupply");
      setRemainingSupply(Number(_supply));
    } else {
		setRemainingSupply(initialRemainingSupply)
	}
  }, [initialRemainingSupply]);

  useEffect(() => {
    if (initialMaxSupply === undefined) {
      const _max = localStorage.getItem("maxSupply");
      setMaxSupply(Number(_max));
    } else {
		setMaxSupply(initialMaxSupply)
	}
  }, [initialMaxSupply]);
  
  useEffect(() => {
    if (inputPrice === undefined ) {
      setPrice(Number(inputPrice));
    } else {
		setPrice(inputPrice)
	}
  }, [inputPrice]);
	return (
		<div className="w-full flex flex-col gap-4">
			<div className="flex grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
				<Card>
					<CardHeader>
						<CardDescription>Maxiumum Supply</CardDescription>
						<CardTitle className="flex items-center justify-between">
							<div className="w-full flex items-center gap-2 bg-opacity-20 px-2 h-[24px] flex items-center rounded-lg text-sm whitespace-nowrap text-[#6CE9A6] bg-[#6CE9A6]">
								<div className='mx-auto'> { maxSupply.toLocaleString() }</div>
							</div>
						</CardTitle>
					</CardHeader>
				</Card>
				<Card>
					<CardHeader>
						<CardDescription>Remaining</CardDescription>
						<CardTitle>{remainingSupply.toLocaleString()}</CardTitle>
					</CardHeader>
				</Card>
				<Card>
					<CardHeader>
						<CardDescription>Percentage Minted</CardDescription>
						<CardTitle>{(((maxSupply - remainingSupply) / maxSupply) * 100).toFixed(2)}%</CardTitle>
					</CardHeader>
				</Card>
				<Card>
					<CardHeader>
						<CardDescription>Price</CardDescription>
						<CardTitle>
							{price && price !== -1 &&
								<>{price  / 100000000}</>
							}
							{price === -1 &&
								<>FREE</>
							}
						</CardTitle>
					</CardHeader>
				</Card>
			</div>
		</div>
	)
}
