import React from 'react';

// Define the props interface
interface ProgressBarProps {
    progress: number;  // Progress as a percentage (0-100)
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
    const getColorClass = (): string => {
        if (progress <= 50) {
            return 'bg-green-500';
        } else if (progress <= 75) {
            return 'bg-yellow-500';
        } else if (progress <= 90) {
            return 'bg-orange-500';
        } else {
            return 'bg-red-500';
        }
    };

    return (
        <>
        <div className="text-xs">Minted: {progress.toFixed(2)}%</div>
        <div className="w-full p-1 shadow-md rounded bg-gray-800">
            <div
                className={`h-4 rounded transition-all duration-500 animate-pulse ${getColorClass()} shadow-lg`}
                style={{ width: `${progress}%` }}
            ></div>
        </div>
        </>
)};

export default ProgressBar;
