import React from 'react';
import { useContentType } from '../../utils/hooks/useContentType';
import LazyIframe from '../LazyIframe';

// Define the props type for the component
interface ContentRendererProps {
  item: {
    origin?: {
      outpoint?: string;
    };
  };
  index: number;
}

const ContentRenderer: React.FC<ContentRendererProps> = ({ item, index }) => {
  const url = `https://ordfs.network/content/${item?.origin?.outpoint || ''}`;
  const { contentType, loading } = useContentType(url);

  if (loading) {
    return <p>Loading...</p>; // Placeholder for loading state
  }

  if (contentType?.includes('image/svg+xml')) {
    return (
      <div className="w-full h-0 overflow-hidden relative" style={{ paddingBottom: '100%' }}>
        <LazyIframe src={url} title={`Content ${index}`}  />
      </div>
    );
  } else if (contentType?.startsWith('image/')) {
    return (
      <div className="w-full h-0 overflow-hidden relative" style={{ paddingBottom: '100%' }}>
        <img src={url} alt={`Content ${index}`} className="absolute top-0 left-0 w-full h-full object-contain" />
      </div>
    );
  } else if (contentType?.includes('text/html')) {
    return (
      <div className="w-full h-0 overflow-hidden relative" style={{ paddingBottom: '100%' }}>
        <iframe src={url} title={`Content ${index}`} className="absolute top-0 left-0 w-full h-full" />
      </div>
    );
  } else {
    return <p>{contentType}</p>; // Handle unknown or unsupported content types
  }
}

export default ContentRenderer;
