import { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import pandaIcon from "../assets/panda-icon.svg";
import { PandaConnectButton } from "../../../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from '../../../components/styled/PrimaryButton';
import Header from "../../../components/styled/Header";
import Footer from "../../../components/styled/Footer";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Modal from "../../../components/mint/WaitingModal";
import { ShruggrLib } from "../../../contracts/shruggr-lib";
import GalleryGrid from "../../../components/GalleryGrid";
import CoverPhotoIframe from "../../../components/CoverPhotoIframe";
import { collectionsData, CollectionItem } from "../../../constants/collectionsData"; // Import your constants

export const TwonksMint = () => {
  const wallet = usePandaWallet();
  const { name } = useParams<{ name: string }>(); // Get the name from the URL
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined);
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined);
  const [signer, setSigner] = useState<Signer | undefined>(undefined);
  const [initialSupply, setInitialSupply] = useState(0);
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [coverUrl, setCoverUrl] = useState<string>('');
  const [currentOrigin, setCurrentOrigin] = useState<string>('');
  const [priceInSatoshis, setPriceInSatoshis] = useState<number>(0);
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(10000);
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [collectionName, setCollectionName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finalTxId, setFinalTxId] = useState<string | null>('');
  const [message, setMessage] = useState<string | null>(null);
  const [signedMessage, setSignedMessage] = useState<SignedMessage | undefined>();

  useEffect(() => {
    console.log("URL Name:", name); // Check if name is received correctly
    // Find the collection item based on the name
    const collectionItem = collectionsData.find(item => item.mintLink.includes(name));
    console.log("Found Item Details:", collectionItem); // Check if collectionItem is found
    if (collectionItem) {
      setCurrentOrigin(collectionItem.origin);
      setCollectionName(collectionItem.name);
      setTotalSupply(collectionItem.supply);
      setCoverUrl(collectionItem.imageUrl);
      const PIS = parseFloat(collectionItem.mintPrice) * 100000000
      console.log({PIS})
      setPriceInSatoshis(PIS); // Convert to satoshis
    } else {
      console.error(`No collection found for name: ${name}`);
    }
  }, [name]);

  useEffect(() => {
    if (currentOrigin) {
      const url = `https://ordinals.gorillapool.io/api/txos/${currentOrigin}/`;
      axios.get(url).then((response) => {
        setCollectionName(response.data.origin.data.map.name);
      }).catch((error) => console.log({ error }));
    }
  }, [currentOrigin]);

  useEffect(() => {
    if (!wallet.connect) {
      return;
    }

    const connectWallet = async () => {
      try {
        const key = await wallet.connect();
        setPubKey(key);

        const signer = new PandaSigner(new OrdiProvider());
        setSigner(signer);

        const { isAuthenticated, error } = await signer.requestAuth();
        if (!isAuthenticated) {
          throw new Error(`Unauthenticated: ${error}`);
        }

        setConnectedPayAddress(await signer.getDefaultAddress());
        setConnectedOrdiAddress(await signer.getOrdAddress());
        await handleGetAddresses();
      } catch (error) {
        alert("Something went wrong signing in. " + error);
      }
    };

    connectWallet();
  }, [wallet]);

  const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try {
      const key = await wallet.connect();
      setPubKey(key);
      const signer = new PandaSigner(new OrdiProvider());
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth();
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`);
      }
      setConnectedPayAddress(await signer.getDefaultAddress());
      setConnectedOrdiAddress(await signer.getOrdAddress());
      await handleGetAddresses();
    } catch (error) {
      alert("Something went wrong signing in. " + error);
    }
  };


  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  return (
    <div className="App bg-dark relative isolate overflow-hidden text-white">
      <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
        <div className="flex-grow text-left items-center items-justify-center">
          <div><a href="/">← Go Back, Pussy</a></div>
        </div>
        <div>
          {!addresses &&
            <PandaConnectButton onClick={handleConnect} />
          }
          {addresses &&
            <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) - 5)}</div>
          }
        </div>
      </div>
      <div className="App-header h-full">
        <div>
          <Header
            initialRemainingSupply={remainingSupply}
            initialMaxSupply={totalSupply}
            inputPrice={priceInSatoshis}
            
          />
        </div>
        <div className="max-w-7xl">
          <div className="rounded-lg">
            <div className="flex-1 rounded-lg px-8 bg-dark">
              <div className="flex-1 md:grid md:grid-cols-5 my-2 rounded-lg p-8">
                <div className="flex items-center md:col-span-2">
                  <div className="bg-gray-100 rounded-xl ring-yellow-500 ring-2 overflow-hidden h-full">
                    {currentOrigin && <CoverPhotoIframe id={currentOrigin} /> }
                  </div>
                </div>
                <div className="flex-1 flex flex-col md:col-span-1"></div>
                <div className="flex-1 flex flex-col md:col-span-2 justify-between flex-grow py-12 px-2 lg:px-4 xl:px-8">
                  <section
                    aria-labelledby="summary-heading"
                    className="mt-16 w-full rounded-lg bg-gray-900 px-4 py-6 sm:p-4 lg:col-span-5 lg:mt-0 lg:p-8 ring-gray-500 ring-2"
                  >
                    <h2 id="summary-heading" className="text-lg font-medium">
                      {collectionName}
                    </h2>
                    <dl className="mt-6 space-y-4">
                      <div className="flex items-center justify-between">
                        <dt className="text-sm text-gray-200">Price</dt>
                        <dd className="text-sm font-medium text-gray-200">₿ {priceInSatoshis / 100000000}</dd>
                      </div>
                      <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                        <dt className="md:text-xs flex items-center text-sm text-gray-200">
                          <span>Mint Fee</span>
                        </dt>
                        <dd className="text-sm font-medium text-gray-200">{serviceFeeInSatoshis} <span className="text-xs">sats</span></dd>
                      </div>
                    </dl>
                    <div className="mt-6">
                      {!addresses &&
                        <PandaConnectButton onClick={handleConnect} />
                      }
                      {addresses &&
                        <>
                          <a href={`/listings/${currentOrigin}`} >
                            <Button variant="gradientSquare" className="w-full font-medium" style={{ color: "black" }}>
                              Trade
                            </Button>
                          </a>
                        </>
                      }
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentOrigin && 
      <GalleryGrid
        parentID={currentOrigin}
        address={connectedOrdiAddress ? connectedOrdiAddress.toString() : ""}
        collectionName={collectionName}
        totalItems={Number(totalSupply? totalSupply : 0)}
      />}
      <Footer />
    </div>
  );
};
