import { Button } from "../styled/PrimaryButton";

export default function MintWithUs() {
    return (
      <div className="bg-black">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-indigo-500 sm:text-4xl">
            Have A Collection To Mint?
          </h2>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href="/mint-request"
            >
                <Button variant="gradient"> Tell us more → </Button>
            </a>
          </div>
        </div>
      </div>
    )
  }
  