import React, { useState, useEffect, useCallback } from 'react'; // Import useCallback
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Addresses, usePandaWallet } from 'panda-wallet-provider';
import { Button } from '../../components/styled/PrimaryButton';
import { PandaConnectButton } from '../../components/PandaConnectButton';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import Footer from "../../components/styled/Footer";
import { CollectionItem, collectionsData } from '../../constants/collectionsData';
import { Link } from 'react-router-dom';
import LazyIframe from '../../components/LazyIframe';
import { RecGCFull } from '../../contracts/rec-gc-full';
import MarketHeader from '../../components/styled/MarketHeader';
import ContentRenderer from '../../components/helpers/ContentRenderer';
import IframeContent from '../../components/HeaderCircleIframe';
interface Item {
  // Define properties based on the actual item structure you expect
  id: string;
  name: string;
  origin: {
    outpoint: string;
    data: {
      data:{},
      map: {
        subTypeData: {
          rank: number;
        };
        name: string;
      };
    };
  };
  outpoint:string;
  data:{list:{price:number;};};
  // Add other relevant fields
}

const Listings: React.FC = () => {
  const [headerItem, setHeaderItem] = useState({});
  const wallet = usePandaWallet();
  const [items, setItems] = useState<Item[]>([]);
  const [error, setError] = useState<string>('');
  const { id } = useParams<{ id: string }>(); // Using useParams here
  const [collectionId, setCollectionId] = useState<string>('')
  const [txid, setTxid] = useState<string>('');
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [collectionName, setCollectionName] = useState<string>('');
  const [holdersCount, setHoldersCount] = useState<string>("0");
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [collectionDetails, setCollectionDetails] = useState<CollectionItem | null>(null)
  const [pageLimit, setPageLimit] = useState<number>(100);
  const [pageOffset, setPageOffset] = useState<number>(0)
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [floorPrice, setFloorPrice] = useState<number>(0)

  useEffect(() => {
    const fetchHolders = async () => {
      try {
        if(collectionId){
          let response = await axios.get(`https://ordinals.gorillapool.io/api/collections/${collectionId}/holders`);
          setHoldersCount(response.data.length);
        }
      } catch (error) {
        
      }
    }
    if(collectionId && 
      (collectionId !== 
        '1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0')){
      fetchHolders();
    } else if(collectionId === 
      '1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0'){
        setHoldersCount("foxes broke GorillaPool's holders count ¯\\_(ツ)_/¯")
    }
    

  }, [collectionId])

  const fetchFloor = useCallback(async () => {
    if(collectionId){
     const q = {
        map: {
          subTypeData: {
            collectionId: collectionId,
          },
        },
      };

      const encodedQuery = btoa(JSON.stringify(q));
      const collectionMarketUrl = `https://ordinals.gorillapool.io/api/market?sort=price&dir=ASC&limit=1&q=${encodedQuery}`;
      console.log({collectionMarketUrl})
      try {
        const response = await axios.get(collectionMarketUrl);
        console.log({response})
        setFloorPrice(response.data[0].data.list.price);
        if (response.data && response.data.length > 0) {
          console.log("Floor Response data:", response.data[0].data.list.price);
          setFloorPrice(response.data[0].data.list.price);
        } else {
          setFloorPrice(0);  // Ensure floor price is reset if no data is returned
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch floor price.');
        setFloorPrice(0);  // Reset floor price on error
      }
    }
  }, [collectionId]);

  useEffect(() => {
    fetchFloor();
  }, [collectionId, fetchFloor])


  const fetchItems = useCallback(async () => { // Use useCallback to memoize the function
    if (typeof id !== 'string') return; // Ensure id is a string.
    console.log({id})
    const q = {
      map: {
        subTypeData: {
          collectionId: collectionId,
        },
      },
    };

    const encodedQuery = btoa(JSON.stringify(q));
    const collectionMarketUrl = `https://ordinals.gorillapool.io/api/market?sort=price&dir=ASC&limit=${pageLimit}&offset=${pageOffset}&q=${encodedQuery}`;
    console.log({collectionMarketUrl})
    try {
      const response = await axios.get(collectionMarketUrl);
      setItems(response.data); // Replace with actual response data structure.
      if(response?.data?.length < pageOffset){setDisableNext(true)}
      
      console.log("Logging items returned from api", response.data);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch items.');
    }
  }, [id, collectionId, pageOffset]); // Dependency array includes id

  useEffect(() => {
    let details = collectionsData.find((i) => i.origin === id);
    if (!details) {
      let name = decodeURIComponent(id);
      console.log({id}, {name})
      details = collectionsData.find((i) => i.name === name);
    }
    console.log({details})
    if (details) {
      setCollectionId(id);  // Set collectionId here
      setCollectionDetails(details);    // Set collectionDetails here
    } else {
      console.error("No collection details found for provided ID");
      setError("No collection details found for provided ID");
    }
}, [id]); // Dependency only on id

useEffect(() => {
  console.log("if " + collectionId + " will fetch")
  if (collectionId) {   // Ensure collectionId is set before fetching
    fetchItems();
  }
}, [collectionId, fetchItems]);  // Depend on collectionId and fetchItems

  const purchaseOrdinal = async (event: React.FormEvent, outpoint: string, marketplaceRate?: string, marketplaceAddress?: string) => {
    event.preventDefault();

    const purchaseParams = {
      outpoint,
      marketplaceRate: marketplaceRate ? parseFloat(marketplaceRate) : undefined,
      marketplaceAddress: marketplaceAddress || undefined,
    };

    try {
      const transactionId = await wallet.purchaseOrdinal(purchaseParams);
      setTxid(transactionId!);
      setError('');
    } catch (err) {
      console.error(err);
      setError('Failed to purchase ordinal.');
    }
  };

  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try{
      const key = await wallet.connect();
      const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth()
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`)
      }
      setConnectedPayAddress(await signer.getDefaultAddress())  // <----
      setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
    } catch(error){
      alert("Something went wrong sigining in. " + error)
    }
  };

  const handlePageChange = (newPage: React.SetStateAction<number>) => {
    setPageOffset(newPage);
    setItems([]);
  };

  const getRarity = (rank: number, totalItems: number): string => {
    const percentile = (rank / totalItems) * 100;
    if (percentile <= 1) return 'Exotic';
    if (percentile <= 4) return 'Legendary';
    if (percentile <= 10) return 'Epic';
    if (percentile <= 25) return 'Rare';
    if (percentile <= 50) return 'Uncommon';
    return 'Common';
  };

  const getRarityStyle = (rarity: string): string => {
    switch (rarity) {
      case 'Exotic':
        return 'bg-gradient-to-r from-purple-400 via-blue-300 to-blue-500 text-white';
      case 'Legendary':
        return 'bg-gradient-to-r from-yellow-400 to-yellow-600 text-white';
      case 'Epic':
        return 'bg-gradient-to-r from-red-400 to-red-600 text-white';
      case 'Rare':
        return 'bg-gradient-to-r from-green-400 to-green-600 text-white';
      case 'Uncommon':
        return 'bg-gradient-to-r from-blue-400 to-blue-600 text-white';
      case 'Common':
        return 'bg-gradient-to-r from-gray-400 to-gray-600 text-white';
      default:
        return 'bg-gray-400 text-white';
    }
  };

  const getRarityRing = (rarity: string): string => {
    switch (rarity) {
      case 'Exotic':
        return 'ring-1 ring-purple-500';
      case 'Legendary':
        return 'ring-1 ring-yellow-500 text-white';
      case 'Epic':
        return 'ring-1 ring-red-500 text-white';
      case 'Rare':
        return 'ring-1 ring-green-500 text-white';
      case 'Uncommon':
        return 'ring-1 ring-blue-500 text-white';
      case 'Common':
        return 'ring-1 ring-gray-500 text-white';
      default:
        return 'bg-gray-400 text-white';
    }
  };

  return (
    <div className="App bg-dark relative isolate overflow-hidden text-white min-h-screen">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> <a href="/">← Go Back, Pussy </a></div>
          </div>
          <div className="">
            {!addresses &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {addresses &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <div className=" h-full px-4">
          
        <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-12">
          <div className="flex items-start space-x-5">
            <div className="flex-shrink-0">
              <div className="relative rounded-full max-w-32 max-h-32">
                <IframeContent id={id} />
                <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
              </div>
            </div>
            {/*
              Use vertical padding to simulate center alignment when both lines of text are one line,
              but preserve the same layout if the text wraps without making the image jump around.
            */}
            <div className="pt-1.5">
            <h1 className="text-2xl font-bold text-left">{collectionDetails?.name}</h1>
              <p className="text-sm font-medium text-gray-300 text-left">
                Holders: {' '} {holdersCount}
                <a href="#" className="text-gray-900">
                  
                </a>{' '}
                {/* on <time dateTime="2020-08-25">April 11, 2024</time> */}
              </p>
            </div>
          </div>
          <div className="hidden md:inline-block pr-6 mt-6 pt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <Link to={`${collectionDetails?.mintLink}`}>
                <Button
                variant="greenOutlineSquare"
                className="inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-green-300 shadow-sm  hover:bg-gray-900"
              >
                View Inventory
              </Button>
            </Link>
          </div>
        </div>
        <div  className='text-right pb-2'> 

        
        </div>
        <MarketHeader 
              supply={collectionDetails?.supply} 
              mintPrice={Number(collectionDetails?.mintPrice)} 
              floorPrice={floorPrice} />

<div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 h-full'>
          {items.map((item, index) => (
            <div key={index} className={`overflow-hidden rounded-xl ring-2 pb-2 ${getRarityRing(getRarity(item?.origin?.data?.map?.subTypeData?.rank, collectionDetails?.supply ? Number(collectionDetails?.supply) : 1))}`}>
              <div className="relative w-full" style={{ paddingBottom: '100%' }}>
                <div className="absolute top-0 left-0 w-full h-full">
                  <ContentRenderer item={item} index={index} />
                </div>
              </div>
              {item?.origin?.data?.map?.subTypeData?.rank && (
                <div className="p-2">
                  <div className="text-gray-100 text-center"> {item?.origin?.data?.map?.name} </div>
                  <div className='md:flex w-full pt-2'>
                    <div className="text-white text-center py-1 w-full">Rank: {item?.origin?.data?.map?.subTypeData?.rank}</div>
                    <div className="text-white text-center w-full">
                      <p className={`xs:text-xs sm:text-sm lg:text-md text-center mb-2 py-1 px-6 rounded-full ${getRarityStyle(getRarity(item?.origin?.data?.map?.subTypeData?.rank, collectionDetails?.supply ? Number(collectionDetails?.supply) : 1))}`}>
                        {getRarity(item?.origin?.data?.map?.subTypeData?.rank, collectionDetails?.supply ? Number(collectionDetails?.supply) : 1)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className='flex space-x-2 pt-2 px-2'> 
                <div className='w-full'> 
                  <Button onClick={(e) => purchaseOrdinal(e, item?.outpoint, "0.03", "1QD3avnNJbdsdoYEkvSgzuRYL2toqAVUEq")} variant={"gradientSquare"} className='w-full font-medium text-gray-900'>
                    ₿ {item.data?.list?.price / 100000000}
                  </Button>
                </div>
                <div className='w-full'>
                  <a href={`https://alpha.1satordinals.com/outpoint/${item?.outpoint}/inscription`} target="_blank" rel="noopener noreferrer">
                    <Button variant={"iceGradient"} className='w-full font-medium text-gray-100 rounded-lg'>
                      View Details
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          ))}
          {error && <p>{error}</p>}
          {txid && <p>Transaction ID: {txid}</p>}
        </div>
        <div className="flex justify-center">
            <Button onClick={() => handlePageChange(pageOffset - pageLimit)} disabled={pageOffset === 1}>Prev</Button>
            <Button onClick={() => handlePageChange(pageOffset + pageLimit)} disabled={(items.length < 100 || disableNext) }>Next</Button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Listings;
