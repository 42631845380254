import React, { useEffect, useState } from 'react';

interface IframeContentProps {
  id: string;
}

const IframeContent: React.FC<IframeContentProps> = ({ id }) => {
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    const checkContentType = async () => {
      try {
        const response = await fetch(`https://ordfs.network/content/${id}`, {
          method: 'HEAD',
        });
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.startsWith('image/')) {
          setIsImage(true);
        }
      } catch (error) {
        console.error('Error checking content type:', error);
      }
    };

    checkContentType();
  }, [id]);

  return (
    <div className="w-24 h-24 rounded-full overflow-hidden">
      {isImage ? (
        <img
          src={`https://ordfs.network/content/${id}`}
          alt={`Content ${id}`}
          className="w-full h-full object-contain"
        />
      ) : (
        <iframe
          className="w-full h-full"
          style={{ border: 'none' }}
          src={`https://ordfs.network/content/${id}`}
          title={`Content ${id}`}
        />
      )}
    </div>
  );
};

export default IframeContent;
