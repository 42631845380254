import React, { useState, useEffect } from 'react';
import Header from '../components/styled/Header';
import { Button } from '../components/styled/PrimaryButton';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import { PandaConnectButton } from "../components/PandaConnectButton";
import artifact from '../../artifacts/rec-gc-full.json';
import { RecGCFull } from "../contracts/rec-gc-full";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { ShruggrLib } from '../contracts/shruggr-lib';
import { LayerData, RecGCBase } from "../contracts/rec-gc-base";
import axios from 'axios';

interface RoyaltyPayout {
  type: string;
  destination: string;
  percentage: number;
}

interface Layer {
  urls: string[];
}

const Deploy: React.FC = () => {
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [name, setName] = useState('');
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [description, setDescription] = useState('');
  const [satoshis, setSatoshis] = useState(0);
  const [maxUnits, setMaxUnits] = useState(0);
  const [photo, setPhoto] = useState<File | null>(null);
  const [royaltyPayouts, setRoyaltyPayouts] = useState<RoyaltyPayout[]>([]);
  const [layers, setLayers] = useState<Layer[]>([]);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(10000);
  const [priceInSatoshis, setPriceInSatoshis] = useState<number>(10000);
  const [currentOrigin, setCurrentOrigin] = useState<string>('');

  const layers2: LayerData[] = [
    //Layers
    new LayerData(
      toByteString("Accessory", true),
      [
        //Layer Items
        {
          "outpoint": toByteString("d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Venom",
            "description": "venom accessory",
            "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Purple",
            "description": "purple accessory",
            "more arbitrary info": "purple accessory"
          }), true),
        },
        {
          "outpoint": toByteString("538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Pink",
            "description": "pink accessory",
            "more arbitrary info": "pink accessory"
          }), true),
        },
      ]
    ),
    new LayerData(
      toByteString("Clothes", true),
      [
        //2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0
        {
          "outpoint": toByteString("2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Yellow Clothes",
            "description": "clothes that are yellow",
            "more arbitrary info": "more yellow"
          }), true),
        },
        {
          "outpoint": toByteString("f1be2424f057ab6e410b8bbcc715d8e5b80597e407a157e00730b3acb1cb0f90_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Light Blue Clothes",
            "description": "clothes that are Light Blue",
            "more arbitrary info": "more Light Blue"
          }), true),
        },
        {
          "outpoint": toByteString("513b9109f7a129886f040f88d4500bafb1df511d4abdf53064ad02a0846fa4c7_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Green Clothes",
            "description": "clothes that are Green",
            "more arbitrary info": "more Green"
          }), true),
        },
        // ...
      ],
    ),
    new LayerData(
      toByteString("Eyes", true),
      [
        // {
        //   "outpoint": toByteString("2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0", true),
        //   "data": toByteString(JSON.stringify({
        //     "name": "Light Blue",
        //     "description": "eyes that are light blue",
        //     "more arbitrary info": "more light blue"
        //   }), true),
        // },
        {
          "outpoint": toByteString("0e2e17c03c02f616c675ed04685dc54d03de059ca1223d0c8f3bda407b080492_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Blue Eyes",
            "description": "eyes that are Blue",
            "more arbitrary info": "more Blue"
          }), true),
        },
        {
          "outpoint": toByteString("2d6dc8cad9a7537c44bc267022923182e8790b396727d07effaf654969442485_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Red Eyes",
            "description": "eyes that are Red",
            "more arbitrary info": "more Red"
          }), true),
        },
        // ...
      ],
    )
  ]
  useEffect(() => {
    // Load data from localStorage
    const formData = localStorage.getItem('formData');
    if (formData) {
      const parsedData = JSON.parse(formData);
      setName(parsedData.name);
      setDescription(parsedData.description);
      setSatoshis(parsedData.satoshis);
      setMaxUnits(parsedData.maxUnits);
      setRoyaltyPayouts(parsedData.royaltyPayouts);
      setLayers(parsedData.layers);
      setPhotoUrl(parsedData.photoUrl)
      setServiceFeeInSatoshis(parsedData.serviceFeeInSatoshis)
      setPriceInSatoshis(parsedData.priceInSatoshis)
    }
  }, []);

  useEffect(() => {
    // Save data to localStorage
    const formData = {
      name,
      description,
      satoshis,
      maxUnits,
      royaltyPayouts,
      layers,
      photoUrl
    };
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [name, 
        description, 
        satoshis,  
        maxUnits, 
        royaltyPayouts, 
        layers, 
        photoUrl, 
        serviceFeeInSatoshis, 
        priceInSatoshis]);

  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setPhoto(file);
      const url = URL.createObjectURL(file);
      setPhotoUrl(url);
    }
  };
  

  const addRoyaltyPayout = () => {
    setRoyaltyPayouts([...royaltyPayouts, { type: 'address', destination: '', percentage: 0 }]);
  };

  const removeRoyaltyPayout = (index: number) => {
    setRoyaltyPayouts(royaltyPayouts.filter((_, i) => i !== index));
  };
  

  const updateRoyaltyPayoutDestination = (index: number, destination: string) => {
    const updatedPayouts = [...royaltyPayouts];
    updatedPayouts[index].destination = destination;
    setRoyaltyPayouts(updatedPayouts);
  };
  
  
  const updateRoyaltyPayoutPercentage = (index: number, percentage: number) => {
    const updatedPayouts = [...royaltyPayouts];
    updatedPayouts[index].percentage = percentage;
    setRoyaltyPayouts(updatedPayouts);
  };
  

  const addLayer = () => {
    if (layers.length < 15) {
      setLayers([...layers, { urls: [''] }]);
    }
  };

  const removeLayer = (layerIndex: number) => {
    setLayers(layers.filter((_, index) => index !== layerIndex));
  };

  const updateLayerUrl = (layerIndex: number, urlIndex: number, url: string) => {
    const updatedLayers = [...layers];
    updatedLayers[layerIndex].urls[urlIndex] = url;
    setLayers(updatedLayers);
  };
  
  const addLayerUrl = (layerIndex: number) => {
    const updatedLayers = [...layers];
    updatedLayers[layerIndex].urls.push('');
    setLayers(updatedLayers);
  };
  
  const removeLayerUrl = (layerIndex: number, urlIndex: number) => {
    const updatedLayers = [...layers];
    updatedLayers[layerIndex].urls = updatedLayers[layerIndex].urls.filter((_, i) => i !== urlIndex);
    setLayers(updatedLayers);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Construct NFT contract data here
    console.log('Publishing NFT...');
  };

  const handleDeploy = async () => {

    const payoutScript = bsv.Script.buildPublicKeyHashOut(connectedPayAddress!)
    console.log("Payout Script:", payoutScript);
    const payout =
      new bsv.Transaction.Output({ script: payoutScript, satoshis: priceInSatoshis })
    console.log("Payout:", payout);
    const payoutHex = payout.toBufferWriter()
      .toBuffer()
      .toString('hex');
    console.log("PayoutHex:", payoutHex);

    //TODO: Derive this from the layers object but make it match the CollectionItem Spec
    //this _subTypeData is arbitrary but searchable. 
    //Include any data that the collection would want to be queried by
    // I should build this autmatically from the layers information. 
    const _subTypeData = {
      description: "This is a test class",
      layers: [
        //Layers
        {
          name: "Accessory",
          assets: [
            //Layer Items
            {
              "outpoint": "d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0",
              "name": "Venom",
              "description": "venom accessory",
              "more arbitrary info": "venom accessory"
            },
            {
              "outpoint": "6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0",
              "name": "Purple",
              "description": "purple accessory",
              "more arbitrary info": "purple accessory"
            },
            {
              "outpoint": "0538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0",
              "name": "Pink",
              "description": "Pink accessory",
              "more arbitrary info": "Pink accessory"
            }
          ]
        },
        {
          name: "Clothes",
          assets: [
            {
              "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
              "name": "Yellow Clothes",
              "description": "Sun clothes",
              "more arbitrary info": "Yellow Clothes"
            },
            // ...
          ],
        },
        {
          name: "Eyes",
          assets: [
            {
              "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
              "name": "Yellow Eyes",
              "description": "Sun colored Eyes",
              "more arbitrary info": "This is a cool Eyes."
            },
            // ...
          ],
        }
      ]
    }


    const _royalties = [
      { type: "address", destination: "1EqiQhoQJdyNEvpmX9u2bCKwMLdBRNz1q", percentage: "0.00" }
    ]
    const metadata = {
      app: 'RareDropper',
      type: 'ord',
      contractId: artifact.md5,
      name: "We So Testy v2",
      subType: 'collection',
      subTypeData: JSON.stringify(_subTypeData),
      royalties: JSON.stringify(_royalties),
    };

    console.log(JSON.stringify(metadata));
    const map = new bsv.Script('');
    map.add(Buffer.from("SET"));
    for (const [key, value] of Object.entries(metadata)) {
      map.add(Buffer.from(key))
      map.add(Buffer.from(value))
    }

    const response = await fetch(photoUrl!);
    const data = await response.arrayBuffer();

    // console.log("Content:", )
    const inscriptionScript = ShruggrLib.buildInscriptionScript({
      content: Buffer.from(data),
      contentType: 'image/png'
    },
      {
        "1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5": map.toBuffer()
      }
    )
    const deployable = new RecGCFull(
                          toByteString(metadata.name, true), //the ordinals name 
                          RecGCBase.buildLayerNames(layers2), //constructed names for the layers
                          RecGCBase.buildLayers(layers2),  // the layers object
                          7n, // number of NFTs to mint into the collection
                          0n, // This is a difficuty number in bits. Set to 0 for no POW. 4 of these difficuly = 1 POW20
                          toByteString(payoutHex), //the address & amount to pay when minting
                          0n, //the current height of the chain to enforce the starting point on locks 
                          0n, // number of blocks to remain locked 
                          0n, // number of satoshis that must be locked
                          BigInt(inscriptionScript.length)) // number of bytes in the inscription

    console.log("Deployable:", deployable);
    await deployable.connect(signer!)

    let deployResponse = await deployable.inscribeWithNoOp(inscriptionScript)
    const rawTx = deployResponse.toBuffer();
    console.log(deployResponse.id, rawTx);
    setTimeout(async () => {
      axios.get(`https://ordinals.gorillapool.io/api/tx/${deployResponse.id}/submit`)
    }, 5000)
    setCurrentOrigin(deployResponse.id + "_0")
  };

  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try{
      const key = await wallet.connect();
      setPubKey(key);
      const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth()
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`)
      }
      setConnectedPayAddress(await signer.getDefaultAddress())  // <----
      setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
    } catch(error){
      alert("Something went wrong sigining in. " + error)
    }
  };

  return (
    <>
    <div className="App">
      <Header></Header>
      <header className="App-header">
      <form className="flex-1 p-12" onSubmit={handleSubmit}>
        <div className='flex-1 space-y-4'>
          <div className='text-left'> <label className='text-left'> Collection Name</label> </div>
          <div><input type="text" className='p-2 w-full border border-input bg-black hover:bg-accent hover:text-accent-foreground rounded-xl border-green-500' value={name} onChange={(e) => setName(e.target.value)} placeholder='My Awesome Collection' /></div>
        </div>
        <div className=''>
          <div className='text-left'> <label className='text-left'> Description</label> </div>
          <textarea className='p-2 border w-full border-input bg-black hover:bg-accent hover:text-accent-foreground rounded-xl border-green-500' value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description of your collection"></textarea>
        </div>
        <div className='flex w-full pt-4'>
          <div className='items-center justify-items-center'> <div className='p-4'> <label className=''> Price in Satoshis</label> </div></div>
          <div className='flex-grow py-2'> <input className='p-2 border w-full border-input bg-black hover:bg-accent hover:text-accent-foreground rounded-xl border-green-500' type="number" value={satoshis} onChange={(e) => setSatoshis(Number(e.target.value))} placeholder="100000000" /></div>
        </div>
        <div className='flex w-full pt-4'>
          <div className='items-center justify-items-center'> <div className='p-4'> <label className=''> Maxiumum Units</label> </div></div>
          <div className='flex-grow py-2'> <input className='p-2 border w-full border-input bg-black hover:bg-accent hover:text-accent-foreground rounded-xl border-green-500' type="number" value={maxUnits} onChange={(e) => setMaxUnits(Number(e.target.value))} placeholder="4269" /></div>
        </div>
        <div className='p-2 flex-1 w-full'> 
          <div className='flex'> 
            <div className='my-4 flex-grow text-left'> Royalties</div> 
            <div> 
              <button className='p-2 text-3xl text-green-500' type="button" onClick={addRoyaltyPayout}>
                +
              </button>
            </div>
          </div>
          {royaltyPayouts.map((payout, index) => (
            <div className='flex w-full space-x-2' key={index}>
              <div className='w-full'> 
                <input
                  type="text"
                  className='p-2 border w-full border-input bg-black hover:bg-accent hover:text-accent-foreground rounded-xl border-green-500'
                  value={payout.destination}
                  onChange={(e) => updateRoyaltyPayoutDestination(index, e.target.value)}
                  placeholder="Destination Address"
                  />
              </div>
              <div className='w-full flex'>
                <input
                  type="number"
                  className='p-2 border w-full border-input bg-black hover:bg-accent hover:text-accent-foreground rounded-xl border-green-500'
                  value={payout.percentage}
                  onChange={(e) => updateRoyaltyPayoutPercentage(index, Number(e.target.value))}
                  placeholder="Percentage"
                  />
                  <div className='p-2'> %</div>
              </div>
              <button
                className='text-red-500'
                type="button"
                onClick={() => removeRoyaltyPayout(index)}
              >
                x
              </button>
            </div>
          ))}
        </div>
        <div className='p-2 py-4'>
        {layers.map((layer, layerIndex) => (
          <div key={layerIndex} className="mt-4 rounded-xl">
            <div className="mb-2 flex items-center">
              <div className='flex-grow text-left'> 
                <button
                  className="text-red-500 p-2"
                  type="button"
                  onClick={() => removeLayer(layerIndex)}
                >
                  x
                </button>
                <label className="text-lg font-semibold">Layer {layerIndex + 1}</label>
              </div>
              <button
              className=" text-sm mt-2 border border-input bg-background hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
              type="button"
              onClick={() => addLayerUrl(layerIndex)}
            >
              + variation
            </button>
            </div>
            {layer.urls.map((url, urlIndex) => (
              <div key={urlIndex} className="flex items-center space-2 p-2">
                <input
                  type="text"
                  className="p-2 border w-full border-input bg-black hover:bg-accent hover:text-accent-foreground rounded-xl border-green-500"
                  value={url}
                  onChange={(e) => updateLayerUrl(layerIndex, urlIndex, e.target.value)}
                  placeholder={`URL ${urlIndex + 1}`}
                />
                <button
                  className="text-red-500 p-2"
                  type="button"
                  onClick={() => removeLayerUrl(layerIndex, urlIndex)}
                >
                  X
                </button>
                {url && (
                  <img src={url} alt={`Layer ${layerIndex + 1} URL ${urlIndex + 1} Preview`} className="w-16 h-16 object-cover" height="16px" width="16px" />
                )}
              </div>
            ))}
          </div>
        ))}
        <button
          className="mt-4 border border-input border-green-500 bg-background hover:bg-accent hover:text-accent-foreground p-2 rounded-md"
          type="button"
          onClick={addLayer}
        >
          Add Layer
        </button>

        </div>
        <div className='py-4'>
          <div> <label className=''> Collection Cover Photo</label> </div>
          <div className=''> 
            <label className="cursor-pointer border border-input border-green-500 bg-background hover:bg-accent hover:text-accent-foreground inline-block p-2 rounded-md">
              <span>Select File</span>
              <input type="file" onChange={handlePhotoChange} className="hidden" />
            </label>
            {photoUrl && (
              <div className="mt-4">
                <img src={photoUrl} alt="Collection Cover" className="w-full h-auto max-h-96 max-w-96 mx-auto" />
              </div>
            )}
          </div>
        </div>
        <Button type="submit" variant='gradient'>Publish</Button>
      </form>
      </header>
    </div>
    </>
  );
};

export default Deploy;
