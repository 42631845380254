import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from './styled/PrimaryButton';
import { Link } from 'react-router-dom';
import PendingTransactionCheck from './mint/PendingTransactionsCheck';

type GalleryGridProps = {
  parentID: string;
  address: string;
  collectionName: string;
};

type ImageData = {
  url: string;
  name: string;
};

type ApiResponse = {
  origin: {
    outpoint: string;
    data: {
      map: {
        name: string;
      } // Ensure this path is correct based on your actual API structure
    };
  };
}[];

const GalleryGrid: React.FC<GalleryGridProps> = ({ parentID, address, collectionName }) => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [myItemsCount, setMyItemsCount] = useState(0);
  const [holdersCount, setHoldersCount] = useState(0);
  const itemsPerPage = 1000; // Set the number of items per page

  useEffect(() => {
    const fetchImages = async () => {
      const offset = (currentPage - 1) * itemsPerPage;
      try {
        const response = await axios.post<ApiResponse>(
          `https://ordinals.gorillapool.io/api/txos/address/${address}/unspent?limit=${itemsPerPage}&offset=${offset}`,
          {
            "map": {
              "subTypeData": {
                "collectionId": "1611d956f397caa80b56bc148b4bce87b54f39b234aeca4668b4d5a7785eb9fa_0"
              }
            }
          }
        );
        console.log("First item in the response From Fetch Images", response.data[0])
        const newImages = response.data.map(item => ({
          url: `https://ordfs.network/content/${item.origin.outpoint}`,
          name: item.origin.data.map.name // Adapt based on actual response data structure
        }));
        if(newImages.length ===0 ){
          setDisableNext(true);
        } else {
          setImages(newImages);
          setDisableNext(false);
        }
        
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [currentPage, address]); // Add currentPage to dependency array

  // useEffect(() => {
  //   const fetchHolders = async (address:string, parentID :string) => {
  //     try {
  //       console.log("ParentID & Address at fetch holders: " + parentID + " " + address);
  //       let response = await axios.get(`https://ordinals.gorillapool.io/api/collections/${parentID}/holders`);
  //       console.log("Holders Count: ", response.data.length)
  //       setHoldersCount(response.data.length);
  //       let returnValue = response.data.find((item: { address: string; }) => {return item.address === address})
  //       console.log("My Count", returnValue)
  //       setMyItemsCount(returnValue?.amt ? returnValue.amt : 0)
        
  //     } catch (error) {
        
  //     }
  //   }
  //   if(address && parentID){
  //     fetchHolders(address, parentID);
  //   }
      

  // }, [parentID, address])

  const handlePageChange = (newPage: React.SetStateAction<number>) => {
    setCurrentPage(newPage);
  };

  return (
    <>
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-12">
          <div className="flex items-start space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <img
                  className="h-16 w-16 rounded-full mx-8"
                  src={`https://ordfs.network/${parentID}`}
                  alt=""
                />
                <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
              </div>
            </div>
            {/*
              Use vertical padding to simulate center alignment when both lines of text are one line,
              but preserve the same layout if the text wraps without making the image jump around.
            */}
            <div className="pt-1.5">
            <h1 className="text-2xl font-bold text-left">My {collectionName}: {`¯\\_(ツ)_/¯`}{/*myItemsCount*/} </h1>
              <p className="text-sm font-medium text-gray-300 text-left">
                {/* Holders {' '} */}
                <a href="#" className="text-gray-900">
                  
                </a>{' '}
                {/* {holdersCount} */}{` we broke GorillaPool's holder counts`} 
              </p>
            </div>
          </div>
          <div className="xs:flex-1 md:flex lg:pr-6 mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <div className=''><PendingTransactionCheck ordAddress={address} /></div>
            <div className='w-full'>
              <Link to={`/listings/${parentID}`}>
                <Button
                variant="secondarySquare"
                className="inline-flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50"
              >
                Market
              </Button>
            </Link>
            </div>
            
          </div>
        </div>
    {images.length > 0 && 
      <>
        
        <div className="grid grid-cols-3 sm:grid-cols-5 xl:grid-cols-8 gap-4 mx-4 px-4">
          {images.map((image, index) => (
            <div key={index} className="rounded-xl overflow-hidden bg-gray-900 ring-1 ring-gray-500 m-2"> {/* Padding-top for 1:1 aspect ratio */}
              <div key={index} className="w-full  rounded-xl relative" style={{ paddingTop: '100%' }}> {/* Padding-top for 1:1 aspect ratio */}
              
                <img
                  src={image.url}
                  title={`Content ${index}`}
                  alt={`Content ${index}`}
                  className="absolute top-0 left-0 w-full h-full"
                />
              </div>
              <div className="p-2"> 
                <div className="p-2 text-xs md:text-md">{image.name}</div>
                <a href={`${image.url}`} target="_blank" className='pt-4 mt-4 md:text-sm'>
                  <Button variant={"gradientSquare"} className='text-xs w-full font-medium text-gray-900 '>👀 VIEW</Button> 
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
            <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Prev</Button>
            <Button onClick={() => handlePageChange(currentPage + 1)} disabled={(images.length < 100 || disableNext) }>Next</Button>
        </div>
      </>
      }
    </>

  );
};

export default GalleryGrid;
