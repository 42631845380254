import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import pandaIcon from "../assets/panda-icon.svg";
import { PandaConnectButton } from "../../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { LayerData, RecGCBase } from "../../contracts/rec-gc-base";
import { RecGCFull } from "../../contracts/rec-gc-full";
import artifact from '../../../artifacts/rec-gc-full.json';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from './../../components/styled/PrimaryButton';
import Header from "../../components/styled/Header";
import Footer from "../../components/styled/Footer";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Modal from "../../components/mint/WaitingForFaces";
import { ShruggrLib } from "../../contracts/shruggr-lib";
import { env } from "process";
import GalleryGrid from "../../components/GalleryGrid";

RecGCFull.loadArtifact(artifact);

export const ZeroFace = () => {
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [initialSupply, setInitialSupply] = useState(0)
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [coverUrl, setCoverUrl] = useState<string>('')
  const [currentOrigin, setCurrentOrigin] = useState<string>('cc274a2cc28d88f24a7442443b5542fefe95e486f81f4261e0649401eec30c5d_0');
  const [currentLocation, setCurrentLocation] = useState<string>('cc274a2cc28d88f24a7442443b5542fefe95e486f81f4261e0649401eec30c5d_0');
  const [priceInSatoshis, setPriceInSatoshis] = useState<number>(1000000)
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(10000)
  const [totalSupply, setTotalSupply] = useState<number>(0)
  const [collectionName, setCollectionName] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [finalTxId, setFinalTxId] = useState<string | null>(''); // State to store the mint response
  const [message, setMessage] = useState<string | null>(null)
  const location = useLocation();
  const [signedMessage, setSignedMessage] = useState<
    SignedMessage | undefined
  >();

  //URGENT: Change Cover Photo
  const coverPhotoUrl = 'https://slavettes-layers.s3.amazonaws.com/misc/testy-face-black-on-aqua.png';
  
  
  useEffect(() => {
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(location.search);
    // Get the value of the 'origin' parameter
    const originQueryParam = queryParams.get('origin');
    if (originQueryParam) {
      // Set the state to the query string value
      console.log("Found Origin:", originQueryParam)
      setCurrentOrigin(originQueryParam);
    }
  }, [location]);

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo);

      if(contractInstance.payOut !== toByteString("")){
        const price = 
          bsv.Transaction.Output.fromBufferReader(
            new bsv.encoding.BufferReader(Buffer.from(contractInstance.payOut, 'hex'))
        )
        console.log(price.satoshis);
      }
      console.log("setting remaining supply", Number(contractInstance.supply))
      localStorage.setItem("remainingSupply", contractInstance.supply.toString());
      setRemainingSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin, currentLocation, finalTxId])


  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("setting total supply", contractInstance.supply.toString())
      localStorage.setItem("maxSupply", contractInstance.supply.toString());
      setTotalSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin])

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/txos/${currentOrigin}/`;
    axios.get(url).then((response) => {
      setCollectionName(response.data.origin.data.map.name);
    }).catch((error) => console.log({ error }));
  }, [])

//   useEffect(() => {
    
//     if (!wallet.connect) {
//       return;
//     }
  
//     const connectWallet = async () => {
//       try {
//         const key = await wallet.connect();
//         setPubKey(key);
  
//         const signer = new PandaSigner(new OrdiProvider()); // <---- use `PandaSigner`
//         setSigner(signer);
  
//         const { isAuthenticated, error } = await signer.requestAuth();
//         if (!isAuthenticated) {
//           throw new Error(`Unauthenticated: ${error}`);
//         }
  
//         setConnectedPayAddress(await signer.getDefaultAddress());  // <----
//         setConnectedOrdiAddress(await signer.getOrdAddress());     // <----
//         await handleGetAddresses();
//       } catch (error) {
//         alert("Something went wrong signing in. " + error);
//       }
//     };
  
//     connectWallet();
  
// }, [wallet]);
  
  const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
  const layers: LayerData[] = [
    new LayerData(
      toByteString("Background", true),
      [
        {
          "outpoint": toByteString("063dfe105b7ac81208b1b56ea121e605fc442f692bb93903a30b2ac273fd930c_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Aqua"
          }), true)
        },
        {
          "outpoint": toByteString("d14dcd19dc2ec004f875ae004ad29262cc0aea2ad835d4da1aef532269c859a4_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Peach"
          }), true),
        },
        {
            "outpoint": toByteString("c803c7be7d00933002ddf16e61884b3030be4f72f4f4dc52be55c10cf3a17d66_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Lime"
            }), true),
        },
        {
            "outpoint": toByteString("e00df560b5c414cce7a090e0cc39817dbb18c5fa0990badc255c00b2525d513c_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Yellow"
            }), true),
          },
          {
              "outpoint": toByteString("ba84778b6d88c9fbdc0020e53fe73f4ac9865e4fac31fce7bab5e5c5e05359db_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Black"
              }), true),
          },
          {
              "outpoint": toByteString("7848406431042b009e2b3386a0c4342a82d90647ab5abb1e62e6cd79f425a862_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Orange"
              }), true),
          },
          {
              "outpoint": toByteString("07772b6aa15035645d5ed864c7e73d02e8e860c241469b57d3f7fd367a256803_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Green"
              }), true),
          },
          {
              "outpoint": toByteString("03b1cccfa743dcb71fe67967156048d50c2592912e064f21dceb56c1b3de2c4d_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Royal Blue"
              }), true),
          },
          {
              "outpoint": toByteString("312574f3d49557b8cca9c7eb4b574da89cb87e04ac1094f9811bd0c4a3e21ff0_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Teal"
              }), true),
          },
          {
              "outpoint": toByteString("b33919a91adf746ba33197b042a6f2d4e976af36f3af109a4bbad89ba82f6f67_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Magenta"
              }), true),
          },
          {
              "outpoint": toByteString("ea9c379d6b554caa566ca2d192ccbe4d6b94083406f8601dc1a7d241c676a283_0", true),
              "data": toByteString(JSON.stringify({
                "name": "White"
              }), true),
          },
          {
              "outpoint": toByteString("3e0812a33f66ed1b1555ba509bdb966b63e6a5c23876928dc2bf7c4577a2476e_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Red"
              }), true),
          },
          {
              "outpoint": toByteString("c58952be1b10cffb4297323405f27d728c29d95caf3a412358528c65ed1e4abb_0", true),
              "data": toByteString(JSON.stringify({
                "name": "Purple"
              }), true),
          }
      ]
    ),
    new LayerData(
        toByteString("Head", true),
        [
          {
            "outpoint": toByteString("8effb6541fbc7ea71f1d02a0d1576c47a3d39cf0f53e1d68762a7761ec85883a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Yellow"
            }), true),
          },
          {
            "outpoint": toByteString("5abe27ad59f92a710b147d61109e7eb27b269f28105e1c012dd83e2e90861cd0_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Red"
            }), true),
          },
          {
            "outpoint": toByteString("80d80a2473e92788934e923e7a9c037cd13cb1f1effd892b551b8d4416385b19_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Purple"
            }), true),
          },
          {
            "outpoint": toByteString("7a23387c44450b24d75b5bc659ecd01e78402d1aac0169695a9947f674e154af_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Black"
            }), true),
          },
          {
            "outpoint": toByteString("e63151045880aca9d28e03a7fb694ba5341ab070ef6e88fe2e5384dbbb9c37c6_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Aqua"
            }), true),
          },
          {
            "outpoint": toByteString("ff1abccefba228a303ec82fe8b9e78051e492e3e7a0610247de579402ffc57c1_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Red"
            }), true),
          },
          {
            "outpoint": toByteString("342d576c8a10a73c9d20b289918f7b15f50760f0bcc2c9baa22ac29a18ff8a21_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Black"
            }), true),
          },
          {
            "outpoint": toByteString("776b5bd75968d1b332524915163f77a778a502442cbc6d5807aa818d77994373_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Green"
            }), true),
          },
          {
            "outpoint": toByteString("cca6d1bb5bef84ff66cabc97cd3ebe688f3fc606dda3c6a03fcf246de18cdb3b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Purple"
            }), true),
          },
          {
            "outpoint": toByteString("234e2bf0262c0df98218f7862775b39197d7637db7bdfd0d6885d3924e45bd65_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Lime"
            }), true),
          },
          {
            "outpoint": toByteString("30aeec0f6d175bcd6189f3886d4ec576f6d169e19ed515d6657723f8e57d3736_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Green"
            }), true),
          },
          {
            "outpoint": toByteString("b5a5325ad7b9c5c62dbf92c518ead63d4a226a21b00ae7e8ec20986f4758f8c0_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chancery Orange"
            }), true),
          },
          {
            "outpoint": toByteString("f539b8ced1e8cdf75e355c9dc26d752b5208457b87f00617e536c7d28aaa2e58_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chancery Purple"
            }), true),
          },
          {
            "outpoint": toByteString("b75450edb1fd7986f92bac9481014130a8759f859a367b7f32e67c5cb0c37b0b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Orange"
            }), true),
          },
          {
            "outpoint": toByteString("7d8a5d8302c2751e3db70d0bba9d990c9eb8193d6dd1cb379fe60c1d5ec09cb6_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Magenta"
            }), true),
          },
          {
            "outpoint": toByteString("7a7412e96edf4c04513685c45d82857f6a375b78efb3f825b4a3e6292dc0b43c_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell White"
            }), true),
          },
          {
            "outpoint": toByteString("7c4713204bbcbeb2809c0f9d7da7714e6880542a751ef4b1cfb68f8a4b1487b9_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Royal Blue"
            }), true),
          },
          {
            "outpoint": toByteString("fce6475151c53a0f9a617e8badbbbf98240c791424a7df0caec071b86e10233a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Magenta"
            }), true),
          },
          {
            "outpoint": toByteString("88dc0055e79fe87824ca4f8aba6f59d493f2efc4d6e4acfa1207785f5f795d73_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Teal"
            }), true),
          },
          {
            "outpoint": toByteString("0cae9dbb13eb52aaa08bb23e5ed56f83a409dc16ebb14053af2d3461aed87b45_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Magenta"
            }), true),
          },
          {
            "outpoint": toByteString("836906747b0b1c05385d76360b1569976184ded602e7f22d096173b9ed27ead7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Peach"
            }), true),
          },
          {
            "outpoint": toByteString("3f00026ccbdf5b3200286b3351fd1a94c02798b5b66a165ce44a431675b7ecb7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic White"
            }), true),
          },
          {
            "outpoint": toByteString("a46387ed85f0df18e3fd5276cdb560d662b89b7696ad84787f5c2ce6637a1a3a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Red"
            }), true),
          },
          {
            "outpoint": toByteString("671f921b028426b8d6295f4086573425dcdbcd9171a79ecb79107b9a3629da18_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Teal"
            }), true),
          },
          {
            "outpoint": toByteString("1678ecdd3635611fda3f483706ac837be54eb7cdf23655e7f12fa56fbe4de112_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Magenta"
            }), true),
          },
          {
            "outpoint": toByteString("d463597b66aba2cc96b45f85cff244339eb8d0581d6fe19e6764d6da2cee20e7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Royal Blue"
            }), true),
          },
          {
            "outpoint": toByteString("6fd5d499344a1265c088619bcaf70d289702c55e6a21ed59f2afe3024d020d36_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Orange"
            }), true),
          },
          {
            "outpoint": toByteString("93a2e5e42b2fd5ac04befa7664bc0c3bc85d67389e35f7a426d75638f7ee0a28_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Teal"
            }), true),
          },
          {
            "outpoint": toByteString("6d3aa09d708fe7c633bd47bdf7d150ae2a762b75b6717ae6297151fd7eee47d4_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Aqua"
            }), true),
          },
          {
            "outpoint": toByteString("bcafe822f96585df55070991ac98bae3cad4b10c70e0bb3b8f1d503a45906399_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Yellow"
            }), true),
          },
          {
            "outpoint": toByteString("02c4147a57d18f076fb00d45f0ec35f14889f660308db57960134dec022d6554_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Royal Blue"
            }), true),
          },
          {
            "outpoint": toByteString("1aa43b2e0f53607f9501b67916835836e1ce8a434e3044fe780f078a872c3462_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Aqua"
            }), true),
          },
          {
            "outpoint": toByteString("af78a085d2751af6526873767b36c91c1c3f44b452f0989cd1eebd7f6919a537_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance White"
            }), true),
          },
          {
            "outpoint": toByteString("3f00026ccbdf5b3200286b3351fd1a94c02798b5b66a165ce44a431675b7ecb7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic White"
            }), true),
          },
          {
            "outpoint": toByteString("ee546be92d4adf2e3a08445646fc213b5fc35771099f71f8efdf68b3c0db82af_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Lime"
            }), true),
          },
          {
            "outpoint": toByteString("af0e8e731c5da99ef48ff90cc06a6f3394a3dd6c16278e0f44188608594bf969_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Peach"
            }), true),
          },
          {
            "outpoint": toByteString("517d561768b9862a60d469692fa25fbd26be1d8d8d1acd64db05702b80984681_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus White"
            }), true),
          },
          {
            "outpoint": toByteString("d3c65ee2a485be1345dc879b3ed0d5348ffba11e3224466a1dabd4c638da6472_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Lime"
            }), true),
          },
          {
            "outpoint": toByteString("832560e66104cc6b88d53988ef536fb61e438ec45901d853022ddf529a80ad51_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Peach"
            }), true),
          },
          {
            "outpoint": toByteString("0bb53fec5bcacfc33819159a48c7a587874d7b69f115b731caea436b87998ccb_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Yellow"
            }), true),
          },
          {
            "outpoint": toByteString("961fdd3d05650e2dbc7acfd27c9a640030f7a89f69cf8afad5476b02f775b37f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Purple"
            }), true),
          },
          {
            "outpoint": toByteString("c29a637bf8ee921b6f27b2a7eed6400d1a1ce6bdff1fd3213e8e8791141f5572_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Aqua"
            }), true),
          },
          {
            "outpoint": toByteString("d4c1db27d4d2690715c38bf86cba456e04ceb299d406c35de401fe47c0c144a7_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Orange"
            }), true),
          },
          {
            "outpoint": toByteString("6cfb521e580adab806bc5d74d137eac23b457f838c2830758479bcdfc99b9616_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Snell Black"
            }), true),
          },
          {
            "outpoint": toByteString("b030f792c8fb2ecc43c3c191ba4242ef78baf6e2ca667f7709cb3369b661aaa9_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Green"
            }), true),
          },
          {
            "outpoint": toByteString("62232600f273e0fa5061e09fc1cf52260b1951687d7bbae042b46afd3e91cf2d_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Comic Black"
            }), true),
          },
          {
            "outpoint": toByteString("0e9ab0b7ace4b271e550c78174dccbbca8202ad1d7f1b475c61f695651e4712a_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Green"
            }), true),
          },
          {
            "outpoint": toByteString("9a875cbcf00b536464135ea6177dd4c78a0ed5448bec184c185c2665ed2b6434_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Papyrus Red"
            }), true),
          },
          {
            "outpoint": toByteString("46a26369e5ccec3aa6ed4cdac4cde9144f9d4a59e1486452a56d9a10fcf64440_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Teal"
            }), true),
          },
          {
            "outpoint": toByteString("34cd56c2eb818e70a87023851e291395eb37689d8aaea4dd9c3d1cf5ca046335_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Chance Yellow"
            }), true),
          }
        ]
    ),
    new LayerData(
        toByteString("Mouth", true),
        [
            {
                "outpoint": toByteString("470559d4c64875c9f8c72ec2702876c1b5118409761a3181bb9b0eb595bffdc0_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Lime",
                }), true),
            },
            {
                "outpoint": toByteString("7f0cba0430e459e9e001f229a4795dafefd32a19696f7770da90b8b0f571a219_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Royal Blue",
                }), true),
            },
            {
                "outpoint": toByteString("00b98e1380d698cfc4bd208d6066d290adacc07d096133d89e51cdb8e627726b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Green",
                }), true),
            },
            {
                "outpoint": toByteString("79a9cad29c7317db7f8d312c417a0d16303e2fd11b2fd09d22a4277a02573c3b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Green"
                }), true),
            },
            {
                "outpoint": toByteString("470005c36fe7a5b09e93bff64f59ccc033aaa56e0fac5acc994f5ef36b74d94a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Teal"
                }), true),
            },
            {
                "outpoint": toByteString("14575e9b7607c08f4816410e73a4d9f0b8d07053d4dc321b40996c97070bb3f6_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Orange"
                }), true),
            },
            {
                "outpoint": toByteString("62baba353a06defff5fadef6d09277ae68c3e959d5651242cd1cb14b1366e92b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Teal"
                }), true),
            },
            {
                "outpoint": toByteString("bb14747e2c808d6400480c5a0a4b734bf45744d1e346a6c10a23ccfb02bc5af0_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Yellow"
                }), true),
            },
            {
                "outpoint": toByteString("ba16afb11fcf2070c344bcb694c9020c656660f1cc57251dfbedb79239fbcf59_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Royal Blue"
                }), true),
            },
            {
                "outpoint": toByteString("0797978ad37defabfa75ebb7c47cb23d1d2da5280c5323cf6f0a65f7e3b6047c_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Red"
                }), true),
            },
            {
                "outpoint": toByteString("3bdd14efe5648296d744965b6e73c335cffd95160f7c82c6f7566352d1be38e5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Teal"
                }), true),
            },
            {
                "outpoint": toByteString("7d4e386d519687b9215c42b9ab49dfec7d796b7c2be293755a62afdd855cec11_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Green"
                }), true),
            },
            {
                "outpoint": toByteString("e072412b353780975fda690782358f9229ceb7e265e2ee96a9e21669f365ccee_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Red"
                }), true),
            },
            {
                "outpoint": toByteString("44ab96c7f8d13cab12f66514b6fb8081db79da5b2e6abe06845015f3fc26aeb3_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Yellow"
                }), true),
            },
            {
                "outpoint": toByteString("5b326e93b28d81cf68f09443a8add714fa8f19a4847bb99bf00820658309311f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash White"
                }), true),
            },
            {
                "outpoint": toByteString("20083f6bb1f5031c112b18359040610bbae76204a7fc179209d9adadaaf0a02f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda White"
                }), true),
            },
            {
                "outpoint": toByteString("5b50d717b318e8328bfff4112e44fc7c523cfd25968072c8cdc91d2d08fdc73c_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Purple"
                }), true),
            },
            {
                "outpoint": toByteString("f11c0efe2e01dadfca4a92f442888dafe995415ab52a3fa646d3827a9e20b0a7_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Magenta"
                }), true),
            },
            {
                "outpoint": toByteString("c22e0a153522b99946634e5daa5bea0476edb23f56cee4b3962cd9a71f9061c0_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 White"
                }), true),
            },
            {
                "outpoint": toByteString("9d84b87b3447d122e03474b5e40bf6f9c27fc7e8052c90aa744b37553e7753d6_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Magenta"
                }), true),
            },
            {
                "outpoint": toByteString("4e760c56f25afe512847cfc3f250afebece559e26ae3ec7679e3713a9428bac8_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Orange"
                }), true),
            },
            {
                "outpoint": toByteString("c528d9e646a1aa22732353e0904fe8ee4cca7ce6520c6437aed7da1ff94e3698_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Royal Blue"
                }), true),
            },
            {
                "outpoint": toByteString("abfd3bc9951f62f8da719ccd77816a0008ef584aabb66e9af5a49ecdb0dbc1b9_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Purple"
                }), true),
            },
            {
                "outpoint": toByteString("24c1bde3a494a800f88814d9aed6c8ea445d2d874597788e8bbd48cd2d4d9526_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Peach"
                }), true),
            },
            {
                "outpoint": toByteString("64edb61b2286ade93deef1c7ce01a1cd5f11e90e44e578cce11f4c6b779fbeaa_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Peach"
                }), true),
            },
            {
                "outpoint": toByteString("5449085e101b0064afffeffcfc6139235aa13837c54b274461ccdb1cfb9dd270_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Peach"
                }), true),
            },
            {
                "outpoint": toByteString("8a80f5d7f76deaad4eafaf7a3976489f98e7bc5d3dd88f4d744820f3a092af65_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Teal"
                }), true),
            },
            {
                "outpoint": toByteString("823a2848198bd3c8eacfcad730e83829fafef946a8e05b53eeb72726cf1a039f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Black"
                }), true),
            },
            {
                "outpoint": toByteString("37cda3882cb703b6cf02c9c7d81cd1590abe37c4a8e46c81cb6da815582aef38_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Lime"
                }), true),
            },
            {
                "outpoint": toByteString("65bda8989dba5bd8926786d13ba9aebe33c12c5b809bb0f2b01a877dc795dc5e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Yellow"
                }), true),
            },
            {
                "outpoint": toByteString("fa27baea682173fcf493f23dd06fec5463097f8babea8ca69c4d85c983bd388f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Aqua"
                }), true),
            },
            {
                "outpoint": toByteString("25335382b55540c18381aeee7011498bc2b3fe8ff4d3608a3a539f60c684c4cf_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Black"
                }), true),
            },
            {
                "outpoint": toByteString("f82533d874724d9446c8e56553dcded569b8e39e3ace2ffe3f2b6c353f2d77ce_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Black"
                }), true),
            },
            {
                "outpoint": toByteString("19ea82fb20e768ad97092f4c5f9a33e265e0f6de9aabda1a4b6de419391268a7_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Orange"
                }), true),
            },
            {
                "outpoint": toByteString("a56d1d1322a86a43bc498eb99f68fefbac348154c261abe532bc70197a143236_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "0 Aqua"
                }), true),
            },
            {
                "outpoint": toByteString("1d9cd8d9c65e9e0de7f068438cd64c2261f3dd5330b9999fa637826c0dc0cc46_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Red"
                }), true),
            },
            {
                "outpoint": toByteString("74f9581584d32d36c43ed960f78f933c5d9d5bf5fe07eafd3607c6c2811a713b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Lime"
                }), true),
            },
            {
                "outpoint": toByteString("e27791a5b3f647aa62a7e90911f17c9b8126f0746e50b37dface2534625c64c3_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Tilda Purple"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Left Eye", true),
        [
            {
                "outpoint": toByteString("82e943e775ed2b83f571c349ec3c0561ef01e2642bce35ed1218055073e1760a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Aqua"
                }), true),
            },
            {
                "outpoint": toByteString("fbe8a2382101026386eb2c44d4c54fe04b314b42489ec59c6293f40e99ab7b90_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Teal"
                }), true),
            },
            {
                "outpoint": toByteString("d83557d0cc11bf3dbc02f636af5e8d2b6efa47f9c67addf1aac2b88f613bbb93_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Green"
                }), true),
            },
            {
                "outpoint": toByteString("8c1660e6cdeb898b20080828838cc42aa99ddeba83d5f88426794cf62a85fe5f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Lime"
                }), true),
            },
            {
                "outpoint": toByteString("8d0214b880ae1103badb0ab67b9c66ff84475af53fb97528dd47c1b8a16c826e_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Purple"
                }), true),
            },
            {
                "outpoint": toByteString("ba0c25b9c73c9c0caed42d422c409e056970ea81a56b883794195107b4b095aa_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Peach"
                }), true),
            },
            {
                "outpoint": toByteString("4099e73b5687150ad78769ff2aee0e122440e8f17c67105d00b846e0472a5660_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Black"
                }), true),
            },
            {
                "outpoint": toByteString("8e8833fd70cbed552c8a3d0519d789f6d261743b84454f3ed7e6929887f67919_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Red"
                }), true),
            },
            {
                "outpoint": toByteString("16ac2b63e6de450b6c0cde362619d4c0ce955a0ebda7bed6809537d757f18b06_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Orange"
                }), true),
            },
            {
                "outpoint": toByteString("637a18a52d227f01fd1e5d00d15e1420d1e268bbc1cf3f03cdaeb2f141c75e7d_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Magenta"
                }), true),
            },
            {
                "outpoint": toByteString("2993c373b5f595b48f013548f5c85a16dad47651371aaed64cd793e2d53cfb33_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Royal Blue"
                }), true),
            },
            {
                "outpoint": toByteString("6db1be91e39068fd518e32364bc069ca040d5e63c3a3f878c80f8dd5e4f92151_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Yellow"
                }), true),
            },
            {
                "outpoint": toByteString("7c8f620420ab13a4d2ebde12c8795348fe7589b0f820d28b64247cdb2c09ba44_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash White"
                }), true),
            },
            {
                "outpoint": toByteString("1fde9b34ed5d74dd0364fcdd355716dbf49daf9e25ae6f5f6843881b900a87a0_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Peach"
                }), true),
            },
            {
                "outpoint": toByteString("6892db0918984c35b8cbf22cc9a0289919657d7c0f0b8c5bcd27ae4bdea3b5aa_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Yellow"
                }), true),
            },
            {
                "outpoint": toByteString("93f90683fefbc2adde649538aeb03b856e5a33c56950eb8fa0c6cdd2ccd9dddb_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Magenta"
                }), true),
            },
            {
                "outpoint": toByteString("67ebd1236b3740e25dbb3793ca204284747a611e90740ff92c933a3a61d310e5_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Orange"
                }), true),
            },
            {
                "outpoint": toByteString("43139cde64ca4cc71ea1fd45ea12c267bdbdf6c4baf127c7e3213bb5f9b5f5e3_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Green"
                }), true),
            },
            {
                "outpoint": toByteString("7873ffc3fa08a8070a1751008029d85d6e0892352ece78c22419659d2eb7067f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Lime"
                }), true),
            },
            {
                "outpoint": toByteString("9b68a1914b402df50fdf3266f34b6634543808af298bbb2a39871d0b00df917f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X White"
                }), true),
            },
            {
                "outpoint": toByteString("af3351f3f302451318c74e7235b056d95816fbac10cd35a16cf2f37f717663bd_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Purple"
                }), true),
            },
            {
                "outpoint": toByteString("02144d1290eb332f59495efda92391ab31913ee86c7d237e371cbaf6a4b721b6_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Red"
                }), true),
            },
            {
                "outpoint": toByteString("2b23032c55473489fd51479449986886dcab8328d51d42828d5d9674862ac46c_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Royal Blue"
                }), true),
            },
            {
                "outpoint": toByteString("cf12df612c5b590d92219ab3689a60b83c1797ee8459920f3d9631ccff1cf578_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Aqua"
                }), true),
            },
            {
                "outpoint": toByteString("18cf0442e589cef9791c77cc87a9931fc7040107a61e619ab8358e2d908be726_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Teal"
                }), true),
            }
        ]
    ),
    new LayerData(
        toByteString("Right Eye", true),
        [
            {
                "outpoint": toByteString("b65f274981ba3cc60f898ab208144aa503ad14b4877275987d149466ac9ac2ac_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Yellow"
                }), true),
            },
            {
                "outpoint": toByteString("6f44df5127723603d91217c3fc438228ea995cf734dc936c108a3428a3e98cf9_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Purple"
                }), true),
            },
            {
                "outpoint": toByteString("c8aec5123eac26db913228b3ef57073a088657e37f11cb685baebba9fb9b5357_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Teal"
                }), true),
            },
            {
                "outpoint": toByteString("462694241ab2e350c318ab3f5ba8b656576033d1d1a7cfe390c744e4b2253f46_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Magenta"
                }), true),
            },
            {
                "outpoint": toByteString("b1d8a67a616ef6453b21c2e0ade00de2f311be3f269d8b5645ec7fe7114f0487_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Red"
                }), true),
            },
            {
                "outpoint": toByteString("f12f00ea343283230dbe5592af3a4cbad1dfa242c6815902ad6e516588078092_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Green"
                }), true),
            },
            {
                "outpoint": toByteString("3463edf1d9bfe4062273b68080201c00e8c1982e16d6984ce09dcc46ffa34317_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Royal Blue"
                }), true),
            },
            {
                "outpoint": toByteString("f4d609207f4e1ead745bd647aee6cf39da1e0a6476efc94431a10c87d0671547_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Teal"
                }), true),
            },
            {
                "outpoint": toByteString("ae4eba430f2203c4f8ffdc8611453e8e1e5a8d4852fbac2eb2714d11477bf02b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash White"
                }), true),
            },
            {
                "outpoint": toByteString("9bc32b6b6e473d50bc4237c86a694a34a25aeb3f5cddc1e93f7668612337ad3b_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X White"
                }), true),
            },
            {
                "outpoint": toByteString("1a5c2221eceb90dd4790b1c55b3061370c2776db7f7e44ca9e1140a0368fd255_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Green"
                }), true),
            },
            {
                "outpoint": toByteString("bc52f4893cf318afdf8ad2c95550e0293fe32fa07794cea6de5333536ec59ec8_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Orange"
                }), true),
            },
            {
                "outpoint": toByteString("bc68756c265a51d5a9e1b7bf7724c5a5c6db2b36b690853d8f18fff227c6f8d8_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Yellow"
                }), true),
            },
            {
                "outpoint": toByteString("2a5d134aebb5beca4f1d4392ddc851c712e199c7d6746e140db06e9f6f4d6707_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Magenta"
                }), true),
            },
            {
                "outpoint": toByteString("5356284370e26b011b1e0a2c87be082af8684b362b2b8184d1b0526b87b09667_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Purple"
                }), true),
            },
            {
                "outpoint": toByteString("6244fe203a85267448675d4d66d9e1c1f6a017dc4c819e16a61e36ab5827c146_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Peach"
                }), true),
            },
            {
                "outpoint": toByteString("f3c2a2ceb8151f4ece207d47bf2d4c499d973e9028a8934c534d2c2f4a36e414_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Royal Blue"
                }), true),
            },
            {
                "outpoint": toByteString("1bd43d7b5a709b9256070121c095ca47c9b778a44b9ec20f49a8089ec523df37_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Peach"
                }), true),
            },
            {
                "outpoint": toByteString("f5e5a9524b3329891501558849027499cc01229730a9de2f677e622b0caa026f_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Black"
                }), true),
            },
            {
                "outpoint": toByteString("aded3799d692bfcd53ea0951438ce0c165a5458af987a4ffae319b7848697e9c_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Aqua"
                }), true),
            },
            {
                "outpoint": toByteString("f60c24fa99dfde76b6efdf42b5a36c4319575d2763f90f8a9246eb6198cc2510_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Orange"
                }), true),
            },
            {
                "outpoint": toByteString("1d15a0b4b6bee549ad983c4b255cf59eb7d8072be506c065b65428389101273a_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Teal"
                }), true),
            },
            {
                "outpoint": toByteString("60aa2e376f9e35cef7e59ad6d9f30f83530e4387c0486222dc3e6aa967cc0a29_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "Dash Lime"
                }), true),
            },
            {
                "outpoint": toByteString("f7223e70f1d75eac72a7af8ec0c15796d01df0c17cecdfbcc223e2658a2fcbef_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Red"
                }), true),
            },
            {
                "outpoint": toByteString("2aed0cd30b35894f85658aa781bc175703043dd76e1c2248bea705259ce78ff2_0", true),
                "data": toByteString(JSON.stringify({
                    "name": "X Lime"
                }), true),
            }
        ]
    )
  ]

  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try{
      const key = await wallet.connect();
      setPubKey(key);
      const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth()
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`)
      }
      setConnectedPayAddress(await signer.getDefaultAddress())  // <----
      setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
      await handleGetAddresses();
    } catch(error){
      alert("Something went wrong sigining in. " + error)
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false)
    setFinalTxId(null)
  }

  //TODO: Add a hook to get the contract details from the origin 1 time if theyre not populated 
  //      and save them in initalSupply, etc... 

  const handleDeploy = async () => {
    //URGENT: Set up Yours wallet to receive payment
    const payoutScript = bsv.Script.buildPublicKeyHashOut(connectedPayAddress!)
    console.log("Payout Script:", payoutScript);
    const payout =
      new bsv.Transaction.Output({ script: payoutScript, satoshis: priceInSatoshis })
    console.log("Payout:", payout);
    const payoutHex = payout.toBufferWriter()
      .toBuffer()
      .toString('hex');
    console.log("PayoutHex:", payoutHex);

    //TODO: Derive this from the layers object but make it match the CollectionItem Spec
    //this _subTypeData is arbitrary but searchable. 
    //Include any data that the collection would want to be queried by
    // I should build this autmatically from the layers information. 
    //URGENT: Change the description
    const _subTypeData = {
      description: "0Face",
      attributes: [
        // //Layers
        // {
        //   name: "Accessory",
        //   assets: [
        //     //Layer Items
        //     {
        //       "outpoint": "d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0",
        //       "name": "Venom",
        //       "description": "venom accessory",
        //       "more arbitrary info": "venom accessory"
        //     },
        //     {
        //       "outpoint": "6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0",
        //       "name": "Purple",
        //       "description": "purple accessory",
        //       "more arbitrary info": "purple accessory"
        //     },
        //     {
        //       "outpoint": "0538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0",
        //       "name": "Pink",
        //       "description": "Pink accessory",
        //       "more arbitrary info": "Pink accessory"
        //     }
        //   ]
        // },
        // {
        //   name: "Clothes",
        //   assets: [
        //     {
        //       "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
        //       "name": "Yellow Clothes",
        //       "description": "Sun clothes",
        //       "more arbitrary info": "Yellow Clothes"
        //     },
        //     // ...
        //   ],
        // },
        // {
        //   name: "Eyes",
        //   assets: [
        //     {
        //       "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
        //       "name": "Yellow Eyes",
        //       "description": "Sun colored Eyes",
        //       "more arbitrary info": "This is a cool Eyes."
        //     },
        //     // ...
        //   ],
        // }
      ]
    }

    //URGENT: Set up Yours wallet to receive royalties
    //set up to tardians-live-wallet
    const _royalties = [
      { type: "address", destination: "1QD3avnNJbdsdoYEkvSgzuRYL2toqAVUEq", percentage: "0.03" }
    ]

    //URGENT: Change Collection Name
    const metadata = {
      app: 'Bubblemint',
      type: 'ord',
      contractId: artifact.md5,
      name: "0Face",
      subType: 'collection',
      subTypeData: JSON.stringify(_subTypeData),
      royalties: JSON.stringify(_royalties),
    };

    console.log(JSON.stringify(metadata));
    const map = new bsv.Script('');
    map.add(Buffer.from("SET"));
    for (const [key, value] of Object.entries(metadata)) {
      map.add(Buffer.from(key))
      map.add(Buffer.from(value))
    }

    const response = await fetch(coverPhotoUrl);
    const data = await response.arrayBuffer();

    // console.log("Content:", )
    const inscriptionScript = ShruggrLib.buildInscriptionScript({
      content: Buffer.from(data),
      contentType: 'image/png'
    },
      {
        "1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5": map.toBuffer()
      }
    )

    //URGENT: Change total supply & other factors   
    const deployable = new RecGCFull(
                          toByteString(metadata.name, true), //the ordinals name 
                          RecGCBase.buildLayerNames(layers), //constructed names for the layers
                          RecGCBase.buildLayers(layers),  // the layers object
                          100000n, // number of NFTs to mint into the collection
                          0n, // This is a difficuty number in bits. Set to 0 for no POW. 4 of these difficuly = 1 POW20
                          toByteString(payoutHex), //the address & amount to pay when minting
                          0n, //the current height of the chain to enforce the starting point on locks 
                          0n, // number of blocks to remain locked 
                          0n, // number of satoshis that must be locked
                          BigInt(inscriptionScript.length)) // number of bytes in the inscription

    console.log("Deployable:", deployable);
    await deployable.connect(signer!)

    let deployResponse = await deployable.inscribeWithNoOp(inscriptionScript)
    const rawTx = deployResponse.toBuffer();
    console.log(deployResponse.id, rawTx);
    setTimeout(async () => {
      axios.get(`https://ordinals.gorillapool.io/api/tx/${deployResponse.id}/submit`)
    }, 5000)
    setCurrentOrigin(deployResponse.id + "_0")
  };

  const handleMint = async () => {
    setMessage(null);
    setIsModalOpen(true);
    try {
      setFinalTxId('');
      await wallet.getPaymentUtxos()
      await wallet.getBalance();
      const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
      const response = await axios.get(url);
      console.log("This is the the response to Latest", response.data);
      if (response.data.spend && response.data.spend !== "") {
        //This is the signal that the mint is complete. 
        console.log("spend is not null");
        setMessage("Try refreshing the balance in yours wallet by clicking balance display")
        return;
      }
      console.log("setting current location", response.data.outpoint);
      setCurrentLocation(response.data.outpoint)
      // const inscPrefix = Buffer.from('0063036f726451', 'hex')

      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("Contract Instance initialized...", contractInstance.layers);
      setRemainingSupply(Number(contractInstance.supply));
      localStorage.setItem("remainingSupply", Number(contractInstance.supply).toString());

      let serviceFeeOutput = new bsv.Transaction.Output({
        script: bsv.Script.fromAddress(new bsv.Address("1QD3avnNJbdsdoYEkvSgzuRYL2toqAVUEq")),
        satoshis: 1000
      })

      await contractInstance.connect(signer!)
      const nonce = toByteString('');
      const selectionData = contractInstance.buildSelectionData(layers, nonce);
      console.log({ selectionData });
      const buyerScript = bsv.Script.buildPublicKeyHashOut(connectedOrdiAddress!)
      setMessage("Signing");
      const mintResponse = await contractInstance.methods.mint(
        nonce, // user generated random bytestring. If the difficulty is higher than 0 this needs to be something that when hashed with the previous txid (Little Endian) it creates the output with the specified proof of work. 
        toByteString(buyerScript.toHex()),
        toByteString(''), // public key hash that these satoshis will be locked to
        selectionData,
        toByteString(serviceFeeOutput.toBufferWriter().toBuffer().toString('hex')),
        {
          changeAddress: connectedPayAddress,
          pubKeyOrAddrToSign: connectedPayAddress,
          partiallySign: true
        })

      const rawTx = mintResponse.tx.toBuffer();
      console.log(mintResponse.tx.id, rawTx);
      await setTimeout(async () => {
        setMessage("Broadcasting");
        let injectResponse = await axios.get(`https://ordinals.gorillapool.io/api/tx/${mintResponse.tx.id}/submit`)
        console.log({injectResponse})
        await sleep(3000)
        setFinalTxId(mintResponse.tx.id);
      }, 5000)


    } catch (error) {
      let er = error as Error;
      console.error('Error Minting inscription:', er);
      if(er.toString()==="User dismissed the request!"){
        setMessage(null);
        setIsModalOpen(false);
      } else if(er.toString().includes('mempool')) {
        setMessage("w0mp w0mp. someone beat you too it")
      }else{
        setMessage(er.toString())
      }
      //alert("Something went wrong. Refresh the page and try again. Error message: " + error);
      // Handle the error as needed
    }
  };

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  const handleSignMessage = async () => {
    if (!messageToSign) {
      alert("There was no message to sign!");
      return;
    }
    const signRes = await wallet.signMessage({ message: messageToSign });
    if (signRes) setSignedMessage(signRes);
  };

  return (
    <>

      <div className="App bg-dark relative isolate overflow-hidden text-white">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> <a href="/">← Go Back, Pussy </a></div>
          </div>
          <div className="">
            {!addresses &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {addresses &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <div className="App-header h-full">
        
          <div className="">
            <Header 
              initialRemainingSupply={remainingSupply}
              initialMaxSupply={totalSupply}
              inputPrice={priceInSatoshis}></Header>
          </div>
            
          <div className="max-w-7xl">
            <div className=" rounded-lg">
              
              <div className="flex-1 rounded-lg px-8 bg-dark ">
                <div className="flex-1 md:grid md:grid-cols-5 my-2  rounded-lg p-8">
                  <div className="flex items-center md:col-span-2">
                    <div className="bg-gray-100 rounded-xl ring-yellow-500 ring-2 overflow-hidden">
                      <img src={coverUrl}
                          alt="Collection Cover" 
                          className="mx-auto h-full w-full" 
                           />
                    </div>
                  </div>
                  <div className=" flex-1 flex flex-col md:col-span-1"></div>
                  <div className=" flex-1 flex flex-col md:col-span-2 justify-between flex-grow py-12 px-2 lg:px-4 xl:px-8 ">
                    <section
                      aria-labelledby="summary-heading"
                      className="mt-16 w-full rounded-lg bg-gray-900 px-4 py-6 sm:p-4 lg:col-span-5 lg:mt-0 lg:p-8 ring-gray-500 ring-2"
                    >
                      <h2 id="summary-heading" className="text-lg font-medium">
                      {collectionName}
                      </h2>

                      <dl className="mt-6 space-y-4">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm text-gray-200">Price</dt>
                          <dd className="text-sm font-medium text-gray-200">₿ {priceInSatoshis / 100000000}</dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className=" md:text-xs flex items-center text-sm text-gray-200">
                            <span>Mint Fee</span>
                            {/* <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">What is this?</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a> */}
                          </dt>
                          <dd className="text-sm font-medium text-gray-200">{serviceFeeInSatoshis} <span className="text-xs">sats</span></dd>
                        </div>
                        {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                          <dt className="flex text-sm text-gray-600">
                            <span>Tax estimate</span>
                            <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Learn more about how tax is calculated</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                          </dt>
                          <dd className="text-sm font-medium text-gray-900">$8.32</dd>
                        </div> */}
                        {/* <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className="text-base font-medium text-gray-200">Order total</dt>
                          <dd className="text-base font-medium text-gray-200">₿ {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}</dd>
                        </div> */}
                      </dl>

                      <div className="mt-6">
                      {!addresses &&
                        <PandaConnectButton onClick={handleConnect} />
                      }
                      {addresses &&
                        <>
                          {/* <Button onClick={handleDeploy} variant="secondarySquare" className="w-full font-medium" style={{ color: "black" }}>
                              Deploy 
                          </Button> */}
                          <Button onClick={handleMint} variant="gradientSquare" className="w-full font-medium" style={{ color: "black" }}>
                              Mint For {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}
                          </Button>
                        </>
                      }
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GalleryGrid 
            parentID={currentOrigin} 
            address={connectedOrdiAddress? connectedOrdiAddress.toString() : "" } 
            collectionName={collectionName}/>
      </div>
      
      <Footer></Footer>
      <Modal
        isOpen={isModalOpen}
        onClose={() => handleModalClose()}
        lastTxid={finalTxId!}
        inputMessage={message? message : 'Minting'}
      />
    </>
  );
};
