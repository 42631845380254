import { Button } from "./../styled/PrimaryButton"
type Status = 'Minted Out' | 'Minting' | 'Soon';
type User = {
  name: string;
  imageUrl: string;
  link: string;
};
type ActivityItem = {
  collection: User;
  origin: string;
  price: string;
  status: Status;
  supply: string;
  date: string;
  dateTime: string;
};

const statuses: Record<Status, string> = {
  Minting: 'text-green-400 bg-green-400/10',
  'Minted Out': 'text-rose-400 bg-rose-400/10',
  Soon: 'text-yellow-400 bg-yellow-400/10'
};


const activityItems: ActivityItem[] = [
  {
    collection: {
      name: 'Order Of The Delta',
      imageUrl:
        'https://slavettes-layers.s3.amazonaws.com/misc/deltas-cover.png',
      link: '/mint-deltas'
    },
    origin: '217c6fa01e2d59e04138e117198635f750685aca771d1d3e5c808e4bf694df78_0',
    price: '0.00001',
    status: 'Minted Out',
    supply: '199',
    date: '45 minutes ago',
    dateTime: '2023-01-23T11:00',
  },
  {
    collection: {
      name: 'Grand Tardinians',
      imageUrl:
        'https://slavettes-layers.s3.amazonaws.com/misc/highly-regarded-w-accessories.png',
        link: '/mint/tardinians'
    },
    origin: 'f099182e168c0a30a9f97556d156c8b6284cfc3f76eca8352807b1ceff29da04_0',
    price: '0.05',
    status: 'Minted Out',
    supply: '999',
    date: '45 minutes ago',
    dateTime: '2023-01-23T11:00',
  },
  {
    collection: {
      name: '0Face',
      imageUrl:
        'https://ordinals.gorillapool.io/content/cc274a2cc28d88f24a7442443b5542fefe95e486f81f4261e0649401eec30c5d_0',
      link: '/mint/0face'
    },
    origin: '2d89f0c8',
    price: '0.01',
    status: 'Minting',
    supply: '100000',
    date: '45 minutes ago',
    dateTime: '2023-01-23T11:00',
  },
  {
    collection: {
      name: 'Pixel Foxes',
      imageUrl:
        'https://mint-sites.s3.amazonaws.com/foxes/yellow-fox.png',
      link: '/mint/pixel-foxes'
    },
    origin: '2d89f0c81',
    price: 'Free',
    status: 'Minting',
    supply: '10000000',
    date: '45 minutes ago',
    dateTime: '2023-01-23T11:00',
  },
  {
    collection: {
      name: 'Based Frogs',
      imageUrl:
        'https://mint-sites.s3.amazonaws.com/hLEakPQm.jpg',
      link: '/mint/based-frogs'
    },
    origin: 'b94dd05b015519dbb362a4d11399f1f59d8bcfd432996516157f510823892dfc_0',
    price: '0.00550000',
    status: 'Minting',
    supply: '55000',
    date: '45 minutes ago',
    dateTime: '2023-01-23T11:00',
  },
]

function classNames(...classes: (string | undefined | null | false)[]) {
  return classes.filter(Boolean).join(' ');
}




export default function Example() {
  return (
    <div className="bg-gray-900 py-10">
      <h2 className="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">Recent Collections</h2>
      <table className="mt-6 w-full whitespace-nowrap text-left">
        <colgroup>
          <col className="w-full sm:w-4/12" />
          <col className="lg:w-4/12" />
          <col className="lg:w-2/12" />
          <col className="lg:w-1/12" />
          <col className="lg:w-1/12" />
        </colgroup>
        <thead className="border-b border-white/10 text-sm leading-6 text-white">
          <tr>
            <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
              Collection
            </th>
            {/* <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
              Origin
            </th> */}
            <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
              Status
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
              Supply
            </th>
            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
              Price
            </th>
            
            <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
              
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-white/5">
          {activityItems.map((item) => (
            <tr key={item.origin}>
              <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                <div className="flex items-center gap-x-4">
                  <img src={item.collection.imageUrl} alt="" className="h-8 w-8 rounded-full bg-gray-800" />
                  <div className="truncate text-sm font-medium leading-6 text-white">{item.collection.name}</div>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 text-sm sm:pr-8 lg:pr-20">
                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                  {/* <time className="text-gray-400 sm:hidden" dateTime={item.dateTime}>
                    {item.date}
                  </time> */}
                  <div className={classNames(statuses[item.status], 'flex-none rounded-full p-1')}>
                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                  </div>
                  <div className="text-white sm:block">{item.status}</div>
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell">
                {parseInt(item.supply, 10).toLocaleString()}
              </td>
              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                    {item.price}
                  </div>
                </div>
              </td>
              {/* <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">{item.origin}</div>
                </div>
              </td> */}
              
              
              
              <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                {/* <time dateTime={item.dateTime}>{item.date}</time> */}
                {item.status === "Minted Out" && 
                  <a href={`/listings/${item.origin}`} >
                      <Button  variant="iceGradient" className="w-full rounded-lg text-gray-900" >
                          View Collection
                      </Button>
                  </a>
                }
                {item.status === "Minting" && 
                  <a href={`${item.collection.link}`} >
                    <Button variant="gradientSquare" className="w-full" style={{ color: "black" }}>
                        Mint
                    </Button>
                  </a>
                }
                {item.status === "Soon" && 
                  <a href={`${item.collection.link}`} >
                    <Button onClick={() => {}} variant="greenOutlineSquare" className="w-full" style={{ color: "black" }}>
                        <span className="text-green-500">Coming Soon</span>
                    </Button>
                  </a>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
