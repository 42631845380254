import { useEffect, useState, FormEvent } from "react";
import axios from 'axios';
import { Button } from './../components/styled/PrimaryButton';
import Footer from "../components/styled/Footer";


interface FormData {
  name: string;
  collectionName: string;
  collectionDescription: string;
  layers: number;
  totalTraits: number;
  twitterHandle: string;
  contactMethod: 'Twitter' | 'Telegram' | 'Discord';
  otherHandle: string;
}

export const MintRequest = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    collectionName: '',
    collectionDescription: '',
    layers: 0,
    totalTraits: 0,
    twitterHandle: '',
    contactMethod: 'Twitter',
    otherHandle: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    // Transform formData into the required format
    const transformedData = {
      "Collection Name": formData.collectionName,
      "Name": formData.name,
      "Collection Description": formData.collectionDescription,
      "Number of Layers": formData.layers,
      "Total Traits": formData.totalTraits,
      "Twitter Handle": formData.twitterHandle,
      "Contact Method": formData.contactMethod,
      "Other Handle": formData.otherHandle
    };
  
    try {
      const response = await axios.post('https://us-central1-magic-dapp-beta.cloudfunctions.net/api/schemas/aF06MnmmUHEGChFqOusGC/records', transformedData, {
        headers: {
          'x-api-key': 'f971bf3c-d0d7-4602-95ef-1b072aabcbd9' // Replace 'your-api-key' with your actual API key
        }
      });
      console.log(response.data);
        //await setTimeout(() => {
            setIsSubmitting(false)
            setSubmitMessage('Got it! If your project isnt shit. Ill get back to you soon.');   
        //}, 5000);
        
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('Something went wrong. Try again. ');
      // Handle error response
    }
  };
  

  return (
    <>
      <div className="App bg-dark relative isolate overflow-hidden text-white">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> <a href="/">← Go Back, Pussy </a></div>
          </div>
        </div>
        <div className="bg-dark px-6 py-12 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-4xl font-bold tracking-tight text-green-100 sm:text-6xl">Mint With Us</h2>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                    Tell us more about your creation...
                </p>
            </div>
        </div>
        <div className="App-header h-full">
          <form onSubmit={handleSubmit} className="space-y-4 w-full max-w-xl">
            <div className="text-left"><label className="font-medium" >What should we call you?</label><input className="w-full p-2 m-2 rounded-xl ring-2 ring-green-800 bg-gray-900 text-gray-100" type="text" name="name" required onChange={handleChange} /></div>
            <div className="text-left"><label className="font-medium">What are you gonna call the collection?</label><input className="w-full p-2 m-2 rounded-xl ring-2 ring-green-800 bg-gray-900 text-gray-100" type="text" name="collectionName"  required onChange={handleChange} /></div>
            <div className="text-left"><label className="font-medium"> Tell us about your collection.</label><textarea className="w-full p-2 m-2 rounded-xl ring-2 ring-green-800 bg-gray-900 text-gray-100" name="collectionDescription"  required onChange={handleChange} /></div>
            <div className="text-left"><label className="font-medium"> How many layers does your collection have</label><input className="w-full p-2 m-2 rounded-xl ring-2 ring-green-800 bg-gray-900 text-gray-100" type="number" name="layers"  required onChange={handleChange} /></div>
            <div className="text-left"><label className="font-medium"> How many total traits from all layers?</label><input className="w-full p-2 m-2 rounded-xl ring-2 ring-green-800 bg-gray-900 text-gray-100" type="number" name="totalTraits" required onChange={handleChange} /></div>
            <div className="text-left"><label className="font-medium"> Whats your Twitter Handle?</label><input className="w-full p-2 m-2 rounded-xl ring-2 ring-green-800 bg-gray-900 text-gray-100" type="text" name="twitterHandle" placeholder="@someoneCool" required onChange={handleChange} /></div>
            <div className="text-left">
                <label className="font-medium"> Whats your Preferred method of contact?</label>
                <select className="w-full p-2 m-2 rounded-xl ring-2 ring-green-800 bg-gray-900 text-gray-100" name="contactMethod" required onChange={handleChange}>
                    <option value="Twitter">Twitter</option>
                    <option value="Telegram">Telegram</option>
                    <option value="Discord">Discord</option>
                </select>
            </div>
            <div><input className="w-full p-2 m-2 rounded-xl ring-2 ring-green-800 bg-gray-900 text-gray-100" type="text" name="otherHandle" placeholder="Your handle (if not Twitter)" required onChange={handleChange} /></div>
            <div><Button type="submit" variant='gradient' className="text-black">Submit</Button></div>
          </form>
        </div>
        <Footer></Footer>
      </div>
      {isSubmitting && (
         <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
      {submitMessage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-gray-900 p-12 rounded-lg">
            <p className="text-gray-100">{submitMessage}</p>
            <Button variant='secondary' onClick={() => {setIsSubmitting(false); setSubmitMessage('' )}}>Close</Button>
          </div>
        </div>
      )}
    </>
  );
};
