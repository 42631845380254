import Hero from "./../components/home/Hero"
import MintList from "./../components/home/MintList"
import FeaturesFunds from "./../components/home/FeaturesFunds"
import Footer from "./../components/styled/Footer"
import MintWithUs from "../components/home/MintWithUs"
import TwonksList from "../components/home/TwonksList"
import MintingNow from "../components/MintingNow"
export const Home = () => {

    return(
        <>
        <Hero ></Hero>
        <MintingNow></MintingNow>
        <MintList></MintList>
        <TwonksList></TwonksList>
        <MintWithUs></MintWithUs>
        <FeaturesFunds></FeaturesFunds>
        <Footer></Footer>
        </>
    );
}