import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import pandaIcon from "../assets/panda-icon.svg";
import { PandaConnectButton } from "../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { LayerData, RecGCBase } from "../contracts/rec-gc-base";
import { RecGCFull } from "../contracts/rec-gc-full";
import artifact from '../../artifacts/rec-gc-full.json';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from './../components/styled/PrimaryButton';
import Header from "../components/styled/Header";
import Footer from "../components/styled/Footer";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Modal from "../components/mint/WaitingModal";
import { ShruggrLib } from "../contracts/shruggr-lib";
import GalleryGrid from "../components/GalleryGrid";

RecGCFull.loadArtifact(artifact);

export const SamplePage = () => {
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [initialSupply, setInitialSupply] = useState(0)
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [coverUrl, setCoverUrl] = useState<string>('')
  const [currentOrigin, setCurrentOrigin] = useState<string>('e4b42788d5eafb7017a419f3dc1d9c6f7f16dfa481a4ff0637a1bdcf378b533c_0');
  const [currentLocation, setCurrentLocation] = useState<string>('e4b42788d5eafb7017a419f3dc1d9c6f7f16dfa481a4ff0637a1bdcf378b533c_0');
  const [priceInSatoshis, setPriceInSatoshis] = useState<number>(1000)
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(1000)
  const [totalSupply, setTotalSupply] = useState<number>(0)
  const [collectionName, setCollectionName] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [finalTxId, setFinalTxId] = useState<string | null>(''); // State to store the mint response
  const location = useLocation();
  const [signedMessage, setSignedMessage] = useState<
    SignedMessage | undefined
  >();
  const coverPhotoUrl = 'https://ordinals.gorillapool.io/content/43a3299aeed85e349a0b9d7d56772b997a8b386a3d40aa6a71b99287febdcc3b_0';
  
  useEffect(() => {
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(location.search);
    // Get the value of the 'origin' parameter
    const originQueryParam = queryParams.get('origin');
    if (originQueryParam) {
      // Set the state to the query string value
      console.log("Found Origin:", originQueryParam)
      setCurrentOrigin(originQueryParam);
    }
  }, [location]);

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo);

      if(contractInstance.payOut !== toByteString("")){
        const price = 
          bsv.Transaction.Output.fromBufferReader(
            new bsv.encoding.BufferReader(Buffer.from(contractInstance.payOut, 'hex'))
        )
        console.log(price.satoshis);
      }
      console.log("setting remaining supply", Number(contractInstance.supply))
      localStorage.setItem("remainingSupply", contractInstance.supply.toString());
      setRemainingSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin, currentLocation, finalTxId])


  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("setting total supply", contractInstance.supply.toString())
      localStorage.setItem("maxSupply", contractInstance.supply.toString());
      setTotalSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin])

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/txos/${currentOrigin}/`;
    axios.get(url).then((response) => {
      setCollectionName(response.data.origin.data.map.name);
    }).catch((error) => console.log({ error }));
  }, [])



  // useEffect(() => {
  //   if (!wallet.connect) {
  //     return;
  //   }
  
  //   const connectWallet = async () => {
  //     try {
  //       const key = await wallet.connect();
  //       setPubKey(key);
  
  //       const signer = new PandaSigner(new OrdiProvider()); // <---- use `PandaSigner`
  //       setSigner(signer);
  
  //       const { isAuthenticated, error } = await signer.requestAuth();
  //       if (!isAuthenticated) {
  //         throw new Error(`Unauthenticated: ${error}`);
  //       }
  
  //       setConnectedPayAddress(await signer.getDefaultAddress());  // <----
  //       setConnectedOrdiAddress(await signer.getOrdAddress());     // <----
  //       await handleGetAddresses();
  //     } catch (error) {
  //       alert("Something went wrong signing in. " + error);
  //     }
  //   };
  
  //   connectWallet();
  // }, [wallet]);
  
  const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
  const layers: LayerData[] = [
    new LayerData(
      toByteString("Layer 4", true),
      [
        {
          "outpoint": toByteString("daed3a8cd033c055de44c5361100687d47ce8eb2c1150e81c042b3f1420554f4_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 4 Blue",
            "description": "Layer 4 Blue description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("55f4d8136011615749d1d948a3ce88395b6a0be9c7583b53704cc2d68773a02b_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 4 Dark Green",
            "description": "Layer 4 Dark Green description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("cac18976c43f1e9f47079dc8be389d63859312db9720c976f78498005be62a88_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer Yellow",
            "description": "Layer Yellow description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("cac18976c43f1e9f47079dc8be389d63859312db9720c976f78498005be62a88_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 4 Yellow",
            "description": "Layer Yellow description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("d1c25d0cae5f9a04d8d532c5cb38a8cf6a99fbb82df0418c0fc8ec5fde6c3b19_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 4 Lime",
            "description": "Layer 4 Lime description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("ed799a4dcd44b57878fda9cac05ec949f5ac5f8d8812d319b70bd7aa55a23976_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 4 Orange",
            "description": "Layer 4 Orange description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("f2c1cea59db6b63e310e4c54453e05ed4a655b46880ad30748a0f4183d9c53f5_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 4 Ice",
            "description": "Layer 4 Ice description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("151d45e88e399565672234cb89b1c9040dd1998dfabfde027230f9b389363791_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 4 Purple",
            "description": "Layer 4 Purple description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("62b7ad898fb9be347dd0062ea2c6d38a7a64248279eef7ec4cbe90c91a70cb82_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 4 Red",
            "description": "Layer 4 Red description",
           // "more arbitrary info": "venom accessory"
          }), true),
        }
      ]
    ),
    new LayerData(
      toByteString("Background", true),
      [
        {
          "outpoint": toByteString("e0a24f74839dda56bc791395555e8c584937bdcfd4d43004d5a0cfd7229e8af6_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 1 Green",
            "description": "Layer 1 Green description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("5cfd1fe7cbf59775b8ce064b37aff61d5f317cb9447ac189df172f5a423a5737_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 1 Blue",
            "description": "Layer 1 Blue description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("d38fb74b493d525c80d48be482b2c3a01d82a24b305b3fa0d886aff17df80799_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 1 Tan",
            "description": "Layer 1 Tan description",
           // "more arbitrary info": "venom accessory"
          }), true),
        }
      ]
    ),
    new LayerData(
      toByteString("Layer 2", true),
      [
        {
          "outpoint": toByteString("e903278f8efcc6fb343a4d297bcfedf2b0e49509331cff175f5ec19f1daa45cc_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 2 Yellow",
            "description": "Layer 2 Yellow description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("4746b08b135553a6207ce97c676a6e9a2b025d1f16d166285c626ece3bd69317_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 2 Purple",
            "description": "Layer 2 Purple description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("db4b661dcf344fe15519ab58c6756ac5ab00751d68d9bc10d4db87fd90961836_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 2 Red",
            "description": "Layer 2 Red description",
           // "more arbitrary info": "venom accessory"
          }), true),
        }
      ]
    ),
    new LayerData(
      toByteString("Layer 3", true),
      [
        {
          "outpoint": toByteString("e455b4eee04dd2ca706f64d9574ded606548cfbd78d3e3b837f6f51ae3dff902_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 3 Blue",
            "description": "Layer 3 Blue description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("301db0f499ab34ae5a24dd1eab15eda98d13e52572edcc120335452a8a773f2e_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 3 Black",
            "description": "Layer 3 Black description",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("db4b661dcf344fe15519ab58c6756ac5ab00751d68d9bc10d4db87fd90961836_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Layer 3 Red",
            "description": "Layer 3 Red description",
           // "more arbitrary info": "venom accessory"
          }), true),
        }
      ]
    )
  ]
  // Layers that were working
  // const layers: LayerData[] = [
  //   //Layers
  //   new LayerData(
  //     toByteString("Accessory", true),
  //     [
  //       //Layer Items
  //       {
  //         "outpoint": toByteString("d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0", true),
  //         "data": toByteString(JSON.stringify({
  //           "name": "Venom",
  //           "description": "venom accessory",
  //           "more arbitrary info": "venom accessory"
  //         }), true),
  //       },
  //       {
  //         "outpoint": toByteString("6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0", true),
  //         "data": toByteString(JSON.stringify({
  //           "name": "Purple",
  //           "description": "purple accessory",
  //           "more arbitrary info": "purple accessory"
  //         }), true),
  //       },
  //       {
  //         "outpoint": toByteString("538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0", true),
  //         "data": toByteString(JSON.stringify({
  //           "name": "Pink",
  //           "description": "pink accessory",
  //           "more arbitrary info": "pink accessory"
  //         }), true),
  //       },
  //     ]
  //   ),
  //   new LayerData(
  //     toByteString("Clothes", true),
  //     [
  //       //2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0
  //       {
  //         "outpoint": toByteString("2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0", true),
  //         "data": toByteString(JSON.stringify({
  //           "name": "Yellow Clothes",
  //           "description": "clothes that are yellow",
  //           "more arbitrary info": "more yellow"
  //         }), true),
  //       },
  //       {
  //         "outpoint": toByteString("f1be2424f057ab6e410b8bbcc715d8e5b80597e407a157e00730b3acb1cb0f90_0", true),
  //         "data": toByteString(JSON.stringify({
  //           "name": "Light Blue Clothes",
  //           "description": "clothes that are Light Blue",
  //           "more arbitrary info": "more Light Blue"
  //         }), true),
  //       },
  //       {
  //         "outpoint": toByteString("513b9109f7a129886f040f88d4500bafb1df511d4abdf53064ad02a0846fa4c7_0", true),
  //         "data": toByteString(JSON.stringify({
  //           "name": "Green Clothes",
  //           "description": "clothes that are Green",
  //           "more arbitrary info": "more Green"
  //         }), true),
  //       },
  //       // ...
  //     ],
  //   ),
  //   new LayerData(
  //     toByteString("Eyes", true),
  //     [
  //       // {
  //       //   "outpoint": toByteString("2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0", true),
  //       //   "data": toByteString(JSON.stringify({
  //       //     "name": "Light Blue",
  //       //     "description": "eyes that are light blue",
  //       //     "more arbitrary info": "more light blue"
  //       //   }), true),
  //       // },
  //       {
  //         "outpoint": toByteString("0e2e17c03c02f616c675ed04685dc54d03de059ca1223d0c8f3bda407b080492_0", true),
  //         "data": toByteString(JSON.stringify({
  //           "name": "Blue Eyes",
  //           "description": "eyes that are Blue",
  //           "more arbitrary info": "more Blue"
  //         }), true),
  //       },
  //       {
  //         "outpoint": toByteString("2d6dc8cad9a7537c44bc267022923182e8790b396727d07effaf654969442485_0", true),
  //         "data": toByteString(JSON.stringify({
  //           "name": "Red Eyes",
  //           "description": "eyes that are Red",
  //           "more arbitrary info": "more Red"
  //         }), true),
  //       },
  //       // ...
  //     ],
  //   )
  // ]

  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try{
      const key = await wallet.connect();
      setPubKey(key);
      const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth()
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`)
      }
      setConnectedPayAddress(await signer.getDefaultAddress())  // <----
      setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
      await handleGetAddresses();
    } catch(error){
      alert("Something went wrong sigining in. " + error)
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false)
    setFinalTxId(null)
  }

  //TODO: Add a hook to get the contract details from the origin 1 time if theyre not populated 
  //      and save them in initalSupply, etc... 

  const handleDeploy = async () => {

    const payoutScript = bsv.Script.buildPublicKeyHashOut(connectedPayAddress!)
    console.log("Payout Script:", payoutScript);
    const payout =
      new bsv.Transaction.Output({ script: payoutScript, satoshis: priceInSatoshis })
    console.log("Payout:", payout);
    const payoutHex = payout.toBufferWriter()
      .toBuffer()
      .toString('hex');
    console.log("PayoutHex:", payoutHex);

    //TODO: Derive this from the layers object but make it match the CollectionItem Spec
    //this _subTypeData is arbitrary but searchable. 
    //Include any data that the collection would want to be queried by
    // I should build this autmatically from the layers information. 
    const _subTypeData = {
      description: "This is a test class",
      attributes: [
        //Layers
        {
          name: "Accessory",
          assets: [
            //Layer Items
            {
              "outpoint": "d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0",
              "name": "Venom",
              "description": "venom accessory",
              "more arbitrary info": "venom accessory"
            },
            {
              "outpoint": "6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0",
              "name": "Purple",
              "description": "purple accessory",
              "more arbitrary info": "purple accessory"
            },
            {
              "outpoint": "0538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0",
              "name": "Pink",
              "description": "Pink accessory",
              "more arbitrary info": "Pink accessory"
            }
          ]
        },
        {
          name: "Clothes",
          assets: [
            {
              "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
              "name": "Yellow Clothes",
              "description": "Sun clothes",
              "more arbitrary info": "Yellow Clothes"
            },
            // ...
          ],
        },
        {
          name: "Eyes",
          assets: [
            {
              "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
              "name": "Yellow Eyes",
              "description": "Sun colored Eyes",
              "more arbitrary info": "This is a cool Eyes."
            },
            // ...
          ],
        }
      ]
    }


    const _royalties = [
      { type: "address", destination: "1EqiQhoQJdyNEvpmX9u2bCKwMLdBRNz1q", percentage: "0.00" }
    ]
    const metadata = {
      app: 'RareDropper',
      type: 'ord',
      contractId: artifact.md5,
      name: "We So Testy v3",
      subType: 'collection',
      subTypeData: JSON.stringify(_subTypeData),
      royalties: JSON.stringify(_royalties),
    };

    console.log(JSON.stringify(metadata));
    const map = new bsv.Script('');
    map.add(Buffer.from("SET"));
    for (const [key, value] of Object.entries(metadata)) {
      map.add(Buffer.from(key))
      map.add(Buffer.from(value))
    }

    const response = await fetch(coverPhotoUrl);
    const data = await response.arrayBuffer();

    // console.log("Content:", )
    const inscriptionScript = ShruggrLib.buildInscriptionScript({
      content: Buffer.from(data),
      contentType: 'image/png'
    },
      {
        "1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5": map.toBuffer()
      }
    )
    const deployable = new RecGCFull(
                          toByteString(metadata.name, true), //the ordinals name 
                          RecGCBase.buildLayerNames(layers), //constructed names for the layers
                          RecGCBase.buildLayers(layers),  // the layers object
                          21n, // number of NFTs to mint into the collection
                          0n, // This is a difficuty number in bits. Set to 0 for no POW. 4 of these difficuly = 1 POW20
                          toByteString(payoutHex), //the address & amount to pay when minting
                          0n, //the current height of the chain to enforce the starting point on locks 
                          0n, // number of blocks to remain locked 
                          0n, // number of satoshis that must be locked
                          BigInt(inscriptionScript.length)) // number of bytes in the inscription

    console.log("Deployable:", deployable);
    await deployable.connect(signer!)

    let deployResponse = await deployable.inscribeWithNoOp(inscriptionScript)
    const rawTx = deployResponse.toBuffer();
    console.log(deployResponse.id, rawTx);
    setTimeout(async () => {
      axios.get(`https://ordinals.gorillapool.io/api/tx/${deployResponse.id}/submit`)
    }, 5000)
    setCurrentOrigin(deployResponse.id + "_0")
  };

  const handleMint = async () => {
    setIsModalOpen(true);
    try {
      setFinalTxId('');
      await wallet.getPaymentUtxos()
      await wallet.getBalance();
      const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
      const response = await axios.get(url);
      console.log("This is the the response to Latest", response.data);
      if (response.data.spend && response.data.spend !== "") {
        //This is the signal that the mint is complete. 
        setIsModalOpen(false);
        console.log("spend is not null");
        alert("Try refreshing the balance in yours wallet. Click on the main balance, where the panda used to be.")
        return;
      }
      console.log("setting current location", response.data.outpoint);
      
      setCurrentLocation(response.data.outpoint)
      // const inscPrefix = Buffer.from('0063036f726451', 'hex')

      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("Contract Instance initialized...", contractInstance.layers);
      setRemainingSupply(Number(contractInstance.supply));
      localStorage.setItem("remainingSupply", Number(contractInstance.supply).toString());

      let serviceFeeOutput = new bsv.Transaction.Output({
        script: bsv.Script.fromAddress(new bsv.Address("1o3PJCEv5pZpuqtgR6zefL28ZqdEtWDnm")),
        satoshis: 1000
      })

      await contractInstance.connect(signer!)
      const nonce = toByteString('');
      const selectionData = contractInstance.buildSelectionData(layers, nonce);
      console.log({ selectionData });
      const buyerScript = bsv.Script.buildPublicKeyHashOut(connectedOrdiAddress!)
      const mintResponse = await contractInstance.methods.mint(
        nonce, // user generated random bytestring. If the difficulty is higher than 0 this needs to be something that when hashed with the previous txid (Little Endian) it creates the output with the specified proof of work. 
        toByteString(buyerScript.toHex()),
        toByteString(''), // public key hash that these satoshis will be locked to
        selectionData,
        toByteString(serviceFeeOutput.toBufferWriter().toBuffer().toString('hex')),
        {
          changeAddress: connectedPayAddress,
          pubKeyOrAddrToSign: connectedPayAddress,
          partiallySign: true
        })

      const rawTx = mintResponse.tx.toBuffer();
      console.log(mintResponse.tx.id, rawTx);
      await setTimeout(async () => {
        let injectResponse = await axios.get(`https://ordinals.gorillapool.io/api/tx/${mintResponse.tx.id}/submit`)
        console.log({injectResponse})
        await sleep(5000)
        setFinalTxId(mintResponse.tx.id);
      }, 5000)


    } catch (error) {
      setIsModalOpen(false);
      console.error('Error Minting inscription:', error);
      alert("Something went wrong. Refresh the page and try again. Error message: " + error);
      // Handle the error as needed
    }
  };

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  const handleSignMessage = async () => {
    if (!messageToSign) {
      alert("There was no message to sign!");
      return;
    }
    const signRes = await wallet.signMessage({ message: messageToSign });
    if (signRes) setSignedMessage(signRes);
  };

  return (
    <>

      <div className="App bg-dark relative isolate overflow-hidden text-white">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> <a href="/">← Go Back, Pussy </a></div>
          </div>
          <div className="">
            {!addresses &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {addresses &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <div className="App-header h-full">
        
          <div className="">
            <Header 
              initialRemainingSupply={remainingSupply}
              initialMaxSupply={totalSupply}></Header>
          </div>
            
          <div className="">
            <div className="pb-24 rounded-lg">
              
              <div className="flex-1 rounded-lg px-8 bg-dark ">
                <div className="flex-1 md:grid md:grid-cols-5 my-2  rounded-lg p-16">
                  <div className="flex items-center md:col-span-3">
                    <div className="bg-gray-100 rounded-xl ring-yellow-500 ring-2">
                      <img src={coverUrl}
                          alt="Collection Cover" 
                          className="mx-auto h-full w-full" 
                           />
                    </div>
                  </div>
                  <div className=" flex-1 flex flex-col md:col-span-2 justify-between flex-grow py-12 px-2 lg:px-4 xl:px-8 ">
                    <section
                      aria-labelledby="summary-heading"
                      className="mt-16 w-full rounded-lg bg-gray-900 px-4 py-6 sm:p-4 lg:col-span-5 lg:mt-0 lg:p-8 ring-gray-500 ring-2"
                    >
                      <h2 id="summary-heading" className="text-lg font-medium">
                      {collectionName}
                      </h2>

                      <dl className="mt-6 space-y-4">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm text-gray-200">Price</dt>
                          <dd className="text-sm font-medium text-gray-200">₿ {priceInSatoshis / 100000000}</dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className=" md:text-xs flex items-center text-sm text-gray-200">
                            <span>Mint Fee</span>
                            {/* <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">What is this?</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a> */}
                          </dt>
                          <dd className="text-sm font-medium text-gray-200">{serviceFeeInSatoshis} <span className="text-xs">sats</span></dd>
                        </div>
                        {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                          <dt className="flex text-sm text-gray-600">
                            <span>Tax estimate</span>
                            <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Learn more about how tax is calculated</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                          </dt>
                          <dd className="text-sm font-medium text-gray-900">$8.32</dd>
                        </div> */}
                        {/* <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className="text-base font-medium text-gray-200">Order total</dt>
                          <dd className="text-base font-medium text-gray-200">₿ {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}</dd>
                        </div> */}
                      </dl>

                      <div className="mt-6">
                      {!addresses &&
                        <PandaConnectButton onClick={handleConnect} />
                      }
                      {addresses &&
                        <>
                          <Button onClick={handleDeploy} variant="secondarySquare" className="w-full font-medium" style={{ color: "black" }}>
                              Deploy 
                          </Button>
                          <Button onClick={handleMint} variant="gradientSquare" className="w-full font-medium" style={{ color: "black" }}>
                              Mint For {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}
                          </Button>
                        </>
                      }
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div> 
            <GalleryGrid parentID={currentOrigin} address={connectedOrdiAddress? connectedOrdiAddress.toString() : ""} collectionName={collectionName} />
          </div>
        </div>
      </div>
      
      <Footer></Footer>
      <Modal
        isOpen={isModalOpen}
        onClose={() => handleModalClose()}
        lastTxid={finalTxId!}
      />
    </>
  );
};
