import { Button } from "./../styled/PrimaryButton"
type Status = 'Minted Out'
type User = {
    name: string;
    imageUrl: string;
    link: string;
};
type ActivityItem = {
    collection: User;
    origin: string;
    price: string;
    status: Status;
    supply: string;
    date: string;
    dateTime: string;
};

const statuses: Record<Status, string> = {
    'Minted Out': 'text-rose-400 bg-rose-400/10',
};


const activityItems: ActivityItem[] = [
    {
        collection: {
            name: 'Fox Syndicate',
            imageUrl:
                'https://ordfs.network/content/56d10eed9c2586fc72d615e372b41b797aabaa5c6c1a99d1b1fe2a548972ea9d_0',
            link: '/twonks/fox-syndicate'
        },
        origin: '56d10eed9c2586fc72d615e372b41b797aabaa5c6c1a99d1b1fe2a548972ea9d_0',
        price: '0.182',
        status: 'Minted Out',
        supply: '4444',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Astro Apes',
            imageUrl:
                'https://ordfs.network/content/fb960be0604d81ae9cf005dd92cc05e8e65298af9ed836ed427a74a7cba22cb6_0',
            link: '/twonks/astro-apes'
        },
        origin: 'fb960be0604d81ae9cf005dd92cc05e8e65298af9ed836ed427a74a7cba22cb6_0',
        price: '0.218',
        status: 'Minted Out',
        supply: '2000',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Astro Apes: Special Forces',
            imageUrl:
                'https://ordfs.network/content/f66ca8d610dbd3402a2cca6f303cd54554191b06b3de78281dd5472dd7ae8881_0',
            link: '/twonks/special-forces'
        },
        origin: 'f66ca8d610dbd3402a2cca6f303cd54554191b06b3de78281dd5472dd7ae8881_0',
        price: 'FREE',
        status: 'Minted Out',
        supply: '2000',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Astro Apes: Lab Monkes',
            imageUrl:
                'https://ordfs.network/content/84305127e69880b7e89d8828af38f387ac7d94229f2a7ec955cccdbd30ed4db8_0',
            link: '/twonks/lab-monkes'
        },
        origin: '84305127e69880b7e89d8828af38f387ac7d94229f2a7ec955cccdbd30ed4db8_0',
        price: 'FREE',
        status: 'Minted Out',
        supply: '2000',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Astro Apes: Slurp Juice',
            imageUrl:
                'https://ordfs.network/content/58f35df588395a1a632db1f62f4f432bae77ed33ae618abec2bd88efbe065827_0',
            link: '/twonks/slurp-juice'
        },
        origin: '58f35df588395a1a632db1f62f4f432bae77ed33ae618abec2bd88efbe065827_0',
        price: 'FREE',
        status: 'Minted Out',
        supply: '2000',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'NVMBEARS',
            imageUrl:
                'https://ordfs.network/content/c8d3c69979a221e8ccfad42b174712d9f4443e0ab3676d035e415d166b23543f_0',
            link: '/twonks/nvmbears'
        },
        origin: 'c8d3c69979a221e8ccfad42b174712d9f4443e0ab3676d035e415d166b23543f_0',
        price: '0.777',
        status: 'Minted Out',
        supply: '999',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Bored Waifus',
            imageUrl:
                'https://ordfs.network/content/5d6e1c5362b0642673be43e95eb6556bdcc1eb86b558624eaecc59ba25b50ea5_0',
            link: '/twonks/bored-waifus'
        },
        origin: '5d6e1c5362b0642673be43e95eb6556bdcc1eb86b558624eaecc59ba25b50ea5_0',
        price: '0.2888',
        status: 'Minted Out',
        supply: '2888',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Rare Sirlions',
            imageUrl:
                'https://ordfs.network/content/d4724cfbcf49c8addf779b7267db992397a32d25628899ba9e93587c31826596_0',
            link: '/twonks/rare-sirloins'
        },
        origin: 'd4724cfbcf49c8addf779b7267db992397a32d25628899ba9e93587c31826596_0',
        price: '0.218',
        status: 'Minted Out',
        supply: '2180',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Turbo Fox',
            imageUrl:
                'https://ordfs.network/content/ed3e3cb7e2730ed4b0e5a4ac50d2b06f2e5a8af3a2b8ad7e67828c178d27eb22_0',
            link: '/twonks/turbo-fox'
        },
        origin: 'ed3e3cb7e2730ed4b0e5a4ac50d2b06f2e5a8af3a2b8ad7e67828c178d27eb22_0',
        price: '0.00000001',
        status: 'Minted Out',
        supply: '69',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Planetary Pixels',
            imageUrl:
                'https://ordfs.network/content/4bdd09355b5cad44ad7769d6781bc1570559a1e5e369ef9cfc7658fb4208e294_0',
            link: '/twonks/planetary-pixels'
        },
        origin: '4bdd09355b5cad44ad7769d6781bc1570559a1e5e369ef9cfc7658fb4208e294_0',
        price: '0.05000000',
        status: 'Minted Out',
        supply: '99',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Planetary Balls',
            imageUrl:
                'https://ordfs.network/content/7e9004c9ea93120202f96b7d307c988a52f10d32416a7e07d34c788d045b9107_0',
            link: '/twonks/planetary-balls'
        },
        origin: '7e9004c9ea93120202f96b7d307c988a52f10d32416a7e07d34c788d045b9107_0',
        price: '0.42000000',
        status: 'Minted Out',
        supply: '99',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Planetary Squares',
            imageUrl:
                'https://ordfs.network/content/61757f7b73135fc5d0c02913f3cb1d33c10ea021a4d5726caeccaad1ab5a3317_0',
            link: '/twonks/planetary-squares'
        },
        origin: '61757f7b73135fc5d0c02913f3cb1d33c10ea021a4d5726caeccaad1ab5a3317_0',
        price: '0.00100000',
        status: 'Minted Out',
        supply: '222',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Planetary Buttons',
            imageUrl:
                'https://ordfs.network/content/fb3b5e47ebce0ea81d88218ccd90529908987093ce0a34dee620b8552aa1498f_0',
            link: '/twonks/planetary-buttons'
        },
        origin: 'fb3b5e47ebce0ea81d88218ccd90529908987093ce0a34dee620b8552aa1498f_0',
        price: '2.18',
        status: 'Minted Out',
        supply: '69',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Planetary Skies',
            imageUrl:
                'https://ordfs.network/content/7ddaf114a2efa146689439a4587a7242864798cc26c0c2b3f02c8adaa6e95e2e_0',
            link: '/twonks/planetary-skies'
        },
        origin: '7ddaf114a2efa146689439a4587a7242864798cc26c0c2b3f02c8adaa6e95e2e_0',
        price: '0.20000000',
        status: 'Minted Out',
        supply: '99',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        collection: {
            name: 'Twelve Coom',
            imageUrl:
                'https://ordfs.network/content/f275fcdff498d6f47d9934cb9321a28e5314f8e4672886475e0dfd19dec56eea_0',
            link: '/twonks/twelvecoom'
        },
        origin: 'f275fcdff498d6f47d9934cb9321a28e5314f8e4672886475e0dfd19dec56eea_0',
        price: '0.20000000',
        status: 'Minted Out',
        supply: '99',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    }
]

function classNames(...classes: (string | undefined | null | false)[]) {
    return classes.filter(Boolean).join(' ');
}




export default function TwonksList() {
    return (
        <div className="bg-gray-900 py-10">
            <h2 className="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">Featured Twonks</h2>
            <table className="mt-6 w-full whitespace-nowrap text-left">
                <colgroup>
                    <col className="w-full sm:w-4/12" />
                    <col className="lg:w-4/12" />
                    <col className="lg:w-2/12" />
                    <col className="lg:w-1/12" />
                    <col className="lg:w-1/12" />
                </colgroup>
                <thead className="border-b border-white/10 text-sm leading-6 text-white">
                    <tr>
                        <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                            Collection
                        </th>
                        {/* <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
              Origin
            </th> */}
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
                            Status
                        </th>
                        <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                            Supply
                        </th>
                        <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                            Price
                        </th>

                        <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">

                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-white/5">
                    {activityItems.map((item) => (
                        <tr key={item.origin}>
                            <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                                <div className="flex items-center gap-x-4">
                                    <iframe src={item.collection.imageUrl} className="h-8 w-8 rounded-full bg-gray-800" />
                                    <div className="truncate text-sm font-medium leading-6 text-white">{item.collection.name}</div>
                                </div>
                            </td>
                            <td className="py-4 pl-0 pr-4 text-sm sm:pr-8 lg:pr-20">
                                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                    {/* <time className="text-gray-400 sm:hidden" dateTime={item.dateTime}>
                    {item.date}
                  </time> */}
                                    <div className={classNames(statuses[item.status], 'flex-none rounded-full p-1')}>
                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                    </div>
                                    <div className="text-white sm:block">{item.status}</div>
                                </div>
                            </td>
                            <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell">
                                {parseInt(item.supply, 10).toLocaleString()}
                            </td>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                <div className="flex gap-x-3">
                                    <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                        {item.price}
                                    </div>
                                </div>
                            </td>
                            {/* <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">{item.origin}</div>
                </div>
              </td> */}



                            <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                                {/* <time dateTime={item.dateTime}>{item.date}</time> */}
                                {item.status === "Minted Out" &&
                                    <a href={`${item.collection.link}`} >
                                        <Button variant="iceGradient" className="w-full rounded-lg text-gray-900" >
                                            Inventory
                                        </Button>
                                    </a>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
