import { useEffect, useState } from "react";
import pandaIcon from "../assets/panda-icon.svg";
import { PandaConnectButton } from "../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { LayerData, RecGCBase } from "../contracts/rec-gc-base";
import { RecGCFull } from "../contracts/rec-gc-full";
import artifact from '../../artifacts/rec-gc-full.json';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
//import { OrdiProvider } from "scrypt-ord";
import { PandaSCryptProvider } from "../utils/panda-scrypt-provider";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from './../components/styled/PrimaryButton';
import Header from "../components/styled/Header";
import Footer from "../components/styled/Footer";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Modal from "../components/mint/WaitingModal";
import { ShruggrLib } from "../contracts/shruggr-lib";

RecGCFull.loadArtifact(artifact);

export const SampleLTM = () => {
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedIdentityAddress, setConnectedIdentityAddress] = useState<bsv.Address | undefined>(undefined)
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [initialSupply, setInitialSupply] = useState(0)
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [coverUrl, setCoverUrl] = useState<string>('')
  const [currentOrigin, setCurrentOrigin] = useState<string>('a6bc283b6855c3949f4432fb964dc457b27e916f89e9a4ea48220433ca8dd801_0');
  const [currentLocation, setCurrentLocation] = useState<string>('a6bc283b6855c3949f4432fb964dc457b27e916f89e9a4ea48220433ca8dd801_0');
  const [priceInSatoshis, setPriceInSatoshis] = useState<number>(100000)
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(10000)
  const [collectionName, setCollectionName] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [mintResponse, setMintResponse] = useState<string | null>(''); // State to store the mint response
  const [currentHeight, setCurrentHeight] = useState<Number>(0);
  const [signedMessage, setSignedMessage] = useState<
    SignedMessage | undefined
  >();
  const coverPhotoUrl = 'https://ordinals.gorillapool.io/content/43a3299aeed85e349a0b9d7d56772b997a8b386a3d40aa6a71b99287febdcc3b_0';



  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("setting remaining supply", Number(contractInstance.supply))
      setRemainingSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin])

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/txos/${currentOrigin}/`;
    axios.get(url).then((response) => {
      setCollectionName(response.data.origin.data.map.name);
    }).catch((error) => console.log({ error }));
  }, [])

  
  const initProvider = () => {
    if ('panda' in window) {
      const provider = window.panda;
  
      if (provider?.isReady) {
        return provider;
      }
    }
  
    window.open('https://chromewebstore.google.com/detail/panda-wallet/mlbnicldlpdimbjdcncnklfempedeipj', '_blank');
  };

  const handleConnect = async () => {
    const wallet = initProvider();
    // if(!wallet.isConnected()){

    // }
    const provider = new PandaSCryptProvider(wallet, bsv.Networks.mainnet)
    const signer = new PandaSigner(provider)//new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
    setSigner(signer);
    if(!await wallet.isConnected()){
        await wallet.connect();
    }
    // const { isAuthenticated, error } = await signer.requestAuth()
    
    // if (!isAuthenticated) {
    //     throw new Error(`Unauthenticated: ${error}`)
    // }
    try{
        const addresses = await wallet.getAddresses();
        const _bsvAddress = addresses!.bsvAddress;
        const _ordAddress = addresses!.ordAddress;
        const _idAddress = addresses!.identityAddress;
        console.log(_bsvAddress, _ordAddress, _idAddress, addresses);
        setConnectedPayAddress(new bsv.Address(_bsvAddress))  // <----
        setConnectedOrdiAddress(new bsv.Address(_ordAddress))// <----
        setConnectedIdentityAddress(new bsv.Address(_idAddress))
    }catch(err){
        console.error(err);
    }
   
  };
  

  const layers: LayerData[] = [
    //Layers
    new LayerData(
      toByteString("Accessory", true),
      [
        //Layer Items
        {
          "outpoint": toByteString("d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Venom",
            "description": "venom accessory",
            "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Purple",
            "description": "purple accessory",
            "more arbitrary info": "purple accessory"
          }), true),
        },
        {
          "outpoint": toByteString("538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Pink",
            "description": "pink accessory",
            "more arbitrary info": "pink accessory"
          }), true),
        },
      ]
    ),
    new LayerData(
      toByteString("Clothes", true),
      [
        //2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0
        {
          "outpoint": toByteString("2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Yellow Clothes",
            "description": "clothes that are yellow",
            "more arbitrary info": "more yellow"
          }), true),
        },
        {
          "outpoint": toByteString("f1be2424f057ab6e410b8bbcc715d8e5b80597e407a157e00730b3acb1cb0f90_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Light Blue Clothes",
            "description": "clothes that are Light Blue",
            "more arbitrary info": "more Light Blue"
          }), true),
        },
        {
          "outpoint": toByteString("513b9109f7a129886f040f88d4500bafb1df511d4abdf53064ad02a0846fa4c7_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Green Clothes",
            "description": "clothes that are Green",
            "more arbitrary info": "more Green"
          }), true),
        },
        // ...
      ],
    ),
    new LayerData(
      toByteString("Eyes", true),
      [
        // {
        //   "outpoint": toByteString("2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0", true),
        //   "data": toByteString(JSON.stringify({
        //     "name": "Light Blue",
        //     "description": "eyes that are light blue",
        //     "more arbitrary info": "more light blue"
        //   }), true),
        // },
        {
          "outpoint": toByteString("0e2e17c03c02f616c675ed04685dc54d03de059ca1223d0c8f3bda407b080492_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Blue Eyes",
            "description": "eyes that are Blue",
            "more arbitrary info": "more Blue"
          }), true),
        },
        {
          "outpoint": toByteString("2d6dc8cad9a7537c44bc267022923182e8790b396727d07effaf654969442485_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Red Eyes",
            "description": "eyes that are Red",
            "more arbitrary info": "more Red"
          }), true),
        },
        // ...
      ],
    )
  ]

  //TODO: Add a hook to get the contract details from the origin 1 time if theyre not populated 
  //      and save them in initalSupply, etc... 

  const handleDeploy = async () => {

    const payoutScript = bsv.Script.buildPublicKeyHashOut(connectedPayAddress!)
    console.log("Payout Script:", payoutScript);
    const payout =
      new bsv.Transaction.Output({ script: payoutScript, satoshis: priceInSatoshis })
    console.log("Payout:", payout);
    const payoutHex = payout.toBufferWriter()
      .toBuffer()
      .toString('hex');
    console.log("PayoutHex:", payoutHex);

    //TODO: Derive this from the layers object but make it match the CollectionItem Spec
    //this _subTypeData is arbitrary but searchable. 
    //Include any data that the collection would want to be queried by
    // I should build this autmatically from the layers information. 
    const _subTypeData = {
      description: "This is a test class",
      layers: [
        //Layers
        {
          name: "Accessory",
          assets: [
            //Layer Items
            {
              "outpoint": "d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0",
              "name": "Venom",
              "description": "venom accessory",
              "more arbitrary info": "venom accessory"
            },
            {
              "outpoint": "6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0",
              "name": "Purple",
              "description": "purple accessory",
              "more arbitrary info": "purple accessory"
            },
            {
              "outpoint": "0538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0",
              "name": "Pink",
              "description": "Pink accessory",
              "more arbitrary info": "Pink accessory"
            }
          ]
        },
        {
          name: "Clothes",
          assets: [
            {
              "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
              "name": "Yellow Clothes",
              "description": "Sun clothes",
              "more arbitrary info": "Yellow Clothes"
            },
            // ...
          ],
        },
        {
          name: "Eyes",
          assets: [
            {
              "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
              "name": "Yellow Eyes",
              "description": "Sun colored Eyes",
              "more arbitrary info": "This is a cool Eyes."
            },
            // ...
          ],
        }
      ]
    }


    const _royalties = [
      { type: "address", destination: "1EqiQhoQJdyNEvpmX9u2bCKwMLdBRNz1q", percentage: "0.00" }
    ]
    const metadata = {
      app: 'RareDropper',
      type: 'ord',
      contractId: artifact.md5,
      name: "We So Locky",
      subType: 'collection',
      subTypeData: JSON.stringify(_subTypeData),
      royalties: JSON.stringify(_royalties),
    };

    console.log(JSON.stringify(metadata));
    const map = new bsv.Script('');
    map.add(Buffer.from("SET"));
    for (const [key, value] of Object.entries(metadata)) {
      map.add(Buffer.from(key))
      map.add(Buffer.from(value))
    }

    const response = await fetch(coverPhotoUrl);
    const data = await response.arrayBuffer();

    // console.log("Content:", )
    const inscriptionScript = ShruggrLib.buildInscriptionScript({
      content: Buffer.from(data),
      contentType: 'image/png'
    },
      {
        "1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5": map.toBuffer()
      }
    )
    const deployable = new RecGCFull(
                          toByteString(metadata.name, true), //the ordinals name 
                          RecGCBase.buildLayerNames(layers), //constructed names for the layers
                          RecGCBase.buildLayers(layers),  // the layers object
                          7n, // number of NFTs to mint into the collection
                          0n, // This is a difficuty number in bits. Set to 0 for no POW. 4 of these difficuly = 1 POW20
                          toByteString(''),//toByteString(payoutHex), //the address & amount to pay when minting
                          839009n, //the current height of the chain to enforce the starting point on locks 
                          4n, // number of blocks to remain locked 
                          1000n, // number of satoshis that must be locked
                          BigInt(inscriptionScript.length)) // number of bytes in the inscription

    console.log("Deployable:", deployable);
    await deployable.connect(signer!)

    let deployResponse = await deployable.inscribeWithNoOp(inscriptionScript)
    const rawTx = deployResponse.toBuffer();
    console.log(deployResponse.id, rawTx);
    setTimeout(async () => {
      axios.get(`https://ordinals.gorillapool.io/api/tx/${deployResponse.id}/submit`)
    }, 5000)
    setCurrentOrigin(deployResponse.id + "_0")
  };

  const handleMint = async () => {
    setIsModalOpen(true);
    try {
      await wallet.getPaymentUtxos()
      await wallet.getBalance();
      const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
      const response = await axios.get(url);
      console.log("This is the the response to Latest", response.data);
      if (response.data.spend && response.data.spend !== "") {
        //This is the signal that the mint is complete. 
        console.log("spend is not null");
        alert("Try refreshing the balance in yours wallet. Click on the main balance, where the panda used to be.")
        return;
      }
      console.log("setting current location", response.data.outpoint);
      setCurrentLocation(response.data.outpoint)
      // const inscPrefix = Buffer.from('0063036f726451', 'hex')

      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("Contract Instance initialized...", contractInstance.layers);
      setRemainingSupply(Number(contractInstance.supply));
      localStorage.setItem("remainingSupply", Number(contractInstance.supply).toString());

      let serviceFeeOutput = new bsv.Transaction.Output({
        script: bsv.Script.fromAddress(new bsv.Address("1o3PJCEv5pZpuqtgR6zefL28ZqdEtWDnm")),
        satoshis: 1000
      })
      let height = await fetchBlockHeight();
      console.log({height});
      await contractInstance.connect(signer!)
      const nonce = toByteString('');
      const selectionData = contractInstance.buildSelectionData(layers, nonce);
      console.log({ selectionData });
      const buyerScript = bsv.Script.buildPublicKeyHashOut(connectedOrdiAddress!)
      const identityPKH = connectedIdentityAddress!.hashBuffer.toString('hex')
      const mintResponse = await contractInstance.methods.mint(
        nonce, // user generated random bytestring. If the difficulty is higher than 0 this needs to be something that when hashed with the previous txid (Little Endian) it creates the output with the specified proof of work. 
        toByteString(buyerScript.toHex()),
        toByteString(identityPKH), // public key hash that these satoshis will be locked to
        selectionData,
        toByteString(serviceFeeOutput.toBufferWriter().toBuffer().toString('hex')),
        {
          changeAddress: connectedPayAddress,
          pubKeyOrAddrToSign: connectedPayAddress,
          partiallySign: true,
          lockTime: height
        })

      const rawTx = mintResponse.tx.toBuffer();
      console.log(mintResponse.tx.id, rawTx);
      setMintResponse("https://gorillapool.io/content/" + mintResponse.tx.id);

      setTimeout(async () => {
        axios.get(`https://ordinals.gorillapool.io/api/tx/${mintResponse.tx.id}/submit`)
      }, 5000)
    } catch (error) {
      setIsModalOpen(false);
      console.error('Error Minting inscription:', error);
      alert("Something went wrong. Refresh the page and try again. Error message: " + error);
      // Handle the error as needed
    }
  };

  const fetchBlockHeight = async () => {
    try {
      const response = await fetch('https://api.whatsonchain.com/v1/bsv/main/chain/info');
      const data = await response.json();
      console.log('Fetched chain info', data);
      setCurrentHeight(Number(data.blocks));
      return data.blocks;
    } catch (error) {
      console.error('Error fetching block height:', error);
    }
  };

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  const handleSignMessage = async () => {
    if (!messageToSign) {
      alert("There was no message to sign!");
      return;
    }
    const signRes = await wallet.signMessage({ message: messageToSign });
    if (signRes) setSignedMessage(signRes);
  };

  return (
    <>

      <div className="App bg-dark relative isolate overflow-hidden text-white">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> ← Go Back, Pussy </div>
          </div>
          <div className="">
            {!connectedPayAddress &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {connectedPayAddress &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <div className="App-header h-full">
        
          <div className="">
            <Header></Header>
          </div>
            
          <div className="">
            <div className="pb-24 rounded-lg">
              
              <div className="flex-1 rounded-lg px-8 bg-gray-800">
                <div className="flex-1 md:grid md:grid-cols-5 my-2  rounded-lg p-16">
                  <div className="flex items-center md:col-span-3">
                    <div className="bg-gray-100 rounded-xl">
                      <img src={coverUrl}
                          alt="Collection Cover" 
                          className="mx-auto h-full w-full" 
                           />
                    </div>
                  </div>
                  <div className=" flex-1 flex flex-col md:col-span-2 justify-between flex-grow py-12 px-2 lg:px-4 xl:px-8">
                    <section
                      aria-labelledby="summary-heading"
                      className="mt-16 w-full rounded-lg bg-gray-700 px-4 py-6 sm:p-4 lg:col-span-5 lg:mt-0 lg:p-8"
                    >
                      <h2 id="summary-heading" className="text-lg font-medium">
                      {collectionName}
                      </h2>

                      <dl className="mt-6 space-y-4">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm text-gray-200">Price</dt>
                          <dd className="text-sm font-medium text-gray-200">₿ {priceInSatoshis / 100000000}</dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className=" md:text-xs flex items-center text-sm text-gray-200">
                            <span>Mint Fee</span>
                            {/* <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">What is this?</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a> */}
                          </dt>
                          <dd className="text-sm font-medium text-gray-200">{serviceFeeInSatoshis} <span className="text-xs">sats</span></dd>
                        </div>
                        {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                          <dt className="flex text-sm text-gray-600">
                            <span>Tax estimate</span>
                            <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Learn more about how tax is calculated</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                          </dt>
                          <dd className="text-sm font-medium text-gray-900">$8.32</dd>
                        </div> */}
                        {/* <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className="text-base font-medium text-gray-200">Order total</dt>
                          <dd className="text-base font-medium text-gray-200">₿ {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}</dd>
                        </div> */}
                      </dl>

                      <div className="mt-6">
                      {!connectedPayAddress &&
                        <PandaConnectButton onClick={handleConnect} />
                      }
                      {connectedPayAddress &&
                        <>
                          <Button onClick={handleDeploy} variant="secondarySquare" className="w-full font-medium" style={{ color: "black" }}>
                              Deploy 
                          </Button>
                          <Button onClick={handleMint} variant="gradientSquare" className="w-full font-medium" style={{ color: "black" }}>
                              Mint For {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}
                          </Button>
                        </>
                      }
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer></Footer>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        lastTxid={mintResponse!}
      />
    </>
  );
};
