import { useState, useEffect } from 'react';

// Define the return type of the hook
export interface ContentTypeResult {
  contentType: string | null;
  loading: boolean;
}

export function useContentType(url: string): ContentTypeResult {
    const [contentType, setContentType] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchContentType = async () => {
            try {
                const response = await fetch(url, { method: 'HEAD' });
                if (response.ok) {
                    const type = response.headers.get('Content-Type');
                    setContentType(type);
                } else {
                    throw new Error('Failed to fetch content type');
                }
            } catch (error) {
                console.error('Error fetching content type:', error);
                setContentType(null); // Ensure content type is null if there's an error
            } finally {
                setLoading(false);
            }
        };

        fetchContentType();
    }, [url]);

    return { contentType, loading };
}
