import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import pandaIcon from "../assets/panda-icon.svg";
import { PandaConnectButton } from "../components/PandaConnectButton";
import {
  Addresses,
  SignedMessage,
  usePandaWallet,
} from "panda-wallet-provider";
import { LayerData, RecGCBase } from "../contracts/rec-gc-base";
import { RecGCFull } from "../contracts/rec-gc-full";
import artifact from '../../artifacts/rec-gc-full.json';
import { PandaSigner } from "scrypt-ts/dist/bsv/signers/panda-signer"
import { OrdiProvider } from "scrypt-ord";
import { ByteString, FixedArray, Signer, bsv, toByteString } from "scrypt-ts";
import axios from 'axios';
import { Button } from './../components/styled/PrimaryButton';
import Header from "../components/styled/Header";
import Footer from "../components/styled/Footer";
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Modal from "../components/mint/WaitingModal";
import { ShruggrLib } from "../contracts/shruggr-lib";
import GalleryGrid from "../components/GalleryGrid";

RecGCFull.loadArtifact(artifact);

export const MintDeltas = () => {
  const wallet = usePandaWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [connectedPayAddress, setConnectedPayAddress] = useState<bsv.Address | undefined>(undefined)
  const [connectedOrdiAddress, setConnectedOrdiAddress] = useState<bsv.Address | undefined>(undefined)
  const [signer, setSigner] = useState<Signer | undefined>(undefined)
  const [initialSupply, setInitialSupply] = useState(0)
  const [remainingSupply, setRemainingSupply] = useState(0);
  const [coverUrl, setCoverUrl] = useState<string>('')
  const [currentOrigin, setCurrentOrigin] = useState<string>('217c6fa01e2d59e04138e117198635f750685aca771d1d3e5c808e4bf694df78_0');
  const [currentLocation, setCurrentLocation] = useState<string>('217c6fa01e2d59e04138e117198635f750685aca771d1d3e5c808e4bf694df78_0');
  const [priceInSatoshis, setPriceInSatoshis] = useState<number>(1000)
  const [serviceFeeInSatoshis, setServiceFeeInSatoshis] = useState<number>(1000)
  const [totalSupply, setTotalSupply] = useState<number>(0)
  const [collectionName, setCollectionName] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [finalTxId, setFinalTxId] = useState<string | null>(''); // State to store the mint response
  const [message, setMessage] = useState<string | null>(null)
  const location = useLocation();
  const [signedMessage, setSignedMessage] = useState<
    SignedMessage | undefined
  >();

  const coverPhotoUrl = 'https://ordinals.gorillapool.io/content/f3f81f2952e18963e859d673273ee6d1df58f711eb69777fecfee9bad649ff75_0';
  
  useEffect(() => {
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(location.search);
    // Get the value of the 'origin' parameter
    const originQueryParam = queryParams.get('origin');
    if (originQueryParam) {
      // Set the state to the query string value
      console.log("Found Origin:", originQueryParam)
      setCurrentOrigin(originQueryParam);
    }
  }, [location]);

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo);

      if(contractInstance.payOut !== toByteString("")){
        const price = 
          bsv.Transaction.Output.fromBufferReader(
            new bsv.encoding.BufferReader(Buffer.from(contractInstance.payOut, 'hex'))
        )
        console.log(price.satoshis);
      }
      console.log("setting remaining supply", Number(contractInstance.supply))
      localStorage.setItem("remainingSupply", "0");
      setRemainingSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin, currentLocation, finalTxId])


  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}?script=true`;
    axios.get(url).then((response) => {
      setCoverUrl("https://ordinals.gorillapool.io/content/" + currentOrigin);
      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("setting total supply", contractInstance.supply.toString())
      localStorage.setItem("maxSupply", contractInstance.supply.toString());
      setTotalSupply(Number(contractInstance.supply));
    }).catch((error) => console.log({ error }));
  }, [currentOrigin])

  useEffect(() => {
    const url = `https://ordinals.gorillapool.io/api/txos/${currentOrigin}/`;
    axios.get(url).then((response) => {
      setCollectionName(response.data.origin.data.map.name);
    }).catch((error) => console.log({ error }));
  }, [])



  useEffect(() => {
    if (!wallet.connect) {
      return;
    }
  
    const connectWallet = async () => {
      try {
        const key = await wallet.connect();
        setPubKey(key);
  
        const signer = new PandaSigner(new OrdiProvider()); // <---- use `PandaSigner`
        setSigner(signer);
  
        const { isAuthenticated, error } = await signer.requestAuth();
        if (!isAuthenticated) {
          throw new Error(`Unauthenticated: ${error}`);
        }
  
        setConnectedPayAddress(await signer.getDefaultAddress());  // <----
        setConnectedOrdiAddress(await signer.getOrdAddress());     // <----
        await handleGetAddresses();
      } catch (error) {
        alert("Something went wrong signing in. " + error);
      }
    };
  
    connectWallet();
  }, [wallet]);
  
  const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
  const layers: LayerData[] = [
    new LayerData(
      toByteString("Background", true),
      [
        {
          "outpoint": toByteString("980709e82ebe77c8793ba1d7108dc27d472ad156b7d57e552cfddd37f5c954db_0", true),
          "data": toByteString(JSON.stringify({
            "name": "White",
            "description": "A White Background",
           // "more arbitrary info": "venom accessory"
          }), true)
        },
        {
          "outpoint": toByteString("ccdf1472b0b20e2278377d97091f9d70588e680ea573e59c8f0142f20bb9e0c6_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Charcoal",
            "description": "A Charcoal Background",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("9a2f7e1b682d671888dafd8238be3c2cafdd2f66fe7fc7106e6f54e28675568e_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Gray",
            "description": "A Gray Background",
          }), true),
        },
        {
            "outpoint": toByteString("a6e1fc1454266093c364bc28abab984483c78912262ac21aa07fbe8a396fa793_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Black",
              "description": "A Black Background",
            }), true),
        },
        {
            "outpoint": toByteString("8b97276feb21cb25ace97c784fbe1b9b07b2f054130023e4ad4c49ec8e42a7cb_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Light Gray",
              "description": "A Light Gray Background",
            }), true),
        }
      ]
    ),
    new LayerData(
      toByteString("Glow", true),
      [
        {
          "outpoint": toByteString("43833e5e667fc58111be04b23dc81063d2409a490248fde1e918d3857a57c06e_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Noisey Red",
            "description": "A Noisey Red Glow",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("24c95ec6388a1ff8bb1fc4d9aa60b7ba48d4aa1e5c008165f3e7373b02be030d_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Noisey Orange Cyan",
            "description": "A Noisey Orange and Cyan Glow",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("44ff054ba4b0c7da3b3f7dedba04ab11b23362f6d345e5d9fb0657252f275710_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Noisey Salmon Pink",
            "description": "A Noisey Salmon and Pink Glow with Hard Edges",
          }), true),
        },
        {
            "outpoint": toByteString("a1d50df2673ed2e13b0e5971add1f63ea17ec82e78ecaee6f242b6e5f583164b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Noisey Ice",
              "description": "A Noisey Ice Glow",
            }), true),
          },
          {
            "outpoint": toByteString("45b87daf1ef35721044bae37669d27766e88f9a61e5c1f3d26af53ef02e6e7e5_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Cyan",
              "description": "A Cyan Glow",
            }), true),
          },
          {
            "outpoint": toByteString("68adad601549361475dfe8726d6265519b1d322ec5ab118d6a42d89b0265a973_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Purple",
              "description": "A Purple Glow",
            }), true),
          },
          {
            "outpoint": toByteString("d008637b6cdd251f0a75881a49d23c3cae3527579bb6c9e9fe4bb3f3d2619c47_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Extra",
              "description": "An Extra Glowie Glow",
            }), true),
          },
          {
            "outpoint": toByteString("a21479d651fa7604e3d510a3b50a1c516874ec109958a283160276f1548e9f24_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Noisey Green",
              "description": "A Noisey Green Glow",
            }), true),
          },
          {
            "outpoint": toByteString("1c3657260dce515e9eb74bb25ec4220a0e05cd4436e730baed7fb79a04d0eaad_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Salmon",
              "description": "A Salmon Glow",
            }), true),
          },
          {
            "outpoint": toByteString("91c8eeb402434abb8f7b49d4b833973f19f5e20068fbcb3aff8f7779f5e47fdf_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Fire",
              "description": "A Two Tone Fire Glow",
            }), true),
          },
          {
            "outpoint": toByteString("64765843dcce8b7711f7cbb7e6e3118edcd859d1eb1acf3e682e35e5a083519e_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Red",
              "description": "A Red Glow",
            }), true),
          },
          {
            "outpoint": toByteString("eea9e2e6a4fb9e0b272ebb558b1d87f50d8910b81f89cad5aace3c1d3dfb30e3_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Purple Bloc",
              "description": "A Purple Bloc Glow",
            }), true),
          },
          {
            "outpoint": toByteString("5e5f3a375fb65263b6f9e80643c08d86e78067021ec7a6505117547b3084f1f3_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Noisey Purple",
                "description": "A Noisey Purple Glow",
            }), true),
          },
          {
            "outpoint": toByteString("d6836826ad9a3c7b71b5ceb74d073a989695a5140a3663f2a8e5076bde5f1677_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Noisey Orange",
                "description": "A Orange Purple Glow",
            }), true),
          },
          {
            "outpoint": toByteString("ef88e34f982a146473861fd82f4e42136051229731970b5917355a19a0125a15_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Two Tone Orange Purple",
                "description": "A Orange Purple Glow",
            }), true),
          },
          {
            "outpoint": toByteString("91ed01a47c2f34d3871053be924bf1e09f42412cdb1bde59b8578bff4f6503b1_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Noisey Cyan",
                "description": "A Noisey Cyan Glow",
            }), true),
          },
          {
            "outpoint": toByteString("709c559bfdd0104b0694d6a4490ef4c1b25aff41b9700dcd7541b82946564b2f_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Ice",
                "description": "An Ice Glow",
            }), true),
          },
          {
            "outpoint": toByteString("19e875708da7e81e30730efe7c47661bdca7b150a1c2bf93ce64d9c7f48ca435_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Interdimensional",
                "description": "An Interdimensional Glow",
            }), true),
          },
          {
            "outpoint": toByteString("2743e89a0c18c7a5fee0fad548f81856c3e5dfadff44b5298819162941757d44_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Noisey Purple Blue",
                "description": "A Noisey Purple Blue Glow",
            }), true),
          },
          {
            "outpoint": toByteString("8e219c5ca85e032972822fd19121092ebcc14da00b5c472d2e5ab3644620f5cf_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Noisey Pink",
                "description": "A Noisey Pink Glow",
            }), true),
          },
          {
            "outpoint": toByteString("35466b51076c2b3ba27af028fa417be0328ddb2b507f4e29935d9cbb9cb9802a_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Noisey Salmon",
                "description": "A Noisey Salmon Glow",
            }), true),
          },
          {
            "outpoint": toByteString("dd567bcf03746259da4f41b523f18ef1428f8a6be33611052274fe2163fe442a_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Noisey Orange Purple",
                "description": "A Noisey Orange Purple Glow",
            }), true),
          },
          {
            "outpoint": toByteString("1b5e66b93fe439b24d73f409ecd96d74cd8adb4969b3b1d8ea3340a87b23d573_0", true),
            "data": toByteString(JSON.stringify({
                "name": "Green",
                "description": "A Green Glow",
            }), true),
          }
      ]
    ),
    new LayerData(
      toByteString("Delta", true),
      [
        {
          "outpoint": toByteString("d6911a9029df38d9b8bb6c2f66d5e7a9ecb0ee0f727cf2d67062d53e37632b8a_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Magenta",
            "description": "A magenta Delta",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("b69c4456033aee96afaba5aaa181600d36999f7c62e3ba315f814465092006e1_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Charcoal",
            "description": "A Charcoal Delta",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("cb6c1e66a5ab1c9ae920dca369861e8fda422bf1927d927659c95303b6b30448_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Cyan",
            "description": "A Cyan Delta",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("3d2b4b2cebd66ecdede4fb4dbbad6e7c65b67fed94cdae29af67abee087ec0a9_0", true),
          "data": toByteString(JSON.stringify({
            "name": "White",
            "description": "A White Delta",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("2b9bb23ce55f9932fabecb0bd1927c6cc0c8672ee550c2bc470f9b9099760674_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Tan",
            "description": "A Tan Delta",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
          "outpoint": toByteString("58dccb8b50a04f04307875420bbb9e394e5786e28f0acd4d677a289163035436_0", true),
          "data": toByteString(JSON.stringify({
            "name": "Red",
            "description": "A Red Delta",
           // "more arbitrary info": "venom accessory"
          }), true),
        },
        {
            "outpoint": toByteString("55f07c09a57ceeca1143c208e22cc85edff9da85e89f372d9982b5c7f428e11f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Gray",
              "description": "A Gray Delta",
             // "more arbitrary info": "venom accessory"
            }), true),
          },
          {
            "outpoint": toByteString("3299123e760072719608573a93ee98385f207f685b08e61bbd90df0f8e020d6f_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Yellow",
              "description": "A Yellow Delta",
             // "more arbitrary info": "venom accessory"
            }), true),
          },
          {
            "outpoint": toByteString("752cced7ee14a178458085cf9b734fa466bbbfce9defedcb09260f4597a58c6b_0", true),
            "data": toByteString(JSON.stringify({
              "name": "Black",
              "description": "A Black Delta",
             // "more arbitrary info": "venom accessory"
            }), true),
          }
      ]
    )
  ]
  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open(
        "https://github.com/Panda-Wallet/panda-wallet#getting-started-alpha",
        "_blank"
      );
      return;
    }
    try{
      const key = await wallet.connect();
      setPubKey(key);
      const signer = new PandaSigner(new OrdiProvider())   // <---- use `PandaSigner`
      setSigner(signer);
      const { isAuthenticated, error } = await signer.requestAuth()
      if (!isAuthenticated) {
        throw new Error(`Unauthenticated: ${error}`)
      }
      setConnectedPayAddress(await signer.getDefaultAddress())  // <----
      setConnectedOrdiAddress(await signer.getOrdAddress())     // <----
      await handleGetAddresses();
    } catch(error){
      alert("Something went wrong sigining in. " + error)
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false)
    setFinalTxId(null)
  }

  //TODO: Add a hook to get the contract details from the origin 1 time if theyre not populated 
  //      and save them in initalSupply, etc... 

  const handleDeploy = async () => {

    const payoutScript = bsv.Script.buildPublicKeyHashOut(connectedPayAddress!)
    console.log("Payout Script:", payoutScript);
    const payout =
      new bsv.Transaction.Output({ script: payoutScript, satoshis: priceInSatoshis })
    console.log("Payout:", payout);
    const payoutHex = payout.toBufferWriter()
      .toBuffer()
      .toString('hex');
    console.log("PayoutHex:", payoutHex);

    //TODO: Derive this from the layers object but make it match the CollectionItem Spec
    //this _subTypeData is arbitrary but searchable. 
    //Include any data that the collection would want to be queried by
    // I should build this autmatically from the layers information. 
    const _subTypeData = {
      description: "This is the order of the Delta",
      attributes: [
        // //Layers
        // {
        //   name: "Accessory",
        //   assets: [
        //     //Layer Items
        //     {
        //       "outpoint": "d05dcc77ab43aa93612b21f78f838d04ec8dcc10551bcd43be07d38dec84856e_0",
        //       "name": "Venom",
        //       "description": "venom accessory",
        //       "more arbitrary info": "venom accessory"
        //     },
        //     {
        //       "outpoint": "6645df4af7fdbd148b84290f9543924b1d845da1b8aebee0e855b22db55b38b9_0",
        //       "name": "Purple",
        //       "description": "purple accessory",
        //       "more arbitrary info": "purple accessory"
        //     },
        //     {
        //       "outpoint": "0538dbb56d5094fbfaad0b9684e5f87de246ac63b9f4ea99b8362ba63e0ce6d87_0",
        //       "name": "Pink",
        //       "description": "Pink accessory",
        //       "more arbitrary info": "Pink accessory"
        //     }
        //   ]
        // },
        // {
        //   name: "Clothes",
        //   assets: [
        //     {
        //       "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
        //       "name": "Yellow Clothes",
        //       "description": "Sun clothes",
        //       "more arbitrary info": "Yellow Clothes"
        //     },
        //     // ...
        //   ],
        // },
        // {
        //   name: "Eyes",
        //   assets: [
        //     {
        //       "outpoint": "2d19e56fefcc1edcd60675735c6b120ee69a2079c10c64dfd49e66db00eb5624_0",
        //       "name": "Yellow Eyes",
        //       "description": "Sun colored Eyes",
        //       "more arbitrary info": "This is a cool Eyes."
        //     },
        //     // ...
        //   ],
        // }
      ]
    }


    const _royalties = [
      { type: "address", destination: "1EqiQhoQJdyNEvpmX9u2bCKwMLdBRNz1q", percentage: "0.03" }
    ]
    const metadata = {
      app: 'RareDropper',
      type: 'ord',
      contractId: artifact.md5,
      name: "Order Of The Delta",
      subType: 'collection',
      subTypeData: JSON.stringify(_subTypeData),
      royalties: JSON.stringify(_royalties),
    };

    console.log(JSON.stringify(metadata));
    const map = new bsv.Script('');
    map.add(Buffer.from("SET"));
    for (const [key, value] of Object.entries(metadata)) {
      map.add(Buffer.from(key))
      map.add(Buffer.from(value))
    }

    const response = await fetch(coverPhotoUrl);
    const data = await response.arrayBuffer();

    // console.log("Content:", )
    const inscriptionScript = ShruggrLib.buildInscriptionScript({
      content: Buffer.from(data),
      contentType: 'image/png'
    },
      {
        "1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5": map.toBuffer()
      }
    )
    const deployable = new RecGCFull(
                          toByteString(metadata.name, true), //the ordinals name 
                          RecGCBase.buildLayerNames(layers), //constructed names for the layers
                          RecGCBase.buildLayers(layers),  // the layers object
                          199n, // number of NFTs to mint into the collection
                          0n, // This is a difficuty number in bits. Set to 0 for no POW. 4 of these difficuly = 1 POW20
                          toByteString(payoutHex), //the address & amount to pay when minting
                          0n, //the current height of the chain to enforce the starting point on locks 
                          0n, // number of blocks to remain locked 
                          0n, // number of satoshis that must be locked
                          BigInt(inscriptionScript.length)) // number of bytes in the inscription

    console.log("Deployable:", deployable);
    await deployable.connect(signer!)

    let deployResponse = await deployable.inscribeWithNoOp(inscriptionScript)
    const rawTx = deployResponse.toBuffer();
    console.log(deployResponse.id, rawTx);
    setTimeout(async () => {
      axios.get(`https://ordinals.gorillapool.io/api/tx/${deployResponse.id}/submit`)
    }, 5000)
    setCurrentOrigin(deployResponse.id + "_0")
  };

  const handleMint = async () => {
    setMessage(null);
    setIsModalOpen(true);
    try {
      setFinalTxId('');
      await wallet.getPaymentUtxos()
      await wallet.getBalance();
      const url = `https://ordinals.gorillapool.io/api/inscriptions/${currentOrigin}/latest?script=true`;
      const response = await axios.get(url);
      console.log("This is the the response to Latest", response.data);
      if (response.data.spend && response.data.spend !== "") {
        //This is the signal that the mint is complete. 
        console.log("spend is not null");
        setMessage("Try refreshing the balance in yours wallet by clicking balance display")
        return;
      }
      console.log("setting current location", response.data.outpoint);
      setCurrentLocation(response.data.outpoint)
      // const inscPrefix = Buffer.from('0063036f726451', 'hex')

      const utxo = {
        txId: response.data.txid,
        outputIndex: response.data.vout,
        satoshis: 1,
        script: Buffer.from(response.data.script, 'base64').toString('hex')
      }
      const contractInstance = RecGCFull.fromInscriptionUTXO<RecGCFull>(utxo)
      console.log("Contract Instance initialized...", contractInstance.layers);
      setRemainingSupply(Number(contractInstance.supply));
      localStorage.setItem("remainingSupply", Number(contractInstance.supply).toString());

      let serviceFeeOutput = new bsv.Transaction.Output({
        script: bsv.Script.fromAddress(new bsv.Address("1EqiQhoQJdyNEvpmX9u2bCKwMLdBRNz1q")),
        satoshis: 1000
      })

      await contractInstance.connect(signer!)
      const nonce = toByteString('');
      const selectionData = contractInstance.buildSelectionData(layers, nonce);
      console.log({ selectionData });
      const buyerScript = bsv.Script.buildPublicKeyHashOut(connectedOrdiAddress!)
      setMessage("Signing");
      const mintResponse = await contractInstance.methods.mint(
        nonce, // user generated random bytestring. If the difficulty is higher than 0 this needs to be something that when hashed with the previous txid (Little Endian) it creates the output with the specified proof of work. 
        toByteString(buyerScript.toHex()),
        toByteString(''), // public key hash that these satoshis will be locked to
        selectionData,
        toByteString(serviceFeeOutput.toBufferWriter().toBuffer().toString('hex')),
        {
          changeAddress: connectedPayAddress,
          pubKeyOrAddrToSign: connectedPayAddress,
          partiallySign: true
        })

      const rawTx = mintResponse.tx.toBuffer();
      console.log(mintResponse.tx.id, rawTx);
      await setTimeout(async () => {
        setMessage("Broadcasting");
        let injectResponse = await axios.get(`https://ordinals.gorillapool.io/api/tx/${mintResponse.tx.id}/submit`)
        console.log({injectResponse})
        await sleep(3000)
        setFinalTxId(mintResponse.tx.id);
      }, 5000)


    } catch (error) {
      let er = error as Error;
      console.error('Error Minting inscription:', er);
      if(er.toString()==="User dismissed the request!"){
        setMessage(null);
        setIsModalOpen(false);
      } else if(er.toString().includes('mempool')) {
        setMessage("w0mp w0mp. someone beat you too it")
      }else{
        setMessage(er.toString())
      }
      //alert("Something went wrong. Refresh the page and try again. Error message: " + error);
      // Handle the error as needed
    }
  };

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  const handleSignMessage = async () => {
    if (!messageToSign) {
      alert("There was no message to sign!");
      return;
    }
    const signRes = await wallet.signMessage({ message: messageToSign });
    if (signRes) setSignedMessage(signRes);
  };

  return (
    <>

      <div className="App bg-dark relative isolate overflow-hidden text-white">
        <div className="pb-24 sm:pb-0 menu-bar flex py-4 px-8">
          <div className="flex-grow text-left items-center items-justify-center ">
            <div> <a href="/">← Go Back, Pussy </a></div>
          </div>
          <div className="">
            {!addresses &&
              <PandaConnectButton onClick={handleConnect} />
            }
            {addresses &&
              <div className="p-4 bg-gray-900 rounded-xl">{connectedOrdiAddress?.toString().slice(0, 5)} ... {connectedOrdiAddress?.toString().slice((connectedOrdiAddress?.toString().length) -5)}</div>
            }
          </div>
        </div>
        
        <div className="App-header h-full">
        
          <div className="">
            <Header 
              initialRemainingSupply={0}
              initialMaxSupply={totalSupply}
              inputPrice={priceInSatoshis}></Header>
          </div>
            
          <div className="max-w-7xl">
            <div className="pb-24 rounded-lg">
              
              <div className="flex-1 rounded-lg px-8 bg-dark ">
                <div className="flex-1 md:grid md:grid-cols-5 my-2  rounded-lg p-16">
                  <div className="flex items-center md:col-span-3">
                    <div className="bg-gray-100 rounded-xl ring-yellow-500 ring-2 overflow-hidden">
                      <img src={coverUrl}
                          alt="Collection Cover" 
                          className="mx-auto h-full w-full" 
                           />
                    </div>
                  </div>
                  <div className=" flex-1 flex flex-col md:col-span-2 justify-between flex-grow py-12 px-2 lg:px-4 xl:px-8 ">
                    <section
                      aria-labelledby="summary-heading"
                      className="mt-16 w-full rounded-lg bg-gray-900 px-4 py-6 sm:p-4 lg:col-span-5 lg:mt-0 lg:p-8 ring-gray-500 ring-2"
                    >
                      <h2 id="summary-heading" className="text-lg font-medium">
                      {collectionName}
                      </h2>

                      <dl className="mt-6 space-y-4">
                        <div className="flex items-center justify-between">
                          <dt className="text-sm text-gray-200">Price</dt>
                          <dd className="text-sm font-medium text-gray-200">₿ {priceInSatoshis / 100000000}</dd>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className=" md:text-xs flex items-center text-sm text-gray-200">
                            <span>Mint Fee</span>
                            {/* <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">What is this?</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a> */}
                          </dt>
                          <dd className="text-sm font-medium text-gray-200">{serviceFeeInSatoshis} <span className="text-xs">sats</span></dd>
                        </div>
                        {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                          <dt className="flex text-sm text-gray-600">
                            <span>Tax estimate</span>
                            <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Learn more about how tax is calculated</span>
                              <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                            </a>
                          </dt>
                          <dd className="text-sm font-medium text-gray-900">$8.32</dd>
                        </div> */}
                        {/* <div className="flex items-center justify-between border-t border-gray-600 pt-4">
                          <dt className="text-base font-medium text-gray-200">Order total</dt>
                          <dd className="text-base font-medium text-gray-200">₿ {(priceInSatoshis + serviceFeeInSatoshis) / 100000000}</dd>
                        </div> */}
                      </dl>

                      <div className="mt-6">
                      {!addresses &&
                        <PandaConnectButton onClick={handleConnect} />
                      }
                      {addresses &&
                        <>
                          {/* <Button onClick={handleDeploy} variant="secondarySquare" className="w-full font-medium" style={{ color: "black" }}>
                              Deploy 
                          </Button> */}
    
                          <Button onClick={() => {}} variant="gradientSquare" className="w-full font-medium" style={{ color: "black" }}>
                             Minted Out
                          </Button>
                        </>
                      }
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GalleryGrid
            parentID={currentOrigin} 
            address={connectedOrdiAddress? connectedOrdiAddress.toString() : "" } 
            collectionName={collectionName}/>
      </div>
      
      <Footer></Footer>
      <Modal
        isOpen={isModalOpen}
        onClose={() => handleModalClose()}
        lastTxid={finalTxId!}
        inputMessage={message? message : 'Minting'}
      />
    </>
  );
};
