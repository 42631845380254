import React, { useEffect, useRef, useState } from 'react';

// Define props interface for type safety
interface LazyIframeProps {
  src: string;
  title: string;
}

const LazyIframe: React.FC<LazyIframeProps> = ({ src, title }) => {
  const [loaded, setLoaded] = useState(false);
  const iframeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      console.log("Intersection Ratio:", entries[0].intersectionRatio); // Debugging
      if (entries[0].isIntersecting && !loaded) {
        console.log("Loading iframe...");
        setLoaded(true);
      }
    }, {
      threshold: 0.1,
      rootMargin: '1000px'
    });

    if (iframeRef.current) {
      observer.observe(iframeRef.current);
    }

    return () => {
      if (iframeRef.current) {
        observer.unobserve(iframeRef.current);
      }
    };
  }, [loaded]);

  return (
    <div ref={iframeRef} className="iframe-container">
      {loaded && (
        <iframe
          src={src}
          title={title}
          className="absolute top-0 left-0 w-full h-full"
          frameBorder="0"
          style={{ height: "100%", width: "100%" }} // Ensure iframe takes full container space
        />
      )}
    </div>
  );
};

export default LazyIframe;
